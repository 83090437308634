import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";

const BillingInfo = ({
  onChange,
  setIsValid,
  setStripe,
  errors,
  profile,
  handleChange,
  handleBlur,
  setDefaultCard,
  defaultCard,
  setFieldValue,
  ...props
}) => {
  if (errors === void 0) {
    errors = {};
  }

  const [touchedCard, didTouchCard] = useState(false);
  const [touchedExp, didTouchExp] = useState(false);
  const [touchedCVC, didTouchCVC] = useState(false);

  useEffect(() => {
    if (setIsValid) {
      setIsValid(true);
    }

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);
    const elements = stripe.elements();
    const card = elements.create("cardNumber");
    card.mount("#card-element");
    card.addEventListener("change", (e) => {
      didTouchCard(true);
    });

    const exp = elements.create("cardExpiry");

    exp.addEventListener("change", (e) => {
      didTouchExp(true);
    });

    exp.on("update", (event) => {
      onChange("exp_month", event.complete);
      onChange("exp_year", event.complete);
    });

    exp.mount("#expire-element");

    const cvc = elements.create("cardCvc");

    cvc.on("update", (event) => {
      onChange("cvv", event.complete);
    });

    cvc.addEventListener("change", (e) => {
      didTouchCVC(true);
    });

    cvc.mount("#cvc-element");

    setStripe({ card, stripe });
  }, []);

  let normalStyle = {
    backgroundColor: "#f0f0f0",
    padding: "8px",
    borderRadius: "15px",
    minWidth: "70px",
    minHeight: "17px",
  };

  return (
    <Form>
      {profile && profile.stripe_id && (
        <FormGroup controlId="formBasicChecbox">
          <Form.Check
            type="checkbox"
            className="text-primary"
            label={`Use default card ending in ${
              profile.cardInfo ? profile.cardInfo.lastFour : "none"
            }`}
            name="useDefaultCard"
            onChange={(e) => {
              // handleChange(e.target.checked);
              setFieldValue("useDefaultCard", e.target.checked);
              setDefaultCard(e.target.checked);
            }}
            onBlur={handleBlur}
          />
        </FormGroup>
      )}
      <FormGroup controlId="name">
        <Form.Label>Card Number</Form.Label>
        <div
          id="card-element"
          className="field"
          style={
            defaultCard
              ? {
                  ...normalStyle,
                  pointerEvents: "none",
                  backgroundColor: "#d2d2d2",
                }
              : normalStyle
          }
        />
      </FormGroup>
      <div className="d-flex justify-content-between">
        <FormGroup controlId="months">
          <Form.Label>Expiration</Form.Label>
          <div
            id="expire-element"
            className="field"
            style={
              defaultCard
                ? {
                    ...normalStyle,
                    pointerEvents: "none",
                    backgroundColor: "#d2d2d2",
                  }
                : normalStyle
            }
          />
        </FormGroup>
        <FormGroup controlId="CCV">
          <Form.Label>CVC</Form.Label>
          <div
            id="cvc-element"
            className="field"
            style={
              defaultCard
                ? {
                    ...normalStyle,
                    pointerEvents: "none",
                    padding: "6px",
                    backgroundColor: "#d2d2d2",
                  }
                : normalStyle
            }
          />
        </FormGroup>
      </div>
      {!defaultCard && !!errors.cardValidation && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {errors.cardValidation.message}
        </Form.Control.Feedback>
      )}
    </Form>
  );
};

BillingInfo.onSubmit = () => true;

export default BillingInfo;
