import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// import Auth0 from "../../utils/auth0";

const Auth = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return null;
  }

  return (
    <li className="nav-item login">
      <Link to="/login" style={{ textDecoration: "none" }}>
        <Button
          // variant='link'
          className="nav-link font-weight-bold rebrand-login-button"
          // onClick={async () => Auth0()}
        >
          Log In
        </Button>
      </Link>
    </li>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login && state.login.isAuthenticated,
});

export default connect(mapStateToProps)(Auth);
