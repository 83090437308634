import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ck from "creditkey-js";

import Cart from "./CartContainer";
import CheckoutInfo from "../../utils/schemas/shippingSchema";
import ShoppingCartStep from "./ShoppingCartStep";
import Payment from "../creditcard/CreditCardModal";
import Modal from "../../components/Modal";
import ShippingInfo, { ConvertProfileToIntial } from "./ShippingInfo";
import {
  updateShippingInfo,
  shouldHideCart,
  shouldShowCart,
  setPaymentMethod,
  setCreditOrder,
  charging,
  charged,
} from "../../store/checkout/action";
import {
  clearCart,
  setTax,
  setShippingOption,
} from "../../store/shoppingCart/action";
import { deleteCartItemById } from "../../store/shoppingCart/thunks";
import { checkout } from "../../store/checkout/thunks";

import "react-toastify/dist/ReactToastify.css";
import { addNewCard, updateBilling } from "../../store/auth/thunks";
import CreditKey from "../creditcard/creditKey";
import {
  isProductChosen,
  openCreditCardCheckout,
} from "../../store/create/jewelry/action";
import {
  checkoutCart,
  getPromotionCodes,
  getShippmentRates,
  uploadLaserEngravingImages,
} from "../../utils/api";
import { dynamicStyle } from "../../utils/helpers";

const CREDIT_KEY = process.env.REACT_APP_CREDIT_KEY;
const CREDIT_TOKEN = process.env.REACT_APP_CREDIT_SECRET;
const CREDIT_KEY_URL = process.env.REACT_APP_CREDIT_KEY_API_URL;

const FullCheckout = (props) => {
  const [stripe, setStripe] = useState(null);
  const [step, setStepState] = useState(0);
  const [payment, setPaymentOption] = useState("Default");
  const [shippingValues, setShippingValues] = useState(null);

  const [isCardModalOpen, openCardModal] = useState(false);
  const [cardInfoLoad, setCardInfoLoad] = useState(false);
  const [cardErrors, setCardErrors] = useState(undefined);
  const [defaultCard, setDefaultCard] = useState(false);
  const [shippingRatePrice, setShippingRate] = useState(null);
  const [rateFetched, setFetchRates] = useState(1);

  const [shippoMethod, setShippoMethod] = useState(null);
  const [shippoId, setShippoId] = useState(null);
  const [shippoServiceToken, setShippoToken] = useState(null);
  const [shippoCarrier, setShippoCarrier] = useState(null);
  const [shippoEstimatedDelivery, setShippoEstimatedDelivery] = useState(null);

  const [shippingRates, setRates] = useState(null);

  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCode, setPromoCode] = useState(null);

  const paymentOptions = ["Default", "Credit-Key"];

  const conditionCrt = props.profile?.creatorId;

  const navigate = useNavigate();

  const [shouldShowCart, setState] = [
    props.shouldShowCart,
    (newState) => {
      if (newState === false) {
        return props.hideCart();
      }
      return props.showCart();
    },
  ];

  function matchPromoCode(givenCode, codes) {
    return codes.find((cd) => cd.code === givenCode.trim());
  }

  const fetchPromoCodes = async () => {
    const res = await getPromotionCodes();
    setPromoCodes(res.data);
  };

  useEffect(() => {
    if (promoCodes.length === 0) {
      fetchPromoCodes();
    }
  }, []);

  const matchedCode =
    promoCode &&
    matchPromoCode(
      promoCode,
      promoCodes.filter((cd) => cd.coupon.name.includes("Production"))
    );

  const collectCardInfo = async () => {
    setCardInfoLoad(true);
    setCardErrors(undefined);
    const stripeResponse = await stripe.stripe.createToken(stripe.card);
    if (stripeResponse.error) {
      setCardErrors(stripeResponse.error);
      return;
    }
    const tok = stripeResponse.token.id;
    await props.updateBilling(
      props.profile.subUser ? props.profile.admin_id : props.profile.id,
      tok
    );
    setCardInfoLoad(false);
    openCardModal(false);
    props.setOpenCreditCard(false);
  };

  const PaymentDetailsCard = (payment) => {
    switch (payment) {
      case "Default":
        return props.profile && props.profile.cardInfo ? (
          <Payment
            onChange={props.updateBilling}
            billingInfo={props.checkout.billing}
            setStripe={setStripe}
            setDefaultCard={setDefaultCard}
            defaultCard={defaultCard}
          />
        ) : (
          <>
            <Modal
              title="Enter Your Credit Card Information"
              show={isCardModalOpen}
              handleClose={() => {
                openCardModal(false);
                props.setOpenCreditCard(false);
              }}
              headerStyle={
                conditionCrt
                  ? {
                      ...dynamicStyle(
                        props.profile?.primaryButtonColor,
                        props.profile?.buttonTextColor
                      ),
                      borderRadius: "7px",
                      fontFamily: "MyAppFont",
                      fontSize: "18px",
                    }
                  : { backgroundColor: "#7c6261", color: "white" }
              }
              primaryButtons={[
                {
                  title: cardInfoLoad ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    "Save new card"
                  ),
                  handle: collectCardInfo,
                  className: !conditionCrt && "checkOut-btn",
                  style: conditionCrt
                    ? dynamicStyle(
                        props.profile?.primaryButtonColor,
                        props.profile?.buttonTextColor
                      )
                    : {},
                },
              ]}
              dangerButtons={[
                {
                  title: "Cancel",
                  handle: () => {
                    openCardModal(false);
                    props.setOpenCreditCard(false);
                  },
                },
              ]}
            >
              <Payment
                setStripe={setStripe}
                defaultCard={defaultCard}
                setDefaultCard={setDefaultCard}
              />
              {cardErrors && <p>{cardErrors.message}</p>}
            </Modal>
            <div className="card-alert text-center">
              Please enter your credit card information!
            </div>
            <div className="text-center mb-5">
              <Button
                variant="link"
                className="card-button"
                onClick={() => {
                  openCardModal(true);
                  props.setOpenCreditCard(true);
                }}
              >
                Enter Credit Card info
              </Button>
            </div>
          </>
        );
      case "Credit-Key":
        return (
          <CreditKey
            cart={props.cart}
            salesTax={props.salesTax}
            shippingPrice={props.shipping.shippingMethodPrice || 0}
            profile={props.profile}
            checkout={props.checkout}
          />
        );
      default:
        return null;
    }
  };

  const cont = async (values, title) => {
    setStepState(step + 1);
    const data = {
      city: props.profile ? props.profile.address.city : "",
      address1: props.profile ? props.profile.address.address1 : "",
      state: props.profile ? props.profile.address.state : "",
      country: props.profile ? props.profile.address.country : "",
      zipcode: props.profile ? props.profile.address.zipcode : "",
      name: props.profile
        ? props.profile.firstName + " " + props.profile.lastName
        : "",
      items: props.items,
      phone: props.profile ? props.profile.phone : "",
    };
    if (title === "Shopping Cart") {
      setFetchRates(0);
      const res = await getShippmentRates(data, props.apiToken);
      if (res.status === "SUCCESS") {
        if (res.rates.length !== 0) {
          setFetchRates(1);
        }
        setRates(res.rates);
      } else {
        setFetchRates(2);
      }
    }
  };

  const back = () => setStepState(step - 1);

  const submitCreditKey = async () => {
    const total = props.cart.items.reduce(
      (acc, item) => acc + Number(item.price.totalPrice * item.quantity),
      0
    );

    const grandTotal =
      total +
      Number(props.salesTax) +
      Number(props.checkout.shipping.shippingMethodPrice);

    const addresses = shippingValues.address
      ? shippingValues.address.split(",")
      : "";

    const billingAddress = {
      first_name: shippingValues.firstName,
      last_name: shippingValues.lastName,
      company_name: "Gildform",
      email: shippingValues.email,
      address1: addresses[0],
      address2: addresses[1],
      city: shippingValues.city,
      state: String(shippingValues.state),
      zip: shippingValues.zipcode,
      phone_number: props.profile.phone,
    };

    const shippingAddress = billingAddress;

    const charges = {
      total: total,
      shipping: props.checkout.shipping.shippingMethodPrice,
      tax: props.salesTax,
      discount_amount: 0,
      grand_total: grandTotal,
    };

    const cartItems = [];

    for (const item of props.cart.items) {
      cartItems.push({
        merchant_id: props.cart.id,
        name: item.material.name + " " + item.itemType.name,
        price: item.price.totalPrice,
        quantity: item.quantity,
        sku: item.material.sku,
        color: JSON.stringify(item.material.color),
        size: item.surfaceArea,
      });
    }

    const remoteId = props.cart.id;
    const customerId = props.profile.erp_id;
    const returnUrl = "/model";
    const cancelUrl = "/model";
    const order_complete_url = "/model";

    const url = `${CREDIT_KEY_URL}/begin_checkout?public_key=${CREDIT_KEY}&shared_secret=${CREDIT_TOKEN}`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        remote_id: remoteId,
        remote_customer_id: customerId,
        cart_items: cartItems,
        billing_address: billingAddress,
        shipping_address: shippingAddress,
        charges: charges,
        return_url: returnUrl,
        cancel_url: cancelUrl,
        order_complete_url: order_complete_url,
        merchant_data: {
          "contact-method": "email",
        },
        mode: "modal",
      }),
    });

    const data = await res.json();
    const orderCredit = data.id;
    props.setCreditOrder(orderCredit);
    ck.checkout(data.checkout_url, "modal");
  };

  const submitPayment = async (useDefaultCard) => {
    console.log("useDefaultCard", useDefaultCard);
    let newCard;
    if (!defaultCard || !props.profile.cardInfo) {
      const stripeResponse = await stripe.stripe.createToken(stripe.card);
      newCard = await props.addNewCard(props.profile.sub, stripeResponse);
    }

    let notes;
    props.cart.items.map((item) => {
      notes = item.description ? item.decription + " " : "";
    });

    props.setCharging();
    const orderCreated = await checkoutCart({
      customer: props.profile.subUser
        ? props.profile.admin_id
        : props.profile.sub,
      tax: props.salesTax,
      notes: notes,
      shippingMethod: {
        id: shippoMethod,
        price: Number(shippingRatePrice),
      },
      address: props.checkout.shipping.address,
      orderItems: props.cart.items,
      apiToken: props.apiToken,
      cartId: props.cart.id,
      defaultCard: defaultCard,
      newCard,
      shippoOptions: {
        method: shippoMethod,
        rate: shippoId,
        carrier: shippoCarrier,
        serviceToken: shippoServiceToken,
        shippo: rateFetched === 1 ? true : false,
      },
      shipping: props.checkout.shipping,
      matchedCode: matchedCode,
      creatorOrder: conditionCrt,
    });

    console.log("orderCreated =============", orderCreated);
    let laserImagesUploaded;
    for (const createdOrderItem of orderCreated.orderItems) {
      for (const item of props.cart.items) {
        if (
          item.id === createdOrderItem.unitId &&
          item.laserImage &&
          item.laserImage.length > 0
        ) {
          laserImagesUploaded = await uploadLaserEngravingImages(
            item.laserImage,
            createdOrderItem.id
          );
        }
      }
    }
    if (orderCreated && orderCreated.error) {
      toast.error(
        orderCreated.error.raw
          ? orderCreated.error.raw.message
            ? `There was a problem with checkout: ${orderCreated.error.raw.message}`
            : "There was a problem with checkout! Please try again!"
          : "There was a problem with checkout! Please try again!"
      );
    } else {
      toast.success("Submitted your order, its now under review!", {
        className: "bg-primary text-white",
      });
      // setTimeout(() => {
      //   // navigate("/order-confirmation/thankyou");
      // }, 1000);
      setStepState(0);
    }
    props.setCharged();
    props.clearCart();
    props.hideCart();
    props.isProductChosen(null);
  };

  const Steps = [
    <ShoppingCartStep
      title="Shopping Cart"
      msg="Please review your order details. you can modify or remove any of the created orders at this time. "
      continue={cont}
      cart={props.cart}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
      shipping={props.shipping}
      creditOrder={props.creditOrder}
      profile={props.profile}
      checkout={props.checkout}
      apiToken={props.apiToken}
      doCheckout={props.doCheckout}
    >
      <Cart
        deleteItem={props.deleteCartItemById}
        selectedProduct={props.selectedProduct}
      />
    </ShoppingCartStep>,
    <ShoppingCartStep
      title="Shipping Information"
      msg="Please review your order details. you can modify or remove any of the created orders at this time. "
      continue={cont}
      cart={props.cart}
      next_title="Next"
      onBack={back}
      profile={props.profile}
      initialValues={ConvertProfileToIntial(props.profile)}
      schema={CheckoutInfo.shipping}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
    >
      <ShippingInfo
        shippingInfo={props.checkout ? props.checkout.shipping : null}
        onChange={props.updateShippingInfo}
        updateSalesTax={props.setTax}
        setShippingValues={setShippingValues}
        setShippingRate={setShippingRate}
        setShippoMethod={setShippoMethod}
        cartItems={props.items}
        shippingRatePrice={shippingRatePrice}
        shippoEstimatedDelivery={shippoEstimatedDelivery}
        setShippoId={setShippoId}
        shippoId={shippoId}
        phone={props.profile ? props.profile.phone : ""}
        shippingRates={shippingRates}
        rateFetched={rateFetched}
        setFetchRates={setFetchRates}
        setRates={setRates}
        setShippoToken={setShippoToken}
        setShippoCarrier={setShippoCarrier}
        setShippoEstimatedDelivery={setShippoEstimatedDelivery}
      />
    </ShoppingCartStep>,
    <ShoppingCartStep
      title="Checkout"
      msg="Please review your order details. you can modify or remove any of the created orders at this time. "
      continue={(values) =>
        payment === "Credit-Key"
          ? submitCreditKey()
          : submitPayment(values.useDefaultCard)
      }
      cart={props.cart}
      next_title="Checkout"
      onBack={back}
      isLoading={props.checkout.isCharging}
      profile={props.profile}
      salesTax={props.salesTax}
      shippingPrice={shippingRatePrice}
      shippoEstimatedDelivery={shippoEstimatedDelivery}
      paymentOptions={paymentOptions}
      setPaymentOption={setPaymentOption}
      payment={payment}
      validate={async (values) => {
        if (payment !== "Credit-Key") {
          if (values.useDefaultCard) {
            return;
          }
          let errors = {};
          if (stripe === null) {
            errors.stripe = "Something is screwy";
          }
          if (!defaultCard) {
            const tok = await stripe.stripe.createToken(stripe.card);
            if (tok.error) {
              errors.cardValidation = tok.error;
            }
            if (Object.keys(errors).length) {
              throw errors;
            }
          }
        }
      }}
      matchedCode={matchedCode}
      setPromoCode={setPromoCode}
      promoCode={promoCode}
    >
      {PaymentDetailsCard(payment)}
    </ShoppingCartStep>,
  ];

  if (!props.isAuthenticated) {
    return null;
  }

  const showCart = () => {
    if (props.count === 0) {
      return (
        <div className="cart-list">
          <div>
            <Row className="title">
              <Col>
                <h3>Looks like there is nothing in your cart</h3>
              </Col>
            </Row>
            <Row />
            <Row>
              <Col>
                <p>Upload an STL file below to start creating your piece</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link
                  className={
                    props.profile?.creatorId
                      ? "btn"
                      : "btn btn-primary checkout"
                  }
                  to={
                    props.profile?.creatorId
                      ? props.profile?.pageUrl
                        ? `${props.profile?.pageUrl}`
                        : "/model"
                      : "/model"
                  }
                  onClick={() => props.hideCart()}
                  style={
                    props.profile?.creatorId
                      ? {
                          ...dynamicStyle(
                            props.profile?.primaryButtonColor,
                            props.profile?.buttonTextColor
                          ),
                          width: "100%",
                          marginTop: "20px",
                          fontSize: "16pt",
                        }
                      : {}
                  }
                >
                  Get Started
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return Steps[step];
  };

  return (
    <li
      className={`nav-item cart ${shouldShowCart && "opened"}`}
      style={{ marginRight: "6px" }}
    >
      <Link
        className="nav-link"
        to="/"
        onClick={(e) => {
          e.preventDefault();
          if (props.checkout.isCharging) return;
          setState(!shouldShowCart);
          setStepState(0);
        }}
      >
        <i className="fas fa-shopping-cart" style={{ fontSize: "20px" }} />
        {props.count > 0 && !shouldShowCart && (
          <span
            className="badge badge-secondary"
            style={{ backgroundColor: "gray", color: "white" }}
          >
            {props.count}
          </span>
        )}
      </Link>
      {shouldShowCart && showCart()}
    </li>
  );
};

const mapStateToProps = (state) => ({
  count: state.cart.items.length,
  items: state.cart.items,
  cart: state.cart,
  surfaceArea: state.create.jewelry.surfaceArea,
  checkout: state.checkout,
  shouldShowCart: state.checkout.showCart,
  profile: state.login?.auth && state.login?.auth?.profile,
  apiToken: state.login?.auth && state.login?.auth?.auth?.token,
  salesTax: state.cart.salesTax || 0,
  shipping: state.checkout.shipping,
  creditOrder: state.checkout.creditOrder ? state.checkout.creditOrder : null,
  isAuthenticated: state.login?.isAuthenticated,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
});

const mapDispatchToProps = (dispatch) => ({
  updateShippingInfo: (field, value) =>
    dispatch(updateShippingInfo(field, value)),
  updateBilling: (field, value) => dispatch(updateBilling(field, value)),
  addNewCard: (field, value) => dispatch(addNewCard(field, value)),
  doCheckout: (data) => dispatch(checkout(data)),
  deleteCartItemById: (itemID) => dispatch(deleteCartItemById(itemID)),
  showCart: () => dispatch(shouldShowCart()),
  setCharging: () => dispatch(charging()),
  setCharged: () => dispatch(charged()),
  hideCart: () => dispatch(shouldHideCart()),
  clearCart: () => dispatch(clearCart()),
  setTax: (tax) => dispatch(setTax(tax)),
  setShippingOption: (option) => dispatch(setShippingOption(option)),
  setPaymentOption: (data) => dispatch(setPaymentMethod(data)),
  setCreditOrder: (data) => dispatch(setCreditOrder(data)),
  setOpenCreditCard: (data) => dispatch(openCreditCardCheckout(data)),
  isProductChosen: (data) => dispatch(isProductChosen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullCheckout);
