import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getAllCountries } from "../utils/api";

const CountryDropdown = ({
  onChange,
  handleBlur,
  value,
  name,
  isInvalid,
  isValid,
}) => {
  const [countries, setCountries] = useState(null);

  const authToken = useSelector((state) => state.login.auth.auth.accessToken);

  const getCountries = async () => {
    const countries = await getAllCountries(authToken);
    setCountries(countries);
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Form.Control
      as="select"
      name={name}
      value={value}
      onBlur={handleBlur}
      onChange={onChange}
      isValid={!!isValid}
      isInvalid={!!isInvalid}
    >
      <option value="">Select a country</option>
      {countries &&
        countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
    </Form.Control>
  );
};

export default CountryDropdown;
