import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { redirect } from "react-router-dom";

import CheckJwt from "./CheckJwtExpiration";
// import { ShowLock } from "../../utils/auth0";
import { getUser } from "../../utils/api";
import { retrieveUser } from "../../store/auth/thunks";
import { useNavigate } from "react-router-dom";

const RedirectComponent = () => {
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (window !== void 0) {
      window.setTimeout(() => {
        setLink("/login");
      }, 1000);
    }
  }, []);

  if (!!link) {
    return redirect(decodeURIComponent(link));
  }

  return null;
};

const PrivateRoute = (props) => {
  const shouldForceCreate =
    props.forceCreate === undefined ? true : props.forceCreate;

  const navigate = useNavigate();
  // const validate = props.validate === undefined ? () => true : props.validate;

  // useEffect(() => {
  //   if (props.accessToken) {
  //     props.getUser(props.userID);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!props.accessToken) {
  //     // ShowLock();
  //     // if (ShowLock) {
  //     //   ShowLock(false);
  //     // }
  //     // return () => {
  //     // };
  //   }

  //   if (!props.profile) {
  //     getUser(props.userID)
  //       .then(() => true)
  //       .catch((e) => {
  //         if (shouldForceCreate) {
  //           return redirect("/login");
  //         }
  //       });
  //   }

  //   // if (props.validate) {
  //   //   props.validate(props.userID, props.accessToken, getUser);
  //   // }

  //   // validate(props.userID, props.accessToken, getUser);
  // }, [props.accessToken]);

  if (!props.accessToken) {
    navigate("/login");
  } else {
    //<CheckJwt>{props.children}</CheckJwt>
    return <>{props.children}</>;
  }
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  accessToken: state.login.auth && state.login.auth.auth.accessToken,
  profile: state.login.auth && state.login.auth.profile,
  userID: state.login.auth && state.login.auth.profile.sub,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (userID) => dispatch(retrieveUser(userID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
