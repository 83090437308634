import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  CLEAR_CART,
  DELETE_CART_ITEM_BY_ID,
  GENERATE_CART_ID,
} from "../actionTypes";

const initialState = {
  id: null,
  items: [],
  addToCartLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_REQUEST: {
      return {
        ...state,
        addToCartLoading: true,
      };
    }
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload],
        addToCartLoading: false,
      };
    }
    case ADD_TO_CART_FAILURE: {
      return {
        ...state,
        addToCartLoading: false,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        id: null,
        items: [],
        addToCartLoading: false,
      };
    }
    case DELETE_CART_ITEM_BY_ID: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };
    }
    case GENERATE_CART_ID: {
      return {
        ...state,
        id: action.id,
      };
    }
    default:
      return state;
  }
};
