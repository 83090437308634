import React, { useEffect } from "react";
import CartItem from "./CartItem";

const CartContainer = ({ cart, setIsValid, deleteItem, selectedProduct }) => {
  useEffect(() => {
    if (setIsValid) setIsValid(true);
  }, [setIsValid]);

  return (
    <ul style={{ width: "100%" }}>
      {cart.items.map((item, ndx) => (
        <CartItem
          item={item}
          key={ndx}
          deleteItem={(itemID) => deleteItem(itemID)}
          selectedProduct={selectedProduct}
        />
      ))}
    </ul>
  );
};

export default CartContainer;
