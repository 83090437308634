import React from "react";
import { Overlay, Tooltip } from "react-bootstrap";

const CustomTooltip = (props) => {
  return (
    <Overlay
      target={props.target.current}
      show={props.show}
      placement={props.place}
    >
      {(fprops) => (
        <Tooltip id="overlay-example" {...fprops}>
          You have no permission to perform this action.
          <br /> Please talk to your admin for more details!
        </Tooltip>
      )}
    </Overlay>
  );
};

export default CustomTooltip;
