import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
// import WebFont from 'webfontloader';
import axios from "axios";

import Footer from "./Footer";
// import CheckJwt from "./auth/CheckJwtExpiration";
import Header from "./Header";
import favicon16 from "../assets/images/favicon-16x16.png";
import favicon32 from "../assets/images/favicon-32x32.png";
import favicon64 from "../assets/images/favicon-96x96.png";
import "../style/main.scss";
// import { getFonts } from "../utils/api";

const Layout = ({
  children,
  style,
  className,
  light_header,
  forceFooter,
  backgroundImage,
  sit_on_top,
  haveFooter,
  onboarding,
  noAnswers,
  ...props
}) => {
  haveFooter = haveFooter === undefined ? true : false;

  const [fonts, setFonts] = useState([]);
  const [WebFont, setWebFont] = useState(null);

  // const fetchFonts = async () => {
  //   const res = await getFonts();
  //   setFonts(res);
  // };
  // useEffect(() => {
  //   fetchFonts();
  // }, []);

  // let families = [];
  // fonts.map((font) => {
  //   families.push(font.name);
  // });
  // function addStylesheetURL(url) {
  //   var link = document.createElement("link");
  //   link.rel = "stylesheet";
  //   link.href = url;
  //   document.getElementsByTagName("head")[0].appendChild(link);
  // }
  // useEffect(() => {
  //   (async () => {
  //     const WebFont = await import("webfontloader");
  //     setWebFont(WebFont);
  //   })();
  // }, []);

  const conditionCrt = props.profile?.creatorId;

  useEffect(() => {
    const fontStyle = document.createElement("style");
    console.log("font style", fontStyle);
    fontStyle.innerHTML = `@font-face {
        font-family: 'MyAppFont';
        src: url(${props.profile?.font}) format('truetype');
        font-weight: normal;
        font-style: normal;
      }`;
    document.head.appendChild(fontStyle);
  }, [conditionCrt]);

  return (
    // <CheckJwt {...props}>
    <>
      <Helmet>
        <link href={`${favicon16}`} sizes="16x16" type="image/png" rel="icon" />
        <link href={`${favicon32}`} sizes="32x32" type="image/png" rel="icon" />
        <link href={`${favicon64}`} type="image/png" rel="shortcut icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:400,600&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
          integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
          crossOrigin="anonymous"
        />
        <script
          src="https://code.jquery.com/pep/0.4.3/pep.min.js"
          integrity="sha384-gcaITNPK2MIxJNVAxQFJO1mujYC/98niQA1MFyPQrNkJkE2lfBxi1oFJXGZCIza5"
          crossOrigin="anonymous"
        />
        <script src="https://cdn.auth0.com/js/lock/11.17.2/lock.min.js" />
        <script src="https://js.stripe.com/v3/" />
        <script
          src="https://07446a6977d444c5acd978edb097f403.js.ubembed.com"
          async
        />
        <script
          type="text/javascript"
          src="//script.crazyegg.com/pages/scripts/0115/7987.js"
          async="async"
        />
      </Helmet>
      <Header
        siteTitle={"Gildform"}
        light_header={light_header}
        backgroundImage={backgroundImage}
        sit_on_top={sit_on_top}
        onboarding={onboarding}
        noAnswers={noAnswers}
      />
      <main
        style={conditionCrt ? { ...style, fontFamily: "MyAppFont" } : style}
        className={className || ""}
      >
        {children}
      </main>
      <ToastContainer />
      {haveFooter ? <Footer forceBottom={forceFooter} /> : null}
    </>
    // </CheckJwt>
  );
};

export default Layout;
