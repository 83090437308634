import { SET_STEPS_NUMBER, GO_NEXT, GO_BACK, GO_TO_STEP } from "./action";
import { RESET } from "../actionTypes";

const initialState = {
  totalSteps: 0,
  currentStep: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STEPS_NUMBER: {
      return {
        ...state,
        totalSteps: action.steps,
      };
    }
    case GO_TO_STEP:
      if (action.step >= state.totalSteps) {
        return state;
      }
      return {
        ...state,
        currentStep: action.step,
      };
    case GO_NEXT:
      if (state.currentStep === state.totalSteps) {
        return state;
      }
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case GO_BACK:
      if (state.currentStep - 1 < 0) {
        return state;
      }
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case RESET: {
      return {
        ...state,
        currentStep: 0,
      };
    }
    default:
      return state;
  }
};
