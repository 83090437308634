import React from "react";
import ClipLoader from "react-spinners/ScaleLoader";

const MainLoader = ({ height = 35, width = 4, radius = 2, margin = 2 }) => (
  <div className="h-100 w-100 d-flex justify-content-center align-items-center">
    <ClipLoader
      color="black"
      height={height}
      width={width}
      radius={radius}
      margin={margin}
    />
  </div>
);

export default MainLoader;
