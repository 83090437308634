import {
  UPLOAD_FILE,
  SET_ITEM_TYPE,
  SET_MATERIAL,
  SET_PLATING,
  SET_POLISH,
  SET_ASSEMBLY_OPTION,
  SET_VOLUME,
  SET_SURFACE_AREA,
  SET_DESCRIPTION,
  SET_QUANTITY,
  SET_SCREENSHOT,
  IS_FETCHING,
  MODEL_LOADING,
  MODEL_LOADING_DONE,
  RESET,
  SET_MESH_LOCATION,
  REMOVE_ASSEMBLY_OPTION,
  OPEN_MODAL_LIBRARY,
  SET_SELECTED_MODEL,
  MODEL_CHOSEN,
  SET_SORT_ITEM_TYPE,
  SET_MOST_POPULAR,
  SET_LOAD_PROCESS,
  OPEN_DESIGN_WITH_US_MODAL,
  SET_SELECTED_MARKETPLACE_MODEL,
  OPEN_CREDIT_CARD_CHECKOUT,
  SET_HEIGHT,
  SET_WIDTH,
  SET_LENGTH,
  SET_FILE_DIMENSION_ERRORS,
  NEW_ORDER,
  SET_WEIGHT,
  SET_SELECTED_PRODUCT,
  PRODUCT_CHOSEN,
  SET_LASER_ENGRAVING_OPTION,
  REMOVE_LASER_ENGRAVING_OPTION,
  SET_FONT,
  SET_LASER_CUSTOM_TEXT,
  SET_LASER_IMAGE,
  SET_LIBRARY_PRODUCT,
} from "../../actionTypes";
import { platingOptions } from "./options";

const initialState = {
  itemType: null,
  material: null,
  plating: platingOptions["none"],
  polish: null,
  assemblyOption: null,
  laserEngravingOption: null,
  file: null,
  volume: null,
  surfaceArea: null,
  weight: null,
  model_height: null,
  model_width: null,
  model_length: null,
  file_name: null,
  meshLocation: null,
  description: null,
  screenshot: null,
  quantity: 1,
  isLoading: {
    materialOptions: false,
    polishOptions: false,
    platingOptions: false,
    modelIsLoading: false,
  },
  new_order: true,
  fileDimensionError: false,
  loadProcess: 0,
  laserCustomText: null,
  laserFont: null,
  laserImage: [],
  libraryProduct: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE: {
      return {
        ...state,
        file: action.file,
        file_name: action.name,
      };
    }
    case SET_ITEM_TYPE: {
      return {
        ...state,
        itemType: action.itemType,
      };
    }
    case SET_MATERIAL: {
      return {
        ...state,
        material: action.material,
      };
    }
    case SET_POLISH: {
      return {
        ...state,
        polish: action.polish,
      };
    }
    case SET_PLATING: {
      return {
        ...state,
        plating: action.plating,
      };
    }
    case SET_ASSEMBLY_OPTION: {
      return {
        ...state,
        assemblyOption: action.assemblyOption,
      };
    }
    case REMOVE_ASSEMBLY_OPTION: {
      return {
        ...state,
        assemblyOption: null,
      };
    }
    case SET_LASER_ENGRAVING_OPTION: {
      return {
        ...state,
        laserEngravingOption: action.laserEngravingOption,
      };
    }
    case REMOVE_LASER_ENGRAVING_OPTION: {
      return {
        ...state,
        assemblyOption: null,
      };
    }
    case SET_FONT: {
      return {
        ...state,
        laserFont: action.laserFont,
      };
    }
    case SET_LASER_CUSTOM_TEXT: {
      return {
        ...state,
        laserCustomText: action.laserCustomText,
      };
    }
    case SET_LASER_IMAGE: {
      return {
        ...state,
        laserImage: action.laserImage,
      };
    }
    case SET_LIBRARY_PRODUCT: {
      return {
        ...state,
        libraryProduct: action.libraryProduct,
      };
    }
    case SET_MESH_LOCATION: {
      return {
        ...state,
        meshLocation: action.payload,
      };
    }
    case SET_VOLUME: {
      return {
        ...state,
        volume: action.volume,
      };
    }
    case SET_SURFACE_AREA: {
      return {
        ...state,
        surfaceArea: action.surfaceArea,
      };
    }
    case SET_WEIGHT: {
      return {
        ...state,
        weight: action.weight,
      };
    }
    case SET_HEIGHT: {
      return {
        ...state,
        model_height: action.payload,
      };
    }
    case SET_WIDTH: {
      return {
        ...state,
        model_width: action.payload,
      };
    }
    case SET_LENGTH: {
      return {
        ...state,
        model_length: action.payload,
      };
    }
    case SET_DESCRIPTION: {
      return {
        ...state,
        description: action.description,
      };
    }
    case SET_QUANTITY: {
      return {
        ...state,
        quantity: action.quantity,
      };
    }
    case SET_SCREENSHOT: {
      return {
        ...state,
        screenshot: action.screenshot,
      };
    }
    case IS_FETCHING: {
      return {
        ...state,
        isLoading: {
          materialOptions: true,
          polishOptions: true,
          platingOptions: true,
        },
      };
    }
    case MODEL_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          modelIsLoading: true,
        },
      };
    case MODEL_LOADING_DONE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          modelIsLoading: false,
        },
      };
    case SET_LOAD_PROCESS: {
      return {
        ...state,
        loadProcess: action.payload,
      };
    }
    case OPEN_MODAL_LIBRARY:
      return {
        ...state,
        libraryOpen: action.payload,
      };
    case OPEN_CREDIT_CARD_CHECKOUT:
      return {
        ...state,
        creditCardOpen: action.payload,
      };
    case SET_SELECTED_MODEL:
      return {
        ...state,
        selected3dModel: action.payload,
      };
    case SET_SELECTED_MARKETPLACE_MODEL:
      return {
        ...state,
        selectedMarketplace3dModel: action.payload,
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case SET_SORT_ITEM_TYPE:
      return {
        ...state,
        sortItem: action.payload,
      };
    case MODEL_CHOSEN:
      return {
        ...state,
        modelChosen: action.payload,
      };
    case PRODUCT_CHOSEN:
      return {
        ...state,
        productChosen: action.payload,
      };
    case SET_MOST_POPULAR:
      return {
        ...state,
        popularClicked: action.payload,
      };
    case RESET:
      return {
        ...state,
        itemType: null,
        plating: null,
        assemblyOption: null,
        laserEngravingOption: null,
        quantity: 1,
        file: null,
        file_name: null,
        meshLocation: null,
        volume: null,
        surfaceArea: null,
        description: null,
        laserCustomText: null,
        laserFont: null,
        laserImage: null,
        isLoading: {
          materialOptions: false,
          polishOptions: false,
          platingOptions: false,
          modelIsLoading: false,
        },
      };

    case OPEN_DESIGN_WITH_US_MODAL:
      return {
        ...state,
        designWithUsModalOpen: action.payload,
      };

    case SET_FILE_DIMENSION_ERRORS:
      return {
        ...state,
        fileDimensionError: action.payload,
      };

    case NEW_ORDER:
      return {
        ...state,
        new_order: action.payload,
      };
    default:
      return state;
  }
};
