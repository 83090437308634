import React from "react";
import { Modal, Button } from "react-bootstrap";

export default ({
  show,
  dashboardModal,
  handleClose,
  className,
  style,
  title,
  primaryButtons,
  dangerButtons,
  secondaryButtons,
  children,
  description,
  headerClassName,
  bodyStyle,
  headerStyle,
  size,
  library,
  libraryHandle,
  titleStyle,
  dialogClassName,
}) => (
  <Modal
    show={show}
    onHide={handleClose}
    size={size}
    className={className}
    style={style}
    dialogClassName={dialogClassName}
    centered
  >
    <Modal.Header style={headerStyle} closeButton className={headerClassName}>
      <Modal.Title style={titleStyle}>{title}</Modal.Title>
      <span>{description}</span>
    </Modal.Header>
    <Modal.Body style={bodyStyle}>{children}</Modal.Body>
    <Modal.Footer>
      {dashboardModal ? (
        <>
          <div className="secondary-buttons-section">
            {secondaryButtons &&
              secondaryButtons.map((button, index) => (
                <span className="action-link" onClick={button.handle}>
                  {button.title}
                </span>
              ))}
          </div>
          <div className="primary-buttons-section">
            {primaryButtons &&
              primaryButtons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.outline ? "outline-primary" : "primary"}
                  onClick={button.handle}
                  disabled={button.disabled || button.isLoading}
                  type={button.type}
                  className={button.className}
                  style={button.style}
                >
                  {button.isLoading ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    button.title
                  )}
                </Button>
              ))}
          </div>
        </>
      ) : (
        <div className="footer-items">
          <div className="left">
            {dangerButtons &&
              dangerButtons.map((button, index) => (
                <Button key={index} variant="danger" onClick={button.handle}>
                  {button.title}
                </Button>
              ))}
            {secondaryButtons &&
              secondaryButtons.map((button, index) => (
                <Button
                  key={index}
                  variant="outline-secondary"
                  onClick={button.handle}
                >
                  {button.title}
                </Button>
              ))}
            {library ? (
              <div
                onClick={() => {
                  libraryHandle();
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "left",
                  marginRight: "auto",
                  color: "#7c6262",
                  fontSize: "15px",
                }}
              >
                Choose from Design Library
              </div>
            ) : null}
          </div>
          <div className="right">
            {primaryButtons &&
              primaryButtons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.outline ? "outline-primary" : "primary"}
                  onClick={button.handle}
                  disabled={button.disabled || button.isLoading}
                  type={button.type}
                  className={button.className}
                  style={button.style}
                >
                  {button.isLoading ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    button.title
                  )}
                </Button>
              ))}
          </div>
        </div>
      )}
    </Modal.Footer>
  </Modal>
);
