import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";

import { deleteCartItemById } from "../../store/shoppingCart/thunks";
import { setQuantity } from "../../store/create/jewelry/thunks";

const thousands_separators = (num) => {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

const CartItem = ({
  item,
  deleteCartItemById,
  setQuantity,
  selectedProduct,
}) => (
  <li className="cart-item">
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex align-items-center mb-1">
        {item.image ? (
          <div style={{ width: "50px", height: "50px" }}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt="Screen shot of this jewelry piece"
            />
          </div>
        ) : (
          <div />
        )}
        <div>
          {selectedProduct
            ? `${selectedProduct.name} ${item.material.name}`
            : item.itemType.name}
        </div>
      </div>
      <div className="price" style={{ marginTop: ".8rem" }}>
        Total: ${thousands_separators(item.price.totalPrice.toFixed(2))}
      </div>
    </div>
    <p>{item.description}</p>
    <div className="d-flex flex-row justify-content-between">
      <Col className="d-flex justify-content-start">
        <Form.Group>
          <Form.Control
            disabled
            style={{ textAlign: "right" }}
            as="input"
            value={item.quantity}
            onChange={setQuantity}
            type="number"
          />
        </Form.Group>
      </Col>
      <Col className="d-flex justify-content-end">
        <Button
          onClick={() => deleteCartItemById(item.id)}
          variant="outline-dark"
          style={{
            backgroundColor: "white",
            color: "#7c6262",
            borderColor: "white",
            fontSize:'18px'
          }}
        >
          <i className="fas fa-times" />
        </Button>
      </Col>
    </div>
  </li>
);

const mapDispatchToProps = (dispatch) => ({
  deleteCartItemById: (id) => dispatch(deleteCartItemById(id)),
  setQuantity: (quantity) => dispatch(setQuantity(quantity)),
});

export default connect(undefined, mapDispatchToProps)(CartItem);
