import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import {
  Form,
  Row,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import DesignLibraryModal from "../DesignLibraryModal";
// import DesignForm from "../DesignWithUs";
import FileUploader from "../FileUpload";
import {
  uploadFile,
  setDescription,
  openModalLibrary,
  setSelected3dModel,
  isModelChosen,
  setSortItemType,
  setMostPopular,
  setLoadingProcess,
  openDesignWithUsModal,
  setSelectedMarket3dModel,
  setWeight,
  setSelectedProduct,
  isProductChosen,
} from "../../store/create/jewelry/action";
import {
  setItemType,
  setMaterial,
  setPolish,
} from "../../store/create/jewelry/thunks";
import { modelIsLoading } from "../../store/create/jewelry/action";
import {
  apiFetch,
  getPresignedUrl,
  getAll3DModels,
  getProductVariants,
  getAll3DModelsCreator,
} from "../../utils/api";

import "../../style/table.scss";
import { replaceMarketplaceState } from "../../utils/helpers";
import { getMarketplaceUsersDesigns } from "../../store/auth/thunks";
import { dynamicStyle } from "../../utils/helpers";
// import CalculatorFirst from "./CalculatorFirst";

const MARKET_URL = process.env.REACT_APP_MARKETPLACE_URL;

const StartOrderStep = ({
  uploadFile,
  itemType,
  setItemType,
  setDescription,
  modelIsLoading,
  items,
  loading,
  shopifyShopURL,
  shopifyAccessToken,
  token,
  membership,
  openModalLibrary,
  selected3dModel,
  setSelectedModel,
  isModelChosen,
  modelChosen,
  isDesignLibraryModalOpen,
  openDesignLibraryModal,
  setSortItemType,
  sortItem,
  popularClicked,
  setMostPopular,
  isLoading,
  setLoadProcess,
  progressPercentage,
  openDesignWithUsModal,
  userProjects,
  selectedMarketplace3dModel,
  setSelectedMarket3dModel,
  location,
  getMarketplaceUsersDesigns,
  profile,
  setWeight,
  setHeight,
  setLength,
  setWidth,
  productionPage,
  estimatePricing,
  material,
  plating,
  polish,
  assemblyOption,
  laserEngravingOption,
  setSelectedProduct,
  selectedProduct,
  isProductChosen,
  productChosen,
  setProductPricing,
  setPolishPrice,
  setPlatingPrice,
  setMaterialPrice,
  setAssemblyPrice,
  setLaserEngravingPrice,
  materialPrice,
  platingPrice,
  polishPrice,
  assemblyPrice,
  itemConfigs,
  setMaterial,
  setPolish,
}) => {
  const [itemTypes, setItemTypes] = useState(null);
  const [productItems, setProductItems] = useState(null);

  const [modal, setModal] = useState(false);
  const [showDesign, shouldShowDesign] = useState(false);
  const [D3models, setModels] = useState([]);
  const [chosen, setChosen] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [filteredDesign, setFilteredDesign] = useState(null);
  const [products, setProducts] = useState([]);

  const [activeTab, setActiveTab] = useState("default");

  const fileReader =
    typeof window !== `undefined`
      ? new FileReader()
      : { readAsArrayBuffer: (_file) => {} };

  const fetchItemTypes = async () => {
    const data = await apiFetch("/items");
    setItemTypes(data.filter((it) => !it.product));
    setProductItems(data.filter((it) => it.product));
  };

  const conditionCrt = profile?.creatorId;

  const fetch3dModels = async (token) => {
    const data = conditionCrt
      ? await getAll3DModelsCreator(token, Number(profile?.creatorId))
      : await getAll3DModels(token);
    setModels(data);
  };

  const getMarketplaceDesigns = async (id) => {
    const userDesigns = await getMarketplaceUsersDesigns(id);
  };

  const getProducts = async () => {
    const res = await getProductVariants();
    setProducts(res);
  };

  useEffect(() => {
    fetchItemTypes();
  }, []);

  useEffect(() => {
    if (isDesignLibraryModalOpen) {
      if (products.length === 0 && activeTab === "product") {
        getProducts();
        fetch3dModels(token);
      }
    }
  }, [isDesignLibraryModalOpen, activeTab]);

  useEffect(() => {
    if (activeTab === "market") {
      getMarketplaceDesigns(profile?.id);
    } else if (activeTab === "default" && D3models.length === 0) {
      fetch3dModels(token);
    }
  }, [activeTab]);

  useEffect(() => {
    if (location.includes("market")) {
      const fromMarketPlace = location ? location.split("=") : null;
      setTimeout(() => {
        openDesignLibraryModal(true);
        openModalLibrary(true);
        setActiveTab("market");
        setFilteredDesign(fromMarketPlace[1]);
      }, 900);
    }
  }, [location]);

  const checkFalsePricing = (item) => {
    if (isNaN(item.price)) {
      return true;
    }

    return false;
  };

  const checkItemUnit = (item) => {
    if (item.quantity < 1 || null) {
      return true;
    }
    return false;
  };

  const variants =
    itemConfigs && itemConfigs.map((itemConfig) => itemConfig.variant);

  const sterlingSilver =
    variants &&
    variants.find((variant) => variant.code.toLowerCase() === "ssa1");

  const noPolish =
    variants &&
    variants.find((variant) => variant.code.toLowerCase() === "satin polish");

  const onFileLoad2 = (files) => {
    modelIsLoading();
    if (productChosen) {
      setItemType(null);
      setMaterial(sterlingSilver);
      setPolish(noPolish);
    }
    isProductChosen(false);
    setSelectedProduct(null);

    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      if (files[0] === void 0) return;

      if (!window.meshes) {
        window.meshes = {};
      }
      window.meshes[files[0].name] = files[0];
    }

    if (files.length === 0) return;

    if (files.length > 1) {
      this.showError("Too many files were selected");
      return;
    }
    fileReader.onload = (e) => {
      uploadFile(files[0].name, e.target.result);
    };

    fileReader.onprogress = function (data) {
      if (data.lengthComputable) {
        var progress = parseInt((data.loaded / data.total) * 100, 10);
      }
    };

    fileReader.readAsArrayBuffer(files[0]);
    setTimeout(() => {
      setChosen(false);
    }, 1800);
  };

  function setBlobs(blob, fileName) {
    const splitted = fileName ? fileName.split(".") : "";
    let container = new DataTransfer();
    let file = new File([blob], fileName ? `${splitted[0]}.stl` : "model.stl", {
      lastModified: new Date().getTime(),
    });
    container.items.add(file);
    setChosen(true);
    setSelectLoading(false);
    openDesignLibraryModal(false);
    openModalLibrary(false);
    setSortItemType(null);
    onFileLoad2(container.files);
  }

  const download3d = (url) => {
    fetch(url, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then(function (myBlob) {
        setBlobs(
          myBlob,
          activeTab === "default"
            ? selected3dModel.file_name
            : activeTab === "market"
            ? selectedMarketplace3dModel.modelFileName
            : ""
        );
      })
      .catch((e) => {
        console.log("error n fetchDonwlodn---", e);
      });
  };

  const chooseThreeDModel = async () => {
    setSelectLoading(true);
    if (activeTab !== "product") {
      isModelChosen(true);
    }
    if (activeTab === "default") {
      const res = await getPresignedUrl(selected3dModel.mesh_folder);
      if (res.url) {
        download3d(res.url);
      } else {
        toast.error("Could not load this file!");
        setSelectLoading(false);
      }
    } else if (activeTab === "market") {
      if (selectedMarketplace3dModel.model3D) {
        download3d(selectedMarketplace3dModel.model3D);
      } else {
        toast.error("Could not load this file!");
        setSelectLoading(false);
      }
    } else if (activeTab === "product") {
      isProductChosen(true);
      setItemType(selectedProduct);
      setSelectLoading(false);
      openModalLibrary(false);
      openDesignLibraryModal(false);
    }
  };

  const onFileLoad = (event) => {
    if (productChosen) {
      setItemType(null);
      setMaterial(sterlingSilver);
      setPolish(noPolish);
    }
    isProductChosen(false);
    setSelectedProduct(null);
    const files = event.currentTarget.files;

    modelIsLoading();
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      if (files[0] === void 0) return;

      if (!window.meshes) {
        window.meshes = {};
      }
      window.meshes[files[0].name] = files[0];
    }

    if (files.length === 0) return;
    if (files.length > 1) {
      this.showError("Too many files were selected");
      return;
    }
    fileReader.onload = (e) => {
      uploadFile(files[0].name, e.target.result);
    };
    fileReader.readAsArrayBuffer(files[0]);

    fileReader.onprogress = function (data) {
      //    loadProgress = (data.loaded / data.total) * 100;
      setProgress((data.loaded / data.total) * 100);
      setLoadProcess(Math.round((data.loaded / data.total) * 100));
    };

    if (modelChosen) {
      isModelChosen(false);
    }
  };

  progressPercentage(progress);

  const hideInput = conditionCrt ? profile?.addUpload : false;
  // const hideInput = true;

  const hideDesc = conditionCrt ? profile?.addDescription : false;
  // const hideDesc = true;

  return (
    <>
      <Row
        style={hideInput && hideDesc ? { margin: "0", marginTop: "30px" } : {}}
      >
        {productionPage ? (
          <div className="spread-title">
            {/* <div className="spread-first">Estimate cost production</div>
            <CalculatorFirst
              setWeight={setWeight}
              setHeight={setHeight}
              setLength={setLength}
              setWidth={setWidth}
            /> */}
          </div>
        ) : (
          <div
            className="spread-title"
            style={{
              margin: "0",
            }}
          >
            <div
              className="spread-first"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              Upload your 3d model
            </div>
            <div>
              <Button
                className="btn-lib"
                onClick={() => {
                  openModalLibrary(true);
                  openDesignLibraryModal(true);
                }}
                style={{
                  ...dynamicStyle(
                    profile?.primaryButtonColor,
                    profile?.buttonTextColor
                  ),
                  width: "100%",
                  fontSize: "16px",
                }}
              >
                Choose from Library
              </Button>
              {/* <span
                style={{
                  fontSize: ".95em",
                  padding: "1px 1px",
                }}
              >
                or
              </span>
              <Button
                variant="dark"
                id="button-d-tour"
                onClick={() => {
                  if (window) {
                    window.open(`${MARKET_URL}/projects-dashboard`, "_blank");
                  }
                }}
                style={dynamicStyle(
                  profile?.primaryButtonColor,
                  profile?.buttonTextColor
                )}
              >
                Design it with Us
              </Button> */}
            </div>
          </div>
        )}
      </Row>
      {!productionPage && (
        <>
          {hideInput ? (
            <Row style={hideInput ? { display: "none" } : {}}>
              <FileUploader
                fileExtensions={[".stl", ".glTF", ".obj", ".st"]}
                onFileLoad={(e) => (chosen ? onFileLoad2() : onFileLoad(e))}
                hideInput={hideInput}
              />
            </Row>
          ) : (
            <OverlayTrigger
              placement="top-start"
              overlay={
                <Tooltip id="tooltip-bottom">
                  To take a screenshot, rotate the 3d file in the position you
                  would like!
                </Tooltip>
              }
            >
              <Row>
                <FileUploader
                  fileExtensions={[".stl", ".glTF", ".obj", ".st"]}
                  onFileLoad={(e) => (chosen ? onFileLoad2() : onFileLoad(e))}
                  profile={profile}
                />
              </Row>
            </OverlayTrigger>
          )}
        </>
      )}
      <Row />
      <Row
        className="px-0"
        style={hideInput && hideDesc ? { margin: "0", marginTop: "30px" } : {}}
      >
        <Form.Label style={{ fontWeight: 600 }}>Jewelry Type:</Form.Label>
        <Form.Group>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-custom-1"
              variant="outline-secondary"
              disabled={!itemTypes || selectedProduct}
              style={{ width: "100%", textAlign: "left" }}
              // className={itemType ? "no-arrow" : "toggle-arrow"}
            >
              {itemType ? itemType.name : "Select"}
              {!itemTypes && <i className="fas fa-circle-notch fa-spin" />}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="outline-secondary">
              {itemTypes &&
                itemTypes
                  .filter((itemType) => !itemType.product)
                  .map((item) => (
                    <Dropdown.Item
                      key={item.id}
                      onClick={() => {
                        setItemType(item);
                        if (productionPage) {
                          estimatePricing(
                            item,
                            material,
                            plating,
                            polish,
                            assemblyOption,
                            laserEngravingOption,
                            1
                          );
                        }
                      }}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      </Row>
      {productionPage || hideDesc ? null : (
        <Row className="px-0 mt-1">
          <Form.Group>
            <Form.Label style={{ fontWeight: 600 }}>
              Describe Your Piece:
            </Form.Label>
            <Form.Control
              id="desc-tour"
              as="textarea"
              rows="3"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>
      )}
      <Modal
        title="Welcome to the Design Library"
        description="We've created the best and brightest 3D models for you to choose from. Just take your pick and make your specifications."
        show={isDesignLibraryModalOpen}
        handleClose={() => {
          openModalLibrary(false);
          openDesignLibraryModal(false);
          setSortItemType(null);
          replaceMarketplaceState(location);
        }}
        primaryButtons={[
          {
            title:
              activeTab === "product" ? "Select Product" : "Select 3D model",
            disabled:
              activeTab === "default"
                ? !selected3dModel
                : activeTab === "market"
                ? !selectedMarketplace3dModel
                : activeTab === "product"
                ? !selectedProduct
                : true,
            isLoading: selectLoading,
            handle: chooseThreeDModel,
            className: "lib-btn",
            style: dynamicStyle(
              profile?.primaryButtonColor,
              profile?.buttonTextColor
            ),
          },
        ]}
        dangerButtons={[
          {
            title: "Cancel",
            handle: () => {
              openModalLibrary(false);
              openDesignLibraryModal(false);
              setSortItemType(null);
              replaceMarketplaceState(location);
            },
          },
        ]}
        size={"lg"}
        headerClassName={"d-flex flex-column"}
        headerStyle={{
          paddingRight: "4rem",
          paddingLeft: "4rem",
          paddingBottom: "5px",
          paddingTop: "20px",
        }}
        bodyStyle={{ paddingRight: "2.5rem", paddingLeft: "2.5rem" }}
        loading={selectLoading}
      >
        <DesignLibraryModal
          models={D3models}
          itemTypes={itemTypes}
          setSelectedModel={setSelectedModel}
          selected3dModel={selected3dModel}
          setSortItemType={setSortItemType}
          sortItem={sortItem}
          membership={membership}
          setMostPopular={setMostPopular}
          popularClicked={popularClicked}
          projects={userProjects && userProjects}
          filteredDesign={filteredDesign}
          setFilteredDesign={setFilteredDesign}
          setSelectedMarket3dModel={setSelectedMarket3dModel}
          selectedMarketplace3dModel={selectedMarketplace3dModel}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          products={products}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          laserEngravingStep={false}
          setPolishPrice={setPolishPrice}
          setMaterialPrice={setMaterialPrice}
          setAssemblyPrice={setAssemblyPrice}
          setLaserEngravingPrice={setLaserEngravingPrice}
          setPlatingPrice={setPlatingPrice}
          materialPrice={materialPrice}
          platingPrice={platingPrice}
          polishPrice={polishPrice}
          assemblyPrice={assemblyPrice}
          setProductPricing={setProductPricing}
          profile={profile}
        />
      </Modal>
      {/* Marketplace Library */}
      {/* <DesignForm
        show={showDesign}
        handleClose={() => {
          openDesignWithUsModal(false);
          shouldShowDesign(false);
        }}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.login && state.login?.auth?.auth?.token,
  profile: state.login?.auth && state?.login?.auth?.profile,
  file: state.create.jewelry.file,
  itemType: state.create.jewelry.itemType,
  description: state.create.jewelry.description,
  membership:
    state.login.auth?.profile?.membership &&
    state.login.auth?.profile?.membership?.name,
  shopifyShopURL: state.login?.auth?.profile?.shopifyUrl
    ? state.login.auth?.profile?.shopifyUrl
    : null,
  shopifyAccessToken: state.login?.auth?.profile?.shopifyAccessToken
    ? state.login?.auth?.profile?.shopifyAccessToken
    : null,
  selected3dModel: state.create.jewelry && state.create.jewelry.selected3dModel,
  modelChosen: state.create.jewelry && state.create.jewelry.modelChosen,
  sortItem: state.create.jewelry && state.create.jewelry.sortItem,
  popularClicked: state.create.jewelry && state.create.jewelry.popularClicked,
  isLoading: state.create.jewelry?.isLoading?.modelIsLoading,
  userProjects: state.login && state.login.userProjects,
  selectedMarketplace3dModel:
    state.create.jewelry && state.create.jewelry.selectedMarketplace3dModel,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  laserEngravingOption: state.create.jewelry.laserEngravingOption,
  assemblyOption: state.create.jewelry.assemblyOption,
  modelHeight: state.create.jewelry.model_height,
  modelWidth: state.create.jewelry.model_width,
  modelLength: state.create.jewelry.model_length,
  weight: state.create.jewelry.weight,
  productChosen: state.create.jewelry && state.create.jewelry.productChosen,
});

const mapDispatchToProps = (dispatch) => ({
  setItemType: (type) => dispatch(setItemType(type)),
  uploadFile: (name, file) => dispatch(uploadFile(name, file)),
  setDescription: (description) => dispatch(setDescription(description)),
  modelIsLoading: () => dispatch(modelIsLoading()),
  openModalLibrary: (data) => dispatch(openModalLibrary(data)),
  setSelectedModel: (data) => dispatch(setSelected3dModel(data)),
  setSortItemType: (data) => dispatch(setSortItemType(data)),
  isModelChosen: (data) => dispatch(isModelChosen(data)),
  setMostPopular: (data) => dispatch(setMostPopular(data)),
  setLoadProcess: (data) => dispatch(setLoadingProcess(data)),
  openDesignWithUsModal: (data) => dispatch(openDesignWithUsModal(data)),
  setSelectedMarket3dModel: (data) => dispatch(setSelectedMarket3dModel(data)),
  setSelectedProduct: (data) => dispatch(setSelectedProduct(data)),
  getMarketplaceUsersDesigns: (userId) =>
    dispatch(getMarketplaceUsersDesigns(userId)),
  setWeight: (data) => dispatch(setWeight(data)),
  isProductChosen: (data) => dispatch(isProductChosen(data)),
  setMaterial: (material) => dispatch(setMaterial(material)),
  setPolish: (polish) => dispatch(setPolish(polish)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartOrderStep);
