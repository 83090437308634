import * as yup from 'yup';

const USPSRegex = /^[a-z0-9_-]{20,22}$/;
const fedexRegex = /\b\d{12}\b/;
const UPSRegex = /\b[a-zA-Z0-9]{18}\b/;

export default {
  shipping: yup.object().shape({
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    zipcode: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_.-]{2,}(?:-[a-zA-Z0-9_.-]{3})?$/,
        'Please enter a valid Zipcode'
      ),
    didLegal: yup
      .bool()
      .required()
      .oneOf([true], 'You must agree to our terms'),
    shippingMethod: yup.string().required(),
    USPSAccount: yup.string().when('shippingMethod', {
      is: 'USPS Account',
      then: yup
        .string()
        .required()
        .matches(USPSRegex, 'USPS account should be between 20 and 22 numbers'),
    }),
    FEDEXAccount: yup.string().when('shippingMethod', {
      is: 'FEDEX Account',
      then: yup
        .string()
        .required()
        .matches(fedexRegex, 'FEDEX account should be 12 numbers'),
    }),
    UPSAccount: yup.string().when('shippingMethod', {
      is: 'UPS Account',
      then: yup
        .string()
        .required()
        .matches(UPSRegex, 'UPS Account should be 18 characters'),
    }),
  }),
  card_token: yup.string().required(),
};
