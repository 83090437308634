import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./router/router";
import reduxStore from "./store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";

const { store, persistor } = reduxStore();

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  );
};

export default App;
