import React, { useState } from "react";
import { Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";

const CreditKey = ({ cart, salesTax, shippingPrice }) => {
  const [period, setPeriod] = useState(1);
  const handleChange = (e) => {
    setPeriod(e.target.value);
  };

  const monthlyPeriods = [1, 2, 3, 6, 9, 12];
  const finalPrice = cart.items.reduce(
    (acc, item) =>
      acc +
      Number(item.price.totalPrice * item.quantity) +
      Number(salesTax) +
      Number(shippingPrice),
    0
  );

  const priceCredit = (finalPrice / period).toFixed(2);
  const paymentDetail = (
    <div style={{ fontSize: "16px" }}>
      <span className="font-weight-bold">${priceCredit}/month</span>
    </div>
  );
  return (
    <Form>
      <FormLabel>
        <span className="font-weight-bold">Calculate your monthly payment</span>
      </FormLabel>
      <div>
        <FormGroup controlId="name">
          <FormLabel>Period</FormLabel>
          <Form.Control
            as="select"
            name={"month"}
            value={period}
            onChange={handleChange}
          >
            <option value="">Select a period</option>
            {monthlyPeriods &&
              monthlyPeriods.map((month) => (
                <option key={month} value={month}>
                  {month + "-month period"}
                </option>
              ))}
          </Form.Control>
        </FormGroup>
      </div>
      <div className="d-flex justify-content-between">
        <FormGroup controlId="months">
          <Row>
            <Col>
              <p>Payment:</p>
            </Col>
            <Col>
              <div>{paymentDetail}</div>
            </Col>
          </Row>
        </FormGroup>
      </div>
    </Form>
  );
};

export default CreditKey;
