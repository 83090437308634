import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import SalesTax from "sales-tax";

import CountryDropdown from "../CountryDropdown";
import StateDropdown from "../StateDropdown";
import ShippingMethods from "./ShippingMethods";

export const ConvertProfileToIntial = (profile) => {
  if (!profile) {
    return {
      state: "AL",
    };
  }
  if (!profile?.address) {
    return {
      state: "AL",
    };
  }
  return {
    name: profile?.first_name + " " + profile?.last_name,
    firstName: profile?.first_name,
    lastName: profile?.last_name,
    email: profile?.email,
    street: `${profile?.address?.street1} #${profile?.address?.street2}`,
    city: profile?.address?.city,
    zipcode: profile?.address?.zipcode,
    state: profile?.address?.state,
    country: profile?.address?.country,
  };
};

const ShippingInfo = ({
  onChange,
  shippingInfo,
  setIsValid,
  shipping,
  setValues,
  profile,
  touched,
  errors,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  cart,
  updateSalesTax,
  setShippingValues,
  setShippingRate,
  setShippoMethod,
  shippingRatePrice,
  shippoId,
  setShippoId,
  phone,
  shippingRates,
  rateFetched,
  setFetchRates,
  setRates,
  setShippoToken,
  setShippoCarrier,
  setShippoEstimatedDelivery,
  ...props
}) => {
  const isValid = (fieldName) => {
    return !errors[fieldName] && touched[fieldName];
  };
  const isInvalid = (fieldName) => {
    return errors[fieldName] && touched[fieldName];
  };

  const [addressChange, setAddressChange] = useState(false);
  const [countryChange, setCountryChange] = useState(false);

  const [shippingAddresses, setShippingAddress] = useState({
    address: null,
    city: null,
    zipcode: null,
    state: null,
    country: null,
  });

  useEffect(() => {
    if (Object.keys(shipping).length === 0) {
      setValues({
        firstName: profile?.first_name,
        lastName: profile?.last_name,
        email: profile?.email,
        country: profile?.address?.country,
        state: profile?.address?.state,
        address: `${profile?.address?.address1}, ${profile?.address?.address2}`,
        city: profile?.address?.city,
        zipcode: profile?.address?.zipcode,
      });
    } else {
      setValues({ ...props.values, ...shipping });
    }
  }, [shipping]);

  useEffect(() => {
    setShippingValues(values);
    setShippingAddress({
      address: values.address,
      city: values.city,
      zipcode: values.zipcode,
      state: values.state,
      country: values.country,
    });
  }, [values]);

  const shippingAddress =
    profile?.address?.address1 + ", " + profile?.address?.address2;

  useEffect(() => {
    if (profile) {
      onChange("firstName", profile?.first_name);
      onChange("lastName", profile?.last_name);
      onChange("email", profile?.email);
      onChange("country", profile?.address?.country);
      onChange("address", shippingAddress);
      onChange("city", profile?.address?.city);
      onChange("state", profile?.address?.state);
      onChange("zipcode", profile?.address?.zipcode);
    }
  }, []);

  return (
    <Form>
      <Form.Group controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="First Name"
          value={values.firstName}
          name="firstName"
          isValid={isValid("firstName")}
          isInvalid={isInvalid("firstName")}
          onChange={(e) => {
            onChange("firstName", e.target.value);
            handleChange(e);
            setShippingValues({ ...values, firstName: e.target.value });
          }}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.firstName}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Last Name"
          value={values.lastName}
          name="lastName"
          isValid={isValid("lastName")}
          isInvalid={isInvalid("lastName")}
          onChange={(e) => {
            onChange("lastName", e.target.value);
            handleChange(e);
            setShippingValues({ ...values, lastName: e.target.value });
          }}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          name="email"
          placeholder="Email Address"
          isValid={isValid("email")}
          isInvalid={isInvalid("email")}
          value={values.email}
          onChange={(e) => {
            onChange("email", e.target.value);
            handleChange(e);
            setShippingValues({ ...values, email: e.target.value });
          }}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="country" className="countries">
        <Form.Label>Country</Form.Label>
        <CountryDropdown
          name={"country"}
          value={Number(values.country)}
          isValid={isValid("country")}
          isInvalid={isInvalid("country")}
          onChange={(e) => {
            onChange("zipcode", "");
            setShippingAddress({
              ...shippingAddresses,
              country: e.target.value,
            });
            onChange("country", e.target.value);
            handleChange(e);
            setShippingValues({ ...values, country: e.target.value });
          }}
          handleBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.country}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="address">
        <Form.Label>Shipping Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Street Address"
          name="address"
          isValid={isValid("address")}
          isInvalid={isInvalid("address")}
          value={values.address}
          onChange={(e) => {
            onChange("city", "");
            onChange("address", e.target.value);
            setShippingAddress({
              ...shippingAddresses,
              address: e.target.value,
            });
            handleChange(e);
            setShippingValues({ ...values, address: e.target.value });
          }}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.street}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="city">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder="City"
          name="city"
          isValid={isValid("city")}
          isInvalid={isInvalid("city")}
          value={values.city}
          onChange={(e) => {
            setTimeout(() => {
              setAddressChange(true);
            }, [3000]);
            onChange("city", e.target.value);
            setShippingAddress({
              ...shippingAddresses,
              city: e.target.value,
            });
            handleChange(e);
            setShippingValues({ ...values, city: e.target.value });
          }}
          onBlur={handleBlur}
        />
        <Form.Control.Feedback type="invalid">
          {errors.city}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between">
        <Form.Group controlId="state" className="states">
          <Form.Label>State</Form.Label>
          <StateDropdown
            id={values.country}
            name="state"
            value={Number(values.state)}
            isValid={isValid("state")}
            isInvalid={isInvalid("state")}
            onChange={async (e) => {
              onChange("state", e.target.value);
              setShippingAddress({
                ...shippingAddresses,
                state: e.target.value,
              });
              handleChange(e);
              setShippingValues({ ...values, state: e.target.value });
              if (!profile?.isTaxExempt) {
                const tax = await SalesTax.getSalesTax(
                  "US",
                  e.target[e.target.selectedIndex].getAttribute("tax")
                );
                const total = cart.items.reduce(
                  (acc, item) => acc + Number(item.price.totalPrice.toFixed(2)),
                  0
                );
                updateSalesTax(total * tax.rate);
              } else {
                updateSalesTax(0.0);
              }
            }}
            handleBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {errors.state}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="zip">
          <Form.Label>Zip</Form.Label>
          <Form.Control
            type="text"
            name="zipcode"
            value={values.zipcode}
            isValid={isValid("zipcode")}
            isInvalid={isInvalid("zipcode")}
            onChange={(e) => {
              setTimeout(() => {
                setCountryChange(true);
              }, [2000]);
              setShippingAddress({
                ...shippingAddresses,
                zipcode: e.target.value,
              });
              onChange("zipcode", e.target.value);
              handleChange(e);
              setShippingValues({ ...values, zipcode: e.target.value });
            }}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">
            {errors.zipcode}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <Form.Group controlId="shippingMethod">
        <Form.Label>Shipping Options</Form.Label>
        <ShippingMethods
          name="shippingMethod"
          value={null}
          isValid={isValid("shippingMethod")}
          isInvalid={isInvalid("shippingMethod")}
          onChange={(e) => {
            setFieldValue("shippingMethod", e.target.value);
            onChange("shippingMethod", e.target.value);
            setShippoId(e.target.value);
            handleChange(e);
            setShippingRate(
              e.target[e.target.selectedIndex].getAttribute("price")
            );
            setShippoMethod(
              e.target[e.target.selectedIndex].getAttribute("servicename")
            );
            setShippoToken(
              e.target[e.target.selectedIndex].getAttribute("token")
            );
            setShippoCarrier(
              e.target[e.target.selectedIndex].getAttribute("carrier")
            );
            setShippoEstimatedDelivery(
              e.target[e.target.selectedIndex].getAttribute("estimatedDelivery")
            );
          }}
          onBlur={handleBlur}
          values={values}
          items={props.cartItems}
          phone={phone}
          addressChange={addressChange}
          setAddressChange={setAddressChange}
          countryChange={countryChange}
          setCountryChange={setCountryChange}
          shippingAddresses={shippingAddresses}
          shippingRates={shippingRates}
          rateFetched={rateFetched}
          setFetchRates={setFetchRates}
          setRates={setRates}
        />
        {errors && errors.shippingMethod && (
          <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
            You must select shipping method
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="formBasicChecbox">
        <Form.Check
          type="checkbox"
          className="text-primary terms"
          label={
            <>
              I have read and agree to the{" "}
              <a
                target="__blank"
                href="/terms-of-service"
                className="font-weight-bold"
              >
                Terms of Service{" "}
              </a>
              and{" "}
              <a
                target="__blank"
                href="/privacy-policy"
                className="font-weight-bold"
              >
                Privacy Policy
              </a>
            </>
          }
          isValid={isValid("didLegal")}
          isInvalid={isInvalid("didLegal")}
          name="didLegal"
          checked={values.didLegal}
          onChange={(e) => {
            setFieldValue("didLegal", e.target.checked);
          }}
          onBlur={handleBlur}
        />
        {errors && errors.didLegal && (
          <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
            You must agree to our Terms of Service and Privacy Policy
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.checkout.shipping,
  profile: state.login.auth.profile,
});

export default connect(mapStateToProps)(ShippingInfo);
