import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  DELETE_CART_ITEM_BY_ID,
  CLEAR_CART,
  GENERATE_CART_ID,
  SET_TAX,
  SET_SHIPPING_OPTION,
} from "../actionTypes";

export const addToCartRequest = (payload) => ({
  type: ADD_TO_CART_REQUEST,
  payload,
});

export const addToCartSuccess = (payload) => ({
  type: ADD_TO_CART_SUCCESS,
  payload,
});

export const addToCartFailure = () => ({
  type: ADD_TO_CART_FAILURE,
});

export const deleteCartItemById = (id) => ({
  type: DELETE_CART_ITEM_BY_ID,
  id,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const generateCartId = (id) => ({
  type: GENERATE_CART_ID,
  id,
});

export const setTax = (tax) => ({
  type: SET_TAX,
  tax,
});

export const setShippingOption = (shippingOption) => ({
  type: SET_SHIPPING_OPTION,
  shippingOption,
});
