import { v4 as uuid } from "uuid";

const API_URL = process.env.REACT_APP_API_URL;
const ERP_API_URL = process.env.REACT_APP_ERP_API;
const MARKETPLACE_API_ULR = process.env.REACT_APP_MARKETPLACE_API;

export const safeGetAuthFromState = (state) => {
  if (!!state.login.auth) {
    return state.login.auth.auth.accessToken;
  }
  return null;
};

export const apiFetch = async (endpoint, options, apiToken) => {
  options = options === void 0 ? {} : options;
  if (apiToken !== void 0 && apiToken !== null) {
    if (options.headers === void 0) {
      options.headers = {};
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${apiToken}`,
    };
  }
  const doFetch = async (retryNum) => {
    const response = await fetch(`${API_URL}${endpoint}`, options, apiToken);
    if (!response.ok && retryNum > 0) {
      return doFetch(retryNum - 1);
    }
    return response;
  };

  const response = await doFetch(1);
  if (!response.ok) {
    const e = new Error(
      `API responded with ${response.status}: ${response.statusText}`
    );
    e.status = response.status;
    e.response = response;
    throw e;
  }
  return response.json();
};

export const createCart = (apiToken) =>
  apiFetch("/carts", { method: "POST" }, apiToken);

export const uploadFile = async ({ id, file }, apiToken) => {
  let formData = new FormData();

  console.log("UPLOADING 3D MODEL----------------");
  formData.append("id", id);
  formData.append("file", file);

  const response = await apiFetch(
    "/models/presigned",
    {
      method: "POST",
      body: formData,
    },
    apiToken
  );

  let { url, fields, bucketKey } = response;

  formData = new FormData();

  for (let [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }

  formData.append("key", bucketKey);
  formData.append("file", file);

  const uploadOptions = {
    method: "POST",
    mode: "cors",
    body: formData,
  };

  const uploadResponse = await fetch(url, uploadOptions);

  if (!uploadResponse.ok) {
    throw new Error(
      `Failed to upload mesh to s3: responded with ${response.status}: ${response.statusText} `
    );
  }

  return { url, fields, bucketKey };
};

export const deleteFromCart = (cartID, itemID, apiToken) =>
  apiFetch(
    `/carts/${cartID}/items/${itemID}`,
    {
      method: "DELETE",
    },
    apiToken
  );

export const addToCart = (cartId, cartData, apiToken) => {
  const body = JSON.stringify({
    ...cartData,
    isCheckedOut: false,
  });
  return apiFetch(
    `/carts/${cartId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    },
    apiToken
  );
};

export const refreshCart = (cartID, apiToken) => {
  return apiFetch(`/carts/${cartID}`, { method: "GET" }, apiToken);
};

export const checkoutCart = async ({
  customer,
  tax,
  notes,
  shippingMethod,
  address,
  orderItems,
  apiToken,
  cartId,
  defaultCard,
  newCard,
  shippoOptions,
  shipping,
  matchedCode,
  creatorOrder,
}) => {
  // const formData = new FormData();
  // formData.append('customer', customer);
  // formData.append('tax', tax);
  // formData.append('notes', notes);
  // formData.append('shippingMethod', shippingMethod);
  // formData.append('address', address);
  // formData.append('orderItems', orderItems);
  // for (const orderItem of orderItems) {
  //   for (const image of orderItem.laserImage) {
  //     formData.append('images', image);
  //   }
  // }
  // formData.append('apiToken', apiToken);
  // formData.append('cartId', cartId);
  // formData.append('defaultCard', defaultCard);
  // formData.append('newCard', newCard);
  // formData.append('shippoOptions', shippoOptions);
  // formData.append('shipping', shipping);
  const res = await apiFetch("/orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({
      customer,
      tax,
      notes,
      shippingMethod,
      address,
      orderItems,
      cartId,
      defaultCard,
      newCard,
      shippoOptions,
      shipping,
      matchedCode,
      creatorOrder,
    }),
    // formData,
  });
  return res;
};

export const loginCreator = async (data) => {
  console.log("dataloginCreator api", data);
  const res = await fetch(
    `${ERP_API_URL}/manufacturers/login/gildform-os/creator`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  console.log("res ----", res);
  const dataRes = await res.json();

  console.log("dataRes", dataRes);
  return { ...dataRes, status: res.status };
};

export const getPrice = (price, authToken) => {
  return apiFetch("/pricing-gild-os", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(price),
  });
};

export const getAuth0Token = (authToken) => {
  return apiFetch("/users-token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const getErpMembershipContent = async () => {
  const res = await fetch(`${ERP_API_URL}/memberships`);
  const data = await res.json();
  return data;
};

export const uploadLaserEngravingImages = async (images, id) => {
  const formData = new FormData();
  for (const image of images) {
    formData.append("images", image);
  }
  formData.append("orderItemId", id);

  // const res = await axios.post(
  //   `${ERP_API_URL}/s3/upload-laser-engraving`,
  //   formData
  // );

  const resData = await fetch(`${ERP_API_URL}/s3/upload-laser-engraving`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: formData,
  });
  const data = await resData.json();
  return data;
};

export const uploadGildformStoreProductImages = async (image) => {
  const formData = new FormData();
  formData.append("image", image);
  const resData = await fetch(
    `${ERP_API_URL}/s3/upload-gildform-store-product-image`,
    {
      method: "POST",
      body: formData,
    }
  );
  const data = await resData.json();

  return data;
};
export const getTransactionsByCustomerId = async (id) => {
  const res = await fetch(`${ERP_API_URL}/transaction/customer/${id}`);
  const data = await res.json();
  return data;
};

export const getStripeConnectLoginLink = async (id) => {
  const res = await fetch(`${ERP_API_URL}/`);
};

export const sendEmailPasswordReset = async (data) => {
  const res = await fetch(`${ERP_API_URL}/mails/password-reset`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
};

export const getGildformStatuses = async (id) => {
  const res = await fetch(`${ERP_API_URL}/setting-groups/${id}`);
  const data = await res.json();
  return data;
};

export const getUserPermissions = async (id) => {
  const res = await fetch(`${ERP_API_URL}/setting-groups/${id}`);
  const data = await res.json();
  return data;
};

export const getProductVariants = async (id) => {
  const res = await fetch(`${ERP_API_URL}/items?type=p`);
  const data = await res.json();
  return data;
};

export const getSettingsById = async (id) => {
  const res = await fetch(`${ERP_API_URL}/settings/${id}`);
  const data = await res.json();
  return data;
};

export const getMarketplaceUser = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project/gildformUser/${userId}`
  );
  const data = await res.json();
  return data;
};

export const getMarketplaceUserProjectItems = async (userId) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project-items/user/auth0/${userId}`
  );
  const data = await res.json();
  return data;
};

export const getPresignedUrlMarketplace = async (folder, fileName) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/project/item/${fileName}/meshes/${folder}`
  );
  const data = await res.json();
  return data;
};

export const openConnectAccount = async (id) => {
  const res = await fetch(
    `${MARKETPLACE_API_ULR}/stripe/gildform/accLink/${id}`
  );
  const data = await res.json();
  return data;
};

export const getConnectAccount = async (id) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/stripe/connect/${id}`);
  return res;
};

export const getConnectAccountLink = async (id) => {
  const res = await fetch(`${MARKETPLACE_API_ULR}/stripe/account/link/${id}`);
  return res;
};

export const getShippingOptions = (apiToken) =>
  apiFetch(`/shipping-methods`, undefined, apiToken);

export const getStatesByCountry = (id, apiToken) =>
  apiFetch(`/countries/${id}/states`, undefined, apiToken);

export const getAllAssyemblyOptions = (itemId) =>
  apiFetch(`/items/subitems/${itemId}`);

export const uploadItemImage = (itemId, image) => {
  const formData = new FormData();

  formData.append("image", image);

  return apiFetch(`/screenshot`, {
    method: "POST",
    body: formData,
  });
};

export const getStatus = (jobOrderID, apiToken) => {
  return apiFetch(`/orders/${jobOrderID}`, undefined, apiToken);
};

export const getUser = (userID, apiToken) => {
  return apiFetch(`/users/${userID}`, undefined, apiToken);
};

export const getOrderById = (id, apiToken) => {
  return apiFetch(`/orders/${id}`, undefined, apiToken);
};

export const getOrdersByUserId = (userID, apiToken) => {
  return apiFetch(`/users/${userID}/orders`, undefined, apiToken);
};

export const getUserShopifyOrders = (userID, apiToken) =>
  apiFetch(`/shopify/orders/user/${userID}`, undefined, apiToken);

export const getAllCountries = (apiToken) =>
  apiFetch(`/countries`, undefined, apiToken);

export const getAllQuestions = (apiToken) =>
  apiFetch(`/questions/onboard`, undefined, apiToken);

export const getAnswer = (id, apiToken) =>
  apiFetch(`/answers/${id}`, undefined, apiToken);

export const getAllAnswers = (apiToken) =>
  apiFetch(`/answers`, undefined, apiToken);

export const getAnswersByQuestion = (id, apiToken) =>
  apiFetch(`/answers/question/${id}`, undefined, apiToken);

export const getAllRoadmaps = (apiToken) =>
  apiFetch(`/roadmap`, undefined, apiToken);

export const getAll3DModels = (apiToken) =>
  apiFetch(`/modelthreed`, undefined, apiToken);

export const getAll3DModelsCreator = async (apiToken, id) => {
  console.log("id getAll3DModelsCreator", id);
  const res = await fetch(`${ERP_API_URL}/modelthreed/creator/${id}`);
  const data = await res.json();
  return data;
};

export const getAll3DModelsCounted = (apiToken) =>
  apiFetch(`/modelthreed/counted`, undefined, apiToken);

export const getPresignedUrl = (folder, apiToken) =>
  apiFetch(`/meshes/${folder}`, undefined, apiToken);

export const getFonts = async () => {
  const res = await fetch(`${ERP_API_URL}/settings/fonts`);
  const data = await res.json();
  return data;
};

export const createRoadmapActivity = (data, apiToken) => {
  const milestoneUpdate = apiFetch(`/roadmap-activities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  });
  return milestoneUpdate;
};

export const getShippmentRates = async (data, token) => {
  const rates = await apiFetch(`/addShipment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  return rates;
};

export const getPromotionCodes = async (token) => {
  const res = await fetch(`${ERP_API_URL}/manufacturers/promo/codes`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data;
};

export const createUser = (auth0User, token) =>
  apiFetch(`/users/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(auth0User),
  });

export const createSubUser = (auth0User, token) =>
  apiFetch(`/users-sub/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(auth0User),
  });

export const getMembershipPlans = () => apiFetch(`/products`);

export const updateUser = (user, token) => {
  const userUpdate = apiFetch(`/users/${user.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });
  return userUpdate;
};

export const updateMembership = async (userID, newMembership, token) => {
  const membershipUpdate = await apiFetch(`/users/${userID}/subscription`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newMembership),
  });

  return membershipUpdate;
};

export const updateBilling = (userID, stripeToken, authToken) =>
  apiFetch(`/users/${userID}/billing`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ token: stripeToken }),
  });

export const addNewCreditCard = async (userID, stripeToken, authToken) => {
  return await apiFetch(`/users/${userID}/source`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ stripeToken: stripeToken }),
  });
};

export const getUsersCarts = async (userID, authToken) => {
  userID = encodeURI(userID);

  const carts = await apiFetch(
    `/carts/users/${userID}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
    authToken
  );

  return carts;
};

export const returnRequest = async (cartID, reason, authToken) => {
  return await apiFetch(
    `/return/${cartID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reason),
    },
    authToken
  );
};

export const requestDesigner = async (data, files) => {
  const reqId = uuid();
  let filePromises = [];
  if (files.length > 0) {
    filePromises = files.map(async (file) => {
      const fileName = `${reqId}/${file.path}`;

      let formData = new FormData();
      formData.append("fileName", reqId);
      const response = await apiFetch("/designers/presigned", {
        method: "POST",
        body: formData,
      });

      let { url, fields, bucketKey } = response;

      formData = new FormData();

      for (let [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }

      formData.append("key", bucketKey);
      formData.append("file", file);

      let uploadOptions = {
        method: "POST",
        mode: "cors",
        body: formData,
      };

      await fetch(url, uploadOptions);
      return fileName;
    });
  }

  data.file_locations = await Promise.all(filePromises);

  return await apiFetch("/designers/request", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};
