import { SET_GILDFORM_ITEM, SET_REDIRECTED_STRIPE } from "../actionTypes";

import {
  LOGIN,
  LOGOUT,
  GET_USER,
  CHECKED,
  SET_SHOPIFY_URL,
  SET_SHOPIFY_ACCESS_TOKEN,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAIL,
  FINISH_EDIT,
  UPDATE_CHECKLIST,
  SET_USER_PROJECTS,
  SET_IS_FIRST_TIME_LOGIN,
} from "./action";

const intialState = {
  auth: null,
  signUpWorkflow: {
    isFirstTimeLogin: false,
  },
  isAuthenticated: false,
  lastChecked: null,
  userLoading: false,
  membershipMsg: "",
  edited: false,
  userProjects: [],
  gildformItem: null,
  redirectedStripe: false,
};

export default (state, action) => {
  if (!state) {
    return intialState;
  }
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        auth: action.fullAuth,
        isAuthenticated: true,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        auth: null,
        isAuthenticated: false,
      };
    }
    case GET_USER: {
      return {
        ...state,
        auth: {
          ...state.auth,
          profile: action.user,
        },
      };
    }
    case UPDATE_CHECKLIST: {
      return {
        ...state,
        auth: {
          ...state.auth,
          profile: { ...state.auth.profile, questions: action.payload },
        },
      };
    }
    case EDIT_REQUEST: {
      return {
        ...state,
        userLoading: true,
        membershipMsg: "",
        edited: false,
      };
    }
    case EDIT_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        edited: true,
      };
    }
    case FINISH_EDIT: {
      return {
        ...state,
        userLoading: false,
        edited: false,
      };
    }
    case EDIT_FAIL: {
      return {
        ...state,
        userLoading: false,
        edited: false,
        membershipMsg: "Failed editing!",
      };
    }
    case CHECKED: {
      return {
        ...state,
        lastChecked: new Date(),
      };
    }
    case SET_SHOPIFY_URL: {
      return {
        ...state,
        auth: {
          ...state.auth,
          shopify: {
            ...state.auth.shopify,
            shopUrl: action.payload,
          },
        },
      };
    }
    case SET_SHOPIFY_ACCESS_TOKEN: {
      return {
        ...state,
        auth: {
          ...state.auth,
          shopify: {
            ...state.auth.shopify,
            accessToken: action.payload,
          },
        },
      };
    }
    case SET_USER_PROJECTS: {
      return {
        ...state,
        userProjects: action.payload,
      };
    }
    case SET_IS_FIRST_TIME_LOGIN: {
      return {
        ...state,
        signUpWorkflow: {
          ...state.signUpWorkflow,
          isFirstTimeLogin: action.payload,
        },
      };
    }
    case SET_GILDFORM_ITEM: {
      return {
        ...state,
        gildformItem: action.payload,
      };
    }
    case SET_REDIRECTED_STRIPE: {
      return {
        ...state,
        redirectedStripe: action.payload,
      };
    }
    default:
      return state;
  }
};
