import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";

import Option from "./AssembyOptions";
import {
  openModalLibrary,
  removeLaserEngravingOption,
  setFontLaser,
  setLaserCustomText,
  setLaserImage,
  setLibraryProduct,
} from "../../store/create/jewelry/action";
import { setLaserEngOption } from "../../store/create/jewelry/thunks";
import { getPrice } from "../../store/create/jewelry/thunks";
import noImage from "../../assets/images/noimage.jpg";
import { showMinQty } from "./Material";
import Modal from "../Modal";
import DesignLibraryModal from "../DesignLibraryModal";
import {
  getGildformStatuses,
  getMarketplaceUserProjectItems,
} from "../../utils/api";
import { dynamicStyle } from "../../utils/helpers";

//steps for the joyride
const steps = [
  {
    title: "Select an laser engraving option",
    target: "#my-row-tour",
    content: "",
  },
  {
    title: "Continue",
    target: "#continue-tour",
    content: "Press continue to move on to the next step.",
  },
  {
    title: "Skip this step",
    target: "#skip-my-tour-earrings",
    content:
      "If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of Ear Post (including but not limited to, ear wires, hinges or joint and catch sets) in your 3D model(s).  You must select Ear Posts, if you’d like your finished product to have ear posts.",
  },
];

const steps2 = [
  {
    title: "Select an assembly option",
    target: "#my-row-tour",
    content:
      "You may choose to have your pendant come assembled with the chains/necklaces/bracelets you select.",
  },
  {
    title: "Continue",
    target: "#continue-tour",
    content: "Press continue to move on to the next step.",
  },
  {
    title: "Skip this step",
    target: "#skip-my-tour-earrings",
    content:
      "If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of chains in your 3D model(s).  You must select a chain type and length, if you’d like your finished product to come with assembled with a chain.",
  },
];

const LaserEngraving = ({
  itemType,
  laserEngravingOption,
  laserEngravingOptions,
  total,
  quantity,
  priceIsLoading,
  getPrice,
  setLaserEngravingOption,
  removeLaserEngravingOption,
  paymentDetail,
  membership,
  productionPage,
  estimatePricing,
  material,
  polish,
  plating,
  assemblyOption,
  productChosen,
  setLaserEngravingPrice,
  totalProductPrice,
  setLaserFont,
  setLaserCustomText,
  setLaserImage,
  laserImage,
  profile,
  openModalLibrary,
  setLibraryProduct,
  libraryProduct,
}) => {
  let title = "Select Laser Engraving";
  const [customTextAndFont, setCustomTextAndFont] = useState("");
  const [font, setFont] = useState({ font_name: "", font_family: "" });
  const [customText, setCustomText] = useState(false);
  const [uploadImageOfText, setUploadImageOfText] = useState(false);
  const [chooseFromGildformLibrary, setChooseFromGildformLibrary] =
    useState(false);
  const [activeTab, setActiveTab] = useState("market");
  const [erpFonts, setErpFonts] = useState([]);
  const [laserEngravingProjectItems, setLaserEngravingProjectItems] = useState(
    []
  );
  const [filterProjectItems, setFilterProjectItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  let Disclaimer = ({ msg }) => <p className="disclamer">{msg}</p>;

  const getFonts = async () => {
    const res = await getGildformStatuses(12);
    setErpFonts(res.settings ? res.settings : []);
  };

  // const getUserProjectItems = async (id) => {
  //   const res = await getMarketplaceUserProjectItems(id);
  //   const newArr = res.filter(
  //     (item) => item.laserEngFile && item.laserEngFileName
  //   );
  //   setLaserEngravingProjectItems(newArr);
  // };

  useEffect(() => {
    // getUserProjectItems(profile && profile.id);
    setLibraryProduct(null);
    if (customText && erpFonts.length === 0) {
      getFonts();
    }
  }, [customText]);

  const conditionCrt = profile?.creatorId;

  return (
    <>
      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "600px" }}
      >
        <Row id="my-row-tour" className="d-flex flex-column flex-nowrap h-100">
          <p>{title}</p>
          {laserEngravingOptions
            .filter((lasEngOption) => lasEngOption.item.id === itemType.id)
            .map(({ variant }) => {
              const assemblyQty = variant.min_qty
                ? JSON.parse(variant.min_qty)
                : null;

              const mess = showMinQty(assemblyQty, variant, membership, true);
              return (
                <Option
                  key={variant.id}
                  title={variant.name}
                  picture={variant.image ? variant.image : noImage}
                  description={variant.description}
                  isSelected={variant == laserEngravingOption}
                  min_qty={variant.min_qty}
                  mess={mess}
                  quantity={quantity}
                  onSelect={() => {
                    if (variant == laserEngravingOption) {
                      removeLaserEngravingOption();
                      getPrice();
                      return;
                    }
                    setLaserEngravingOption(variant);
                    getPrice();
                    if (productionPage) {
                      estimatePricing(
                        itemType,
                        material,
                        plating,
                        polish,
                        assemblyOption,
                        laserEngravingOption,
                        quantity
                      );
                    } else if (productChosen) {
                      setLaserEngravingPrice(variant ? variant.base_price : 0);
                    }
                  }}
                />
              );
            })}
        </Row>
        <Row id="" className="h-100 w-100">
          <div
            className="action-buttons d-flex flex-column"
            style={{ paddingTop: "10px" }}
          >
            <Button
              style={
                conditionCrt
                  ? {
                      ...dynamicStyle(
                        profile?.primaryButtonColor,
                        profile?.buttonTextColor
                      ),
                      width: "fit-content",
                    }
                  : { width: "fit-content" }
              }
              className={conditionCrt ? "mb-2" : "custom-text-and-font mb-2"}
              onClick={() => {
                {
                  setCustomText(true);
                  setUploadImageOfText(false);
                  setChooseFromGildformLibrary(false);
                  setLibraryProduct(null);
                  setLaserImage([]);
                }
              }}
            >
              Custom text
            </Button>
            <Button
              style={
                conditionCrt
                  ? {
                      ...dynamicStyle(
                        profile?.primaryButtonColor,
                        profile?.buttonTextColor
                      ),
                      width: "fit-content",
                    }
                  : { width: "fit-content" }
              }
              className={conditionCrt ? "mb-2" : "upload-image mb-2"}
              onClick={() => {
                setUploadImageOfText(true);
                setCustomText(false);
                setChooseFromGildformLibrary(false);
                setLibraryProduct(null);
                setLaserCustomText(null);
                setLaserFont(null);
              }}
            >
              Upload Image
            </Button>
            <Button
              style={
                conditionCrt
                  ? {
                      ...dynamicStyle(
                        profile?.primaryButtonColor,
                        profile?.buttonTextColor
                      ),
                      width: "fit-content",
                    }
                  : { width: "fit-content" }
              }
              className={`${
                conditionCrt ? "mb-2" : "choose-from-library mb-2"
              }`}
              onClick={() => {
                openModalLibrary(true);
                setChooseFromGildformLibrary(true);
                setCustomText(false);
                setUploadImageOfText(false);
                setLaserImage([]);
                setLaserCustomText(null);
                setLaserFont(null);
              }}
            >
              Choose from Library
            </Button>
          </div>
          <div className="engraving-options d-flex flex-column w-100">
            {customText && !uploadImageOfText && !chooseFromGildformLibrary && (
              <div className="custom-font mt-3">
                <label>Custom Text And Font</label>
                <div className="inputs">
                  <input
                    type="text"
                    name="custom-text-font"
                    className="mb-1 w-100"
                    onChange={(e) => {
                      setCustomTextAndFont(e.target.value);
                      setLaserCustomText(e.target.value);
                    }}
                  />
                  <Form.Control
                    as="select"
                    name={"font"}
                    value={font.font_name}
                    className="mt-1"
                    onChange={(e) => {
                      setFont({
                        font_name: e.target.value,
                        font_family:
                          e.target[e.target.selectedIndex].getAttribute(
                            "fontFamily"
                          ),
                      });
                      setLaserFont(e.target.value);
                    }}
                  >
                    <option value="">Select a font</option>
                    {erpFonts &&
                      erpFonts.map((font, index) => {
                        return (
                          <option
                            key={index}
                            value={font.name}
                            fontFamily={font.description}
                          >
                            {font.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </div>
                <div className="output d-flex flex-column mt-2">
                  <span className="mb-1">Output</span>
                  {customTextAndFont ? (
                    <span
                      style={
                        font && customTextAndFont
                          ? {
                              fontFamily: `'${font.font_name}', ${font.font_family}`,
                              fontSize: "25px",
                            }
                          : ""
                      }
                    >
                      {customTextAndFont}
                    </span>
                  ) : null}
                </div>
              </div>
            )}
            {uploadImageOfText && !customText && !chooseFromGildformLibrary && (
              <div className="upload-image-text mt-3">
                <label>Upload Images</label>
                <div className="inputs d-flex flex-column">
                  <input
                    className="w-100 mb-2"
                    type="file"
                    name="file-input"
                    onChange={async (e) => {
                      if (e.target.files.length > 0) {
                        if (laserImage !== null) {
                          setLaserImage([...laserImage, e.target.files[0]]);
                        } else {
                          setLaserImage([...e.target.files[0]]);
                        }
                      }
                    }}
                  />
                  <div
                    className="d-flex flex-wrap"
                    style={{ maxHeight: "180px", overflowY: "scroll" }}
                  >
                    {laserImage &&
                      laserImage.map((item, index) => (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            color: "white",
                            backgroundColor: "#7c6262",
                            padding: "5px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                          key={index}
                        >
                          <span
                            className="image-name"
                            style={{ wordBreak: "break-all" }}
                          >
                            {item && item.name}
                          </span>
                          <i
                            className="fa fa-times del-tag-icon ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const newArr = laserImage.filter(
                                (image) => image !== item
                              );
                              setLaserImage(newArr);
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {libraryProduct && !customText && !uploadImageOfText && (
              <div className="selected-product d-flex flex-column">
                <span className="mb-3 font-weight-bold  ">Product:</span>
                <span>{libraryProduct.laserEngFileName}</span>
              </div>
            )}
          </div>
        </Row>
        {/* )} */}
      </div>
      <Disclaimer msg="" />
      {!productionPage && (
        <>
          <Row>
            <Col>
              <p>Price</p>
            </Col>
            <Col>
              <p style={{ textAlign: "right" }}>
                USD
                {priceIsLoading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  <>
                    $
                    {productChosen
                      ? totalProductPrice.toFixed(2)
                      : total.totalPrice.toFixed(2)}
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <div>{paymentDetail}</div>
          </Row>
        </>
      )}
      <Modal
        title="Welcome to the Your Library"
        description=""
        show={chooseFromGildformLibrary}
        handleClose={() => {
          openModalLibrary(false);
          setChooseFromGildformLibrary(false);
          setLibraryProduct(selectedItem);
        }}
        primaryButtons={[
          {
            title:
              activeTab === "market" ? "Select Product" : "Select 3D model",
            disabled: activeTab === "market" && !selectedItem ? true : false,
            style: dynamicStyle(
              profile?.primaryButtonColor,
              profile?.buttonTextColor
            ),
            handle: () => {
              openModalLibrary(false);
              setChooseFromGildformLibrary(false);
              setLibraryProduct(selectedItem);
            },
          },
        ]}
        dangerButtons={[
          {
            title: "Cancel",
            handle: () => {
              openModalLibrary(false);
              setChooseFromGildformLibrary(false);
            },
          },
        ]}
        size={"lg"}
        headerClassName={"d-flex flex-column"}
        headerStyle={{
          paddingRight: "4rem",
          paddingLeft: "4rem",
          paddingBottom: "5px",
          paddingTop: "20px",
        }}
        bodyStyle={{ paddingRight: "2.5rem", paddingLeft: "2.5rem" }}
      >
        <DesignLibraryModal
          models={[]}
          itemTypes={[]}
          setSelectedModel={() => {}}
          selected3dModel={() => {}}
          setSortItemType={() => {}}
          sortItem={[]}
          membership={[]}
          setMostPopular={() => {}}
          popularClicked={false}
          //======
          projectItems={laserEngravingProjectItems}
          filterProjectItems={filterProjectItems}
          setFilterProjectItems={setFilterProjectItems}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          laserEngravingStep={true}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          //=======
          filteredDesign={[]}
          setFilteredDesign={() => {}}
          setSelectedMarket3dModel={() => {}}
          selectedMarketplace3dModel={() => {}}
          products={[]}
          setSelectedProduct={() => {}}
          selectedProduct={[]}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
  total: state.create.price.price,
  jewelry: state.create.jewelry,
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  assemblyOption: state.create.jewelry.assemblyOption,
  laserEngravingOption: state.create.jewelry.laserEngravingOption,
  laserEngravingOptions: state.itemConfigs.configs.filter(
    (itemConfig) => itemConfig.subItem.name.toLowerCase() === "laser engraving"
  ),
  laserImage: state.create.jewelry && state.create.jewelry.laserImage,
  priceIsLoading:
    state.create.price.isFetching ||
    state.create.jewelry.isLoading.modelIsLoading,
  membership:
    state.login.auth?.profile?.membership &&
    state.login.auth?.profile?.membership?.nickname
      ? state.login.auth?.profile?.membership?.nickname
      : state.login.auth?.profile?.membership?.name,
  quantity: state.create.jewelry.quantity,
  selected3dModel: state.create.jewelry && state.create.jewelry.selected3dModel,
  libraryProduct: state.create.jewelry && state.create.jewelry.libraryProduct,
});

const mapDispatchToProps = (dispatch) => ({
  getPrice: () => dispatch(getPrice()),
  setLaserEngravingOption: (option) => dispatch(setLaserEngOption(option)),
  setLibraryProduct: (libraryProduct) =>
    dispatch(setLibraryProduct(libraryProduct)),
  setLaserFont: (data) => dispatch(setFontLaser(data)),
  setLaserCustomText: (data) => dispatch(setLaserCustomText(data)),
  setLaserImage: (data) => dispatch(setLaserImage(data)),
  openModalLibrary: (data) => dispatch(openModalLibrary(data)),
  removeLaserEngravingOption: () => dispatch(removeLaserEngravingOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LaserEngraving);
