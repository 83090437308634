import React, { useState } from "react";
import logo from "../assets/images/Gildformiconblack.png";
import logoText from "../assets/images/logo.png";
import "../style/_login.scss";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { LoginCreator } from "../store/auth/thunks";
import { useNavigate } from "react-router-dom";

const LoginPage = (props) => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  let successLogin = true;
  const windowLocation = window?.location;

  const navigate = useNavigate();
  return (
    <Formik
      initialValues={loginData}
      validate={(values) => {
        const errors = {};
        if (!values.email.trim()) {
          errors.email = "Email is required";
        }
        if (!values.password.trim()) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log("RESULT", values.email + " " + values.password);
        setSubmitting(false);
        const data = {
          email: values.email,
          password: values.password,
        };
        const resData = await props.loginCrt(data);
        console.log("resData", resData);
        successLogin = resData.exists;
        if (resData.exists) {
          setTimeout(() => {
            // window.location.href = resData?.pageUrl
            //   ? `${windowLocation.origin}${resData?.pageUrl}`
            //   : `${windowLocation.origin}/model`;
            navigate(resData?.pageUrl ? `${resData?.pageUrl}` : `/model`);
          }, 1000);
        }
      }}
    >
      {({
        handleChange,
        touched,
        values,
        errors,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div className="mainContainer">
          <div className="formContainer">
            <div className="leftForm">
              <img src={logo} alt="gildformLogo" />
              <img src={logoText} alt="gildformLogotext" />
            </div>
            <form className="rightForm" onSubmit={handleSubmit}>
              <p>Log In</p>
              <div className="inputGroup">
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={values.email}
                  className="inputField"
                />
                {values.email ? (
                  <label
                    htmlFor="email"
                    className="field_label_fixed"
                    style={{ fontSize: "17px" }}
                  >
                    Email
                  </label>
                ) : (
                  <label
                    htmlFor="email"
                    className="field_label"
                    style={{ fontSize: "17px" }}
                  >
                    Email
                  </label>
                )}
                {errors.email && touched.email && errors.email && (
                  <p className="error">{errors.email}</p>
                )}
              </div>
              <div className="inputGroup">
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className="inputField"
                />
                {values.password ? (
                  <label
                    htmlFor="password"
                    className="field_label_fixed"
                    style={{ fontSize: "17px" }}
                  >
                    Password
                  </label>
                ) : (
                  <label
                    htmlFor="password"
                    className="field_label"
                    style={{ fontSize: "17px" }}
                  >
                    Password
                  </label>
                )}
                {errors.password && touched.password && errors.password && (
                  <p className="error">{errors.password}</p>
                )}
              </div>
              <Button type="submit" disabled={isSubmitting} onClick={() => {}}>
                Log In
              </Button>
              {!successLogin && (
                <p className="error">Please check your credentials</p>
              )}
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  // material: state.create.jewelry.material,
  // plating: state.create.jewelry.plating,
});

const mapDispatchToProps = (dispatch) => ({
  loginCrt: (data) => dispatch(LoginCreator(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
