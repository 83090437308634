import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import Loader from "../MainLoader";
import { getShippingOptions, getShippmentRates } from "../../utils/api";

const ShippingOptions = ({
  authToken,
  name,
  value,
  onChange,
  isValid,
  isInvalid,
  handleBlur,
  values,
  items,
  phone,
  addressChange,
  countryChange,
  setAddressChange,
  setCountryChange,
  shippingAddresses,
  shippingRates,
  rateFetched,
  setFetchRates,
  setRates,
}) => {
  const [shippingOptions, setShippingOptions] = useState(null);

  const getAllShippingOptions = async () => {
    const data = await getShippingOptions(authToken);
    setShippingOptions(data);
  };

  const fetchShippmentRates = async (data) => {
    setFetchRates(0);
    const res = await getShippmentRates(data, authToken);
    if (res.status === "SUCCESS") {
      if (res.rates.length !== 0) {
        setFetchRates(1);
      }
      setRates(res.rates);
      setAddressChange(false);
      setCountryChange(false);
    } else {
      setFetchRates(2);
      setAddressChange(false);
      setCountryChange(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAllShippingOptions();
    }, 100);
  }, []);

  useEffect(() => {
    if (countryChange || addressChange) {
      setTimeout(() => {
        const data = {
          city: shippingAddresses.city,
          address1: shippingAddresses.address,
          state: shippingAddresses.state,
          country: shippingAddresses.country,
          zipcode: shippingAddresses.zipcode,
          name: values.firstName + " " + values.lastName,
          items,
          phone: phone,
        };
        fetchShippmentRates(data);
      }, 3500);
    }
  }, [countryChange, addressChange]);

  if (rateFetched === 2) {
    return shippingOptions ? (
      <Form.Control
        as="select"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        isValid={!!isValid}
        isInvalid={!!isInvalid}
      >
        <option value={""} price={null}>
          Select a shipping option
        </option>
        {shippingOptions.map((shippingOption) => (
          <option
            key={shippingOption.id}
            value={shippingOption.id}
            price={shippingOption.price}
            servicename={shippingOption.code}
          >
            {shippingOption.name + "  |  $" + shippingOption.price}
          </option>
        ))}
      </Form.Control>
    ) : (
      <div />
    );
  } else if (rateFetched === 1) {
    return (
      <Form.Control
        as="select"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        isValid={!!isValid}
        isInvalid={!!isInvalid}
      >
        <option value={null} price={null}>
          Select a shipping option
        </option>
        {shippingRates &&
          shippingRates.map((shippingOption) => {
            const alloweProps = {
              key: shippingOption.object_id,
              value: shippingOption.object_id,
              price: shippingOption.amount,
              objectId: shippingOption.object_id,
              servicename: `${shippingOption.provider} ${shippingOption.servicelevel.name}`,
              token: `${shippingOption.servicelevel.token}`,
              carrier: `${shippingOption.carrier_account}`,
              estimatedDelivery: shippingOption.estimated_days,
            };
            return (
              <option {...alloweProps}>
                {`${shippingOption.provider}-${shippingOption.servicelevel.name}` +
                  "  |  $" +
                  shippingOption.amount +
                  ` | ${shippingOption.estimated_days} ${
                    shippingOption.estimated_days === "1" ? "day" : "days"
                  }`}
              </option>
            );
          })}
      </Form.Control>
    );
  } else {
    return (
      <div className="d-flex flex-row justify-space-between">
        <span className="mr-2" style={{ color: "maroon" }}>
          Checking your shipping options
        </span>
        <Loader />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  authToken: state.login.auth.auth.accessToken,
  checkout: state.checkout.shipping,
});

export default connect(mapStateToProps)(ShippingOptions);
