import React, { useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import Layout from "../modules/Layout";
// import SEO from "../components/Seo";
import BlueSection from "../components/homepage/BlueSection";
import vectorarrow from "../assets/images/vectorarrow.png";
import CustomCarousel from "../components/CustomCarousel";
import LaunchYourBrand from "../components/homepage/LaunchYourBrand";
import { isToggleOpen, setOpenToggle } from "../utils/helpers";
import WindowWidth from "../components/WindowWidth";
import ButtonLink from "../components/ButtonLink";
import Banner from "../components/Banner";
import MainLoader from "../components/MainLoader";

const Homepageindex = (props) => {
  const [toggle, setToggle] = useState({ id: "", toggle: false });
  const [index, setIndex] = useState(0);
  const [wpContent, setWpContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [testToggle, setTestToggle] = useState([]);

  const width = WindowWidth();

  const fetchWpQuery = async () => {
    setLoading(true);
    const res = await axios.get(
      "https://content.gildform.com/wp-json/acf/v3/home_page/727"
    );
    if (res.status === 200) {
      setWpContent(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWpQuery();
  }, []);

  const all_in_one = wpContent && wpContent.acf.all_in_one[0];
  const customer_brands = wpContent && wpContent.acf.customer_brands;
  const faq = wpContent && wpContent.acf.faq[0];
  const hero_section = wpContent && wpContent.acf.hero_section[0];
  const homepage_cards = wpContent && wpContent.acf.homepage_cards;
  const how_it_works = wpContent && wpContent.acf.how_it_works[0];
  const jewelry_designs = wpContent && wpContent.acf.jewelry_designs[0];
  const produce_now = wpContent && wpContent.acf.produce_now[0];

  return loading ? (
    <div style={{ marginTop: "10rem" }}>
      <MainLoader />
    </div>
  ) : (
    <>
      {/* {props.profile && !props.profile.answers ? <Banner /> : null} */}
      <Layout
        light_header={undefined}
        sit_on_top
        className="home"
        noAnswers={props.profile && !props.profile.answers ? true : false}
      >
        {/* <SEO title="Gildform" /> */}
        <div className="homepage-container">
          <div className="homepage-hero-container">
            <div className="homepage-hero-main">
              <div className="homepage-text">
                <div className="homepage-hero-title">
                  <span>{hero_section?.title}</span>
                </div>
                <div className="homepage-hero-description">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: hero_section?.description,
                    }}
                  />
                </div>
                <div className="hero-button">
                  <ButtonLink
                    {...hero_section?.button[0]}
                    className={"hero-button"}
                    size={"lg"}
                  />
                </div>
              </div>
              <div className="homepage-hero-image">
                <img src={hero_section?.image.url} />
                <div className="arrow-wrapper">
                  <div className="hero-info">
                    <img src={vectorarrow} />
                    <span
                      className="hero-info-text"
                      dangerouslySetInnerHTML={{
                        __html: hero_section?.image_description.split("by")[0],
                      }}
                    />
                    <span className="hero-info-text">by</span>
                    <span
                      className="hero-names"
                      dangerouslySetInnerHTML={{
                        __html: hero_section?.image_description.split("by")[1],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="homepage-brands">
              {width > 992 ? (
                <div className="brands-text">
                  <span>PARTNERS AND CREATORS</span>
                  <div className="brand-logos">
                    {customer_brands?.map((brand, index) => (
                      <div className="brand-logo" key={index}>
                        <img src={brand.image.url} />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="brands-text">
                  <span>PARTNERS AND CREATORS</span>
                  <div className="brand-logos">
                    <CustomCarousel show={1}>
                      {customer_brands?.map((brand, index) => (
                        <div className="brand-logo" key={index}>
                          <img src={brand.image.url} />
                        </div>
                      ))}
                    </CustomCarousel>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="homepage-roadmap">
            <div className="homepage-roadmap-title-wrapper">
              <div className="homepage-roadmap-title">
                <span>{how_it_works?.title}</span>
              </div>
              <div className="homepage-roadmap-description">
                <span
                  dangerouslySetInnerHTML={{
                    __html: how_it_works?.description,
                  }}
                />
              </div>
            </div>
            <Carousel
              interval={null}
              indicators={false}
              activeIndex={index}
              prevIcon={""}
              nextIcon={""}
              // style={{
              // height: width < 768 ? 'fit-content' : '85vh',
              // }}
            >
              {how_it_works &&
                how_it_works?.step_content.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div className="homepage-roadmap-wrapper">
                        <div className="homepage-roadmap-image">
                          <img src={item.image.url} />
                        </div>
                        <div className="homepage-roadmap-info">
                          <div className="milestone-steps">
                            <span>STEP {index + 1}</span>
                          </div>
                          <div className="milestone-title">
                            <span
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </div>
                          <div className="milestone-description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </div>
                          <div className="milestone-buttons">
                            <div className="milestone-button">
                              <ButtonLink {...how_it_works.buttons[0]} />
                            </div>
                            <div className="milestone-button-learn-more">
                              <ButtonLink {...how_it_works.buttons[1]} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
            <div className="milestone-stepper">
              <Button
                className="carousel-buttons"
                onClick={() => {
                  setIndex(
                    index === 0
                      ? how_it_works?.step_content.length - 1
                      : index - 1
                  );
                }}
              >
                <i className="fas fa-arrow-left" />
              </Button>
              <Button
                className="carousel-buttons"
                onClick={() => {
                  setIndex(
                    index === how_it_works?.step_content.length - 1
                      ? 0
                      : index + 1
                  );
                }}
              >
                <i className="fas fa-arrow-right" />
              </Button>
            </div>
          </div>
          <BlueSection location="home" cards={homepage_cards} />
          <div className="featured-designs">
            <div className="title-section">
              <div className="design-title">
                <span>{jewelry_designs?.title}</span>
              </div>
              <div className="design-description">
                <span
                  dangerouslySetInnerHTML={{
                    __html: jewelry_designs?.description,
                  }}
                />
              </div>
            </div>
            <div className="designs-section">
              <CustomCarousel show={width < 992 ? 1 : 4}>
                {jewelry_designs?.card.map((card, index) => (
                  <div className="designer-card" key={index}>
                    <div className="photo-section">
                      <img src={card.image.url} />
                    </div>
                    <div
                      className="design-title"
                      dangerouslySetInnerHTML={{ __html: card.description }}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </div>
          <div className="partner-section">
            <div className="partner-info-section">
              <div className="partner-title">
                <span>{all_in_one?.title}</span>
              </div>
              <div className="partner-description">
                <span
                  dangerouslySetInnerHTML={{ __html: all_in_one?.description }}
                />
              </div>
              <div className="partner-button">
                <ButtonLink {...all_in_one?.button[0]} />
              </div>
            </div>
            <div className="partner-photo-section">
              <img src={all_in_one?.image.url} />
            </div>
          </div>
          <LaunchYourBrand content={produce_now} location={"homepage"} />
          <div className="faq-section">
            <div className="faq-title">
              <span>FAQ</span>
            </div>
            <div className="links">
              {faq?.faq_items?.map((faqItem, index) => {
                const open = isToggleOpen(index, testToggle);
                return (
                  <div className="single-link" key={index}>
                    <div
                      key={index}
                      className="link-click"
                      data-toggle="collapse"
                      data-target={`#link${index}`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={index}
                      onClick={() => {
                        setOpenToggle(
                          { id: index, toggle: !toggle.toggle },
                          testToggle,
                          setTestToggle
                        );
                      }}
                    >
                      <span
                        className={
                          isToggleOpen(index, testToggle)
                            ? "link-title"
                            : "link-title-collapsed"
                        }
                      >
                        {faqItem.title}
                      </span>
                      {!isToggleOpen(index, testToggle) ? (
                        <i className="fas fa-plus" />
                      ) : (
                        <i className="fas fa-minus" />
                      )}
                    </div>
                    {open && (
                      <div className="collapsee" id={`link${index}`}>
                        <div className="link-content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: faqItem.description,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs && state.itemConfigs.configs,
  profile: state.login && state.login?.auth?.profile,
  login: state.login,
  create: state.create,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Homepageindex);
