import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainLoader from "../components/MainLoader";
import Index from "../pages/index";
import Model from "../pages/model";
import Login from "../pages/login";
import { useSelector } from "react-redux";
import Redirect from "../pages/redirect";

// const Layout = lazy(() => import('../containers/Layout'));
// const Login = lazy(() => import('../modules/auth/Login'));
// const ResetPassword = lazy(() => import('../modules/auth/ResetPassword'));
// const Page404 = lazy(() => import('../pages/Page404'));
// const Page500 = lazy(() => import('../pages/Page500'));
// const PageLoader = lazy(() => import('../containers/pageLoader'))

const Router = () => {
  const profile = useSelector((state) => state.login?.auth?.profile);
  const conditionCrt = profile?.creatorId;
  console.log("conditionCrt :>> ", conditionCrt);
  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          {/* <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/reset-password"
          render={(props) => <ResetPassword {...props} />}
        />
        <Route exact path="/404" render={(props) => <Page404 {...props} />} />
        <Route exact path="/500" render={(props) => <Page500 {...props} />} /> */}
          {/* <Route exact path="/stripe-loader" render={(props) => <PageLoader {...props} />} /> */}
          <Route path="/" element={<Index />} />
          <Route
            path={
              conditionCrt
                ? `/${profile?.pageUrl ? profile?.pageUrl : "model"}`
                : "/model"
            }
            element={<Model />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Redirect />} />
          {/* <Route  path="/" element={} render={(props) => <Layout {...props} />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
