import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const Redirect = ({ authToken }) => {
  return !authToken && <Navigate to="/login" />;
};

const mapStateToProps = (state) => ({
  authToken: state.login.auth && state.login.auth.auth.accessToken,
});

export default connect(mapStateToProps, null)(Redirect);
