import React from "react";
import { dynamicStyle } from "../utils/helpers";

const ProgressBar = (props) => {
  const { completed } = props;
  const conditionCrt = props.profile?.creatorId;

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: conditionCrt
      ? dynamicStyle(props.profile?.primaryButtonColor, null)?.backgroundColor
      : "#7c6262",
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div className="progress-bar-container">
      <div style={fillerStyles}></div>
      {`${completed}%`}
    </div>
  );
};

export default ProgressBar;
