import { FETCHING_PRICE, SET_PRICE, RESET_PRICE } from "../../actionTypes";

const intialState = {
  price: {
    assemblyOptionPrice: null,
    castPrice: null,
    fixedCostPrice: null,
    platingPrice: null,
    polishLaborPrice: null,
    threeDPrintingPrice: null,
    laserEngravingOptionPrice: null,
    totalPrice: 0,
  },
  isFetching: false,
};

export default (state, action) => {
  if (!state) {
    return intialState;
  }
  switch (action.type) {
    case FETCHING_PRICE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SET_PRICE: {
      return {
        ...state,
        price: action.price,
        isFetching: false,
      };
    }
    case RESET_PRICE: {
      return {
        price: {
          assemblyOptionPrice: null,
          castPrice: null,
          fixedCostPrice: null,
          platingPrice: null,
          polishLaborPrice: null,
          threeDPrintingPrice: null,
          laserEngravingOptionPrice: null,
          totalPrice: 0,
        },
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
