import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import steps from "./steps/reducer";
import create from "./create";
import cart from "./shoppingCart/reducer";
import checkout from "./checkout/reducer";
import login from "./auth/reducer";
import itemConfigs from "./itemConfigs/reducer";

const reducer = combineReducers({
  steps,
  create,
  itemConfigs,
  cart,
  checkout,
  login,
});

const middlewares = [thunk];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart", "login", "checkout", "itemConfigs"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store);
  return { store, persistor };
};
