import React, { useState, useEffect } from "react";

const CustomCarousel = ({ children, show }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children?.length);

  useEffect(() => {
    setLength(children?.length);
  }, [children]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  return (
    <div className="carousel-container">
      <div className="button-wrapper">
        <button onClick={prev} className="left-arrow">
          &lt;
        </button>
      </div>
      <div className="carousel-wrapper">
        <div className="carousel-content-wrapper">
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show + 1.5)}%)`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <button onClick={next} className="right-arrow">
          &gt;
        </button>
      </div>
    </div>
  );
};
export default CustomCarousel;
