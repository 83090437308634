import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

// import Auth0 from "../../utils/auth0";
const Signup = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return null;
  }
  return (
    <li className="nav-item login">
      <Button
        // variant='link'
        className="nav-link font-weight-bold rebrand-signup-button"
        // onClick={async () => Auth0()}
      >
        Sign Up
      </Button>
    </li>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login && state.login.isAuthenticated,
});

export default connect(mapStateToProps)(Signup);
