import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";
// import moment from "moment";
import { connect } from "react-redux";

import { retrieveUser } from "../../store/auth/thunks";
// import { ShowLock } from "../../utils/auth0";
import { logout } from "../../store/auth/action";

const CheckJwt = (props) => {
  if (props.token) {
    const jwt = jwtDecode(props.token);
    // const now = moment();
    // const before = moment.unix(jwt.exp);
    if (jwt.exp * 1000 < Date.now()) {
      // if (now.isAfter(before)) {
      props.logout();
      //   ShowLock();
    }
  }

  useEffect(() => {
    if (window === void 0) {
      return;
    }
    // if (props.userID) {
    //   props.getUser(props.userID).catch((e) => {
    //     if (!props.onCreate && e.status === 404) {
    //       navigate(`/create`);
    //     }
    //   });
    // }
  }, []);

  return <>{props.children}</>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  token: state.login.auth && state.login.auth.auth.accessToken,
  userID: state.login.auth && state.login.auth.profile.sub,
  lastChecked: state.login.lastChecked,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getUser: (userID) => dispatch(retrieveUser(userID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckJwt);
