import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Form,
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";

import creditIcon from "../../assets/images/creditkey-logo.png";
import { setCreditOrder, setPaymentMethod } from "../../store/checkout/action";
import { connect } from "react-redux";
import { ConvertProfileToIntial } from "./ShippingInfo";
import { dynamicStyle } from "../../utils/helpers";

// import DiscountCode from "../DiscountCode";

const CREDIT_KEY = process.env.REACT_APP_CREDIT_KEY;
const CREDIT_TOKEN = process.env.REACT_APP_CREDIT_SECRET;
const CREDIT_KEY_URL = process.env.REACT_APP_CREDIT_KEY_API_URL;

const ShoppingCartStep = (props) => {
  const [canHitNext, setNextState] = useState(false);
  const [loading, setLoading] = useState(false);

  const conditionCrt = props.profile?.creatorId;

  const continueToNextStep = async (values) => {
    setNextState(false);
    await props.continue(values, props.title);
  };

  const submitPayment = async () => {
    setLoading(true);
    let notes;
    props.cart.items.map((item) => {
      notes = item.description ? item.decription + " " : "";
    });

    const completeCheckoutUrl = `${CREDIT_KEY_URL}/complete_checkout?public_key=${CREDIT_KEY}&shared_secret=${CREDIT_TOKEN}`;

    const completed = await fetch(completeCheckoutUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        id: props.creditOrder,
      }),
    });

    const dataCompleted = await completed.json();

    if (dataCompleted.success === true) {
      await props.doCheckout({
        customer: props.profile?.sub,
        tax: props.salesTax,
        notes: notes + `Credit-Key:${props.creditOrder}`,
        shippingMethod: {
          id: Number(props.shipping.shippingMethod),
          price: Number(props.shipping.shippingMethodPrice),
        },
        address: props.checkout.shipping.address,
        orderItems: props.cart.items,
        apiToken: props.apiToken,
      });
      props.setCreditOrder(null);
      setLoading(false);
      toast.success("Submitted your order, its now under review!", {
        className: "bg-primary text-white",
      });
    } else {
      props.setCreditOrder(null);
      setLoading(false);
      toast.error(
        "An error occurred during complete checkout/Your order was not successfully processed with credit key!"
      );
    }
  };

  const finalPrice = props.cart.items.reduce(
    (acc, item) =>
      acc +
      Number(item.price.totalPrice * item.quantity) +
      Number(props.salesTax),
    0
  );
  const shipPrice = props.shippingPrice ? Number(props.shippingPrice) : 0;

  const finalTotalPrice = finalPrice + Number(shipPrice);

  const restrictionCoupon = props.matchedCode
    ? props.matchedCode.restrictions
      ? props.matchedCode.restrictions.minimum_amount
        ? true
        : false
      : false
    : false;

  const applyDis = restrictionCoupon
    ? props.matchedCode.restrictions.minimum_amount <= Number(finalTotalPrice)
    : false;

  const discountPrice = props.matchedCode
    ? props.matchedCode.coupon.percent_off
      ? (Number(props.matchedCode.coupon.percent_off) / 100) * finalTotalPrice
      : props.matchedCode.coupon.amount_off / 100
    : null;

  const finalDiscountedPrice = restrictionCoupon
    ? applyDis
      ? finalTotalPrice - Number(discountPrice)
      : finalTotalPrice
    : discountPrice
    ? finalTotalPrice - Number(discountPrice)
    : finalTotalPrice;

  const cardInfo =
    props.title === "Checkout"
      ? props?.profile && props.profile?.cardInfo
      : true;

  const checkoutButtons = (fprops) => {
    return (
      <Row>
        {props.onBack && (
          <Col>
            <Button
              className="checkout"
              onClick={props.onBack}
              variant="outline-primary"
              style={
                conditionCrt
                  ? dynamicStyle(
                      props.profile?.primaryButtonColor,
                      props.profile?.buttonTextColor
                    )
                  : {}
              }
            >
              Back
            </Button>
          </Col>
        )}
        <Col>
          <Button
            className="checkout"
            type="submit"
            disabled={
              props.title === "Shipping Information"
                ? !props.shippingPrice ||
                  fprops.isInvalid ||
                  fprops.isSubmitting ||
                  props.isLoading ||
                  !cardInfo
                : fprops.isInvalid ||
                  fprops.isSubmitting ||
                  props.isLoading ||
                  !cardInfo
            }
            style={
              conditionCrt
                ? dynamicStyle(
                    props.profile?.primaryButtonColor,
                    props.profile?.buttonTextColor
                  )
                : props.payment === "Credit-Key"
                ? { marginBottom: "0" }
                : {}
            }
          >
            {(fprops.isSubmitting || props.isLoading) && (
              <i className="fas fa-circle-notch fa-spin mr-3" />
            )}
            {props.next_title || "Checkout"}
          </Button>
          {props.payment === "Credit-Key" && (
            <span
              style={{
                fontSize: "12.8px",
                marginTop: "0",
                marginLeft: "22px",
                color: "#808080",
                fontStyle: "italic",
              }}
            >
              <span>
                Begin{" "}
                {/* <img src={creditIcon} className='mb-1' width={15} height={15} />{' '} */}
                Checkout
              </span>
            </span>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Formik
      validationSchema={props.schema}
      validate={props.validate}
      initialValues={ConvertProfileToIntial(props.profile)}
      onSubmit={async (values, actions) => {
        const func = null || continueToNextStep(values);
        await func(values);
        actions.setSubmitting(false);
      }}
      render={(fprops) => {
        const children = React.Children.map(props.children, (child) => {
          return React.cloneElement(child, {
            ...props,
            ...fprops,
            setIsValid: (valid) => setNextState(valid),
          });
        });

        return (
          <div>
            <Form onSubmit={fprops.handleSubmit} validated={fprops.isValid}>
              <div className="cart-list">
                <div>
                  <Row className={"title"}>
                    <Col>
                      <h3
                        style={
                          conditionCrt
                            ? { color: props?.profile?.primaryButtonColor }
                            : {}
                        }
                      >
                        {props.title}
                      </h3>
                      <p> {props.msg} </p>
                    </Col>
                  </Row>
                  {props.title === "Checkout" && (
                    <Row>
                      <Form.Label>Choose one payment option</Form.Label>
                      <Form.Group>
                        <ToggleButtonGroup
                          type="radio"
                          className="btn-group-separate"
                          name="polish"
                          value={props.payment || "Default"}
                          onChange={(e) => {
                            props.setPaymentOption(e);
                            props.setPaymentMethod(e);
                          }}
                        >
                          {props.paymentOptions.map((payment, i) => (
                            <ToggleButton
                              type="radio"
                              variant="outline-dark"
                              key={i}
                              value={payment}
                            >
                              {payment === "Credit-Key" ? (
                                <div>
                                  <img
                                    className="mr-1 mb-1"
                                    src={creditIcon}
                                    width="15px"
                                    height={"15px"}
                                  />
                                  {payment}
                                </div>
                              ) : (
                                payment
                              )}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </Form.Group>
                    </Row>
                  )}
                  <Row>{children}</Row>
                  <Row>
                    <Col>
                      <p>Sales Tax</p>
                    </Col>
                    <Col>
                      <p className="price">${props.salesTax || 0}</p>
                    </Col>
                  </Row>
                  {props.shippingPrice ? (
                    <Row>
                      <Col>
                        <p>Shipping Cost</p>
                      </Col>
                      <Col>
                        <p className="price">${props.shippingPrice || 0}</p>
                      </Col>
                    </Row>
                  ) : null}
                  {/* {props.title === "Checkout" && (
                    <DiscountCode
                      promoCode={props.promoCode}
                      matchedCode={props.matchedCode}
                      setPromoCode={props.setPromoCode}
                      restrictionCoupon={restrictionCoupon}
                      applyDis={applyDis}
                    />
                  )} */}
                  <Row>
                    <Col>
                      <h4>Order Total</h4>
                    </Col>
                    <Col>
                      <p className="price">
                        $
                        {props.matchedCode
                          ? parseFloat(finalDiscountedPrice).toFixed(2)
                          : parseFloat(finalTotalPrice).toFixed(2)}
                      </p>
                    </Col>
                  </Row>
                  {props.creditOrder && props.payment === "Credit-Key" && (
                    <div>{checkoutButtons(fprops)}</div>
                  )}
                  {props.creditOrder && props.title === "Shopping Cart" && (
                    <Row>
                      <Col>
                        <Button
                          className="checkout"
                          onClick={() => submitPayment()}
                        >
                          {loading && (
                            <i className="fas fa-circle-notch fa-spin mr-3" />
                          )}
                          {!loading && (
                            <img
                              src={creditIcon}
                              width="20px"
                              height={"20px"}
                              className="mb-1 mr-2"
                            />
                          )}
                          Complete Checkout
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {props.creditOrder === null && (
                    <div>{checkoutButtons(fprops)}</div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  creditOrder: state.checkout.creditOrder ? state.checkout.creditOrder : null,
  token: state.login.auth && state.login.auth.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  setPaymentMethod: (data) => dispatch(setPaymentMethod(data)),
  setCreditOrder: (data) => dispatch(setCreditOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartStep);
