import { apiFetch } from "../../utils/api";
import {
  fetchItemConfigsRequest,
  fetchItemConfigsSuccess,
  fetchItemConfigsFailure,
  setMaterialOptions,
  setPlatingOptions,
  setPolishOptions,
  setAssemblyOptions,
  setLaserOptions,
} from "./action";

export const login = (email, password) => async (dispatch) => {
  // your action code
};
export const fetchItemConfigs = () => async (dispatch) => {
  dispatch(fetchItemConfigsRequest());

  try {
    const data = await apiFetch("/item-configs");
    dispatch(fetchItemConfigsSuccess(data));

    dispatch(
      setMaterialOptions(
        data.filter((itemConfig) => itemConfig.subItemId === 1)
      )
    );
    dispatch(
      setPlatingOptions(data.filter((itemConfig) => itemConfig.subItemId === 2))
    );
    dispatch(
      setPolishOptions(data.filter((itemConfig) => itemConfig.subItemId === 3))
    );
    dispatch(
      setAssemblyOptions(
        data.filter((itemConfig) => itemConfig.subItemId === 4)
      )
    );
    dispatch(
      setLaserOptions(data.filter((itemConfig) => itemConfig.subItemId === 5))
    );

    return data;
  } catch (error) {
    dispatch(fetchItemConfigsFailure());
  }
};
