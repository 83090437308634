import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Joyride from "react-joyride";

import Option from "./AssembyOptions";
import { removeAssemblyOption } from "../../store/create/jewelry/action";
import { setAssemblyOption } from "../../store/create/jewelry/thunks";
import { getPrice } from "../../store/create/jewelry/thunks";
import noImage from "../../assets/images/noimage.jpg";
import { showMinQty } from "./Material";

const steps = [
  {
    title: "Select an assembly option",
    target: "#my-row-tour",
    content:
      "You may choose to have your jewelry products come assembled with the components you select. (Example: When you select Ear Posts, your jewelry will come with ear posts assembled) .",
  },
  {
    title: "Continue",
    target: "#continue-tour",
    content: "Press continue to move on to the next step.",
  },
  {
    title: "Skip this step",
    target: "#skip-my-tour-earrings",
    content:
      "If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of Ear Post (including but not limited to, ear wires, hinges or joint and catch sets) in your 3D model(s).  You must select Ear Posts, if you’d like your finished product to have ear posts.",
  },
];

const steps2 = [
  {
    title: "Select an assembly option",
    target: "#my-row-tour",
    content:
      "You may choose to have your pendant come assembled with the chains/necklaces/bracelets you select.",
  },
  {
    title: "Continue",
    target: "#continue-tour",
    content: "Press continue to move on to the next step.",
  },
  {
    title: "Skip this step",
    target: "#skip-my-tour-earrings",
    content:
      "If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of chains in your 3D model(s).  You must select a chain type and length, if you’d like your finished product to come with assembled with a chain.",
  },
];

const AssemblyStep = ({
  itemType,
  assemblyOption,
  assemblyOptions,
  total,
  quantity,
  priceIsLoading,
  getPrice,
  setAssemblyOption,
  removeAssemblyOption,
  paymentDetail,
  membership,
  productionPage,
  estimatePricing,
  material,
  polish,
  plating,
  laserEngravingOption,
  productChosen,
  setAssemblyPrice,
  totalProductPrice,
}) => {
  let title = "Select A Chain Type";

  let Disclaimer = ({ msg }) => <p className="disclamer">{msg}</p>;

  if (itemType.name.toLowerCase() === "earring") {
    title = "Select An Assembly Option";
    Disclaimer = () => null;
  }

  return (
    <>
      <Joyride
        steps={
          itemType.name.toLowerCase() === "earring"
            ? steps
            : itemType.name.toLowerCase() === "necklace" ||
              itemType.name.toLowerCase() === "bracelet" ||
              productChosen
            ? steps2
            : null
        }
        continuous={true}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#c2c2c2",
            overlayColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
        showProgress={true}
        showSkipButton={true}
      />
      <Row id="my-row-tour" className="d-flex flex-column flex-nowrap">
        <p>{title}</p>
        {assemblyOptions &&
          assemblyOptions
            .filter((assembly) => assembly.item.id === itemType.id)
            .map(({ variant }) => {
              const assemblyQty = variant.min_qty
                ? JSON.parse(variant.min_qty)
                : null;

              const mess = showMinQty(assemblyQty, variant, membership, true);
              return (
                <Option
                  key={variant.id}
                  title={variant.name}
                  picture={variant.image ? variant.image : noImage}
                  description={variant.description}
                  isSelected={variant == assemblyOption}
                  min_qty={variant.min_qty}
                  mess={mess}
                  quantity={quantity}
                  onSelect={() => {
                    if (variant == assemblyOption) {
                      removeAssemblyOption();
                      getPrice();
                      return;
                    }
                    setAssemblyOption(variant);
                    getPrice();
                    if (productionPage) {
                      estimatePricing(
                        itemType,
                        material,
                        plating,
                        polish,
                        assemblyOption,
                        laserEngravingOption,
                        quantity
                      );
                    } else if (productChosen) {
                      setAssemblyPrice(variant ? variant.base_price : 0);
                    }
                  }}
                />
              );
            })}
      </Row>
      <Disclaimer msg="* clasps are included with the necklace assembly" />
      {!productionPage && (
        <>
          <Row>
            <Col>
              <p>Price</p>
            </Col>
            <Col>
              <p style={{ textAlign: "right" }}>
                USD
                {priceIsLoading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  <>
                    $
                    {productChosen
                      ? totalProductPrice.toFixed(2)
                      : total.totalPrice.toFixed(2)}
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <div>{paymentDetail}</div>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  total: state.create && state.create.price.price,
  jewelry: state.create && state.create.jewelry,
  itemType: state.create && state.create.jewelry.itemType,
  material: state.create && state.create.jewelry.material,
  plating: state.create && state.create.jewelry.plating,
  polish: state.create && state.create.jewelry.polish,
  laserEngravingOption:
    state.create && state.create.jewelry.laserEngravingOption,
  assemblyOption: state.create && state.create.jewelry.assemblyOption,
  // assemblyOptions: state.itemConfigs.assemblyOptions,
  assemblyOptions:
    state.itemConfigs &&
    state.itemConfigs.configs &&
    state.itemConfigs.configs.length !== 0 &&
    state.itemConfigs.configs.filter(
      (itemConfig) => itemConfig.subItemId === 4
    ),
  priceIsLoading:
    (state.create && state.create.price.isFetching) ||
    (state.create && state.create.jewelry.isLoading.modelIsLoading),
  membership:
    state.login &&
    state.login.auth?.profile?.membership &&
    state.login.auth?.profile?.membership?.nickname
      ? state.login?.auth?.profile?.membership?.nickname
      : state.login?.auth?.profile?.membership?.name,
  quantity: state.create && state.create.jewelry.quantity,
});

const mapDispatchToProps = (dispatch) => ({
  getPrice: () => dispatch(getPrice()),
  setAssemblyOption: (option) => dispatch(setAssemblyOption(option)),
  removeAssemblyOption: () => dispatch(removeAssemblyOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyStep);
