import React, { useEffect, useState } from "react";
import { Row, Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";

import Modal from "./Modal";
import Step from "./steps/BasicStep";
import Material from "./steps/Material";
import Assembly from "./steps/Assembly";
import FileUpload from "./steps/Upload";
import OrderReview from "./steps/Review";
import { goToStep, setSteps } from "../store/steps/action";
import {
  clearSelections,
  isProductChosen,
  openModalLibrary,
  setNewOrder,
  setSelected3dModel,
  setSelectedMarket3dModel,
  setSelectedProduct,
} from "../store/create/jewelry/action";
import { addToCart } from "../store/shoppingCart/thunks";
import { getPrice } from "../store/create/jewelry/thunks";
import { resetCreate, isModelChosen } from "../store/create/jewelry/action";
import { shouldShowCart } from "../store/checkout/action";
import { resetPrice } from "../store/create/price/action";
import ck from "creditkey-js";
import LaserEngraving from "./steps/LaserEngraving";
import { setRedirectedFromStripe } from "../store/auth/action";
import { getConnectAccount } from "../utils/api";
import { dynamicStyle } from "../utils/helpers";

const verifyUpload = (itemType, file) => !!itemType && !!file;

const CREDIT_KEY = process.env.REACT_APP_CREDIT_KEY;

export const getWindowLocation = () => {
  const windowGlobal = typeof window !== "undefined";
  if (windowGlobal) {
    const location = window.location.href;
    return location;
  }
};

const StepList = ({
  assemblyOptions,
  laserEngravingOptions,
  itemType,
  material,
  plating,
  polish,
  assemblyOption,
  laserEngravingOption,
  volume,
  surfaceArea,
  file,
  price,
  file_name,
  quantity,
  description,
  screenshot,
  currentStep,
  shouldDisplayNext,
  displayBack,
  displaySubmit,
  goToStep,
  setSteps,
  clearSelections,
  getPrice,
  takeScreenShot,
  addToCartAPI,
  resetCreate,
  resetPrice,
  showShoppingCart,
  addToCartLoading,
  shipping,
  salesTax,
  modelChosen,
  isModelChosen,
  selected3dModel,
  setSelectedModel,
  openModalLibrary,
  selectedMarketplace3dModel,
  setSelectedMarket3dModel,
  setNewOrder,
  modelHeight,
  modelWidth,
  modelLength,
  weight,
  setQty,
  setSellingPrice,
  sellingPrice,
  qty,
  estimatedPrice,
  setWidth,
  setLength,
  setHeight,
  productionPage,
  priceLoading,
  estimatePricing,
  productChosen,
  selectedProduct,
  setPolishPrice,
  setPlatingPrice,
  setMaterialPrice,
  setAssemblyPrice,
  setLaserEngravingPrice,
  setProductPricing,
  totalProductPrice,
  isProductChosen,
  laserCustomText,
  laserFont,
  laserImage,
  setSelectedProduct,
  laserEngravingPrice,
  materialPrice,
  platingPrice,
  polishPrice,
  assemblyPrice,
  itemConfigs,
  redirectedStripe,
  setRedirectedStripe,
  profile,
}) => {
  const [modal, setModal] = useState(false);
  const [display, setDisplay] = useState(null);

  const location = getWindowLocation();
  const [isDesignLibraryModalOpen, openDesignLibraryModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [stripeComplete, setStripeComplete] = useState(false);
  const [stripeAcc, setStripeAcc] = useState(null);

  const conditionCrt = profile?.creatorId;

  useEffect(() => {
    setSteps(3);
  }, []);

  useEffect(() => {
    if (!productionPage) {
      goToStep(0);
    }
  }, [productionPage]);

  const client = new ck.Client(CREDIT_KEY, "production");
  const total = Number(price?.totalPrice * quantity);

  const shippingPrice = shipping.shippingMethodPrice
    ? Number(shipping.shippingMethodPrice)
    : Number(15);

  const grandTotal = total + Number(salesTax) + shippingPrice;

  const charges = new ck.Charges(
    total,
    shipping.shippingMethodPrice
      ? Number(shipping.shippingMethodPrice)
      : Number(15),
    Number(salesTax),
    0,
    grandTotal
  );

  const onClick = async () => {
    await client.enhanced_pdp_modal(charges);
  };

  const getPdpDisplay = async () => {
    const res = await client.get_pdp_display(charges);
    setDisplay(res);
  };

  useEffect(() => {
    getPdpDisplay();
  }, [charges, total]);

  const paymentDetail = (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-bottom">Click to pre-qualify now!</Tooltip>}
    >
      <div
        className="is-size-6 checkout"
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: display }}
      />
    </OverlayTrigger>
  );

  const addToCart = async () => {
    const sc = productChosen ? null : await takeScreenShot();

    await addToCartAPI({
      itemType,
      material,
      plating,
      polish,
      assemblyOption,
      volume,
      surfaceArea,
      screenshot: sc,
      price: productChosen
        ? {
            ...price,
            laserEngravingOptionPrice: laserEngravingOption
              ? laserEngravingPrice || 0
              : 0,
            totalPrice: totalProductPrice,
          }
        : price,
      file_name,
      quantity,
      description,
      modelChosen,
      selected3dModel,
      selectedMarketplace3dModel,
      modelHeight,
      modelWidth,
      modelLength,
      productChosen,
      selectedProduct,
      laserEngravingOption,
      laserCustomText,
      laserFont,
      laserImage,
    });
    setNewOrder(false);

    setModal(true);
  };

  const [showHowMuch, setShow] = useState(false);

  const estimatedSellingPrice =
    estimatedPrice &&
    Number(estimatedPrice.totalPrice) + Number(estimatedPrice.totalPrice) * 1.5;

  const homMuchToMake = async () => {
    setShow(true);
    setSellingPrice(estimatedSellingPrice.toFixed(2));
    setQty(720);
  };

  const getStripeConnectAccount = async (id) => {
    const res = await getConnectAccount(id);
    if (res.status === 200) {
      const data = await res.json();
      setStripeAcc(data);
      if (
        (data.charges_enabled &&
          data.capabilities.transfers === "active" &&
          data.payouts_enabled) ||
        data.details_submitted
      ) {
        setStripeComplete(true);
      }
    }
  };

  useEffect(() => {
    if (
      (window && window.location.href.includes("code=")) ||
      redirectedStripe
    ) {
      getStripeConnectAccount(profile && profile.stripeConnectId);
      setRedirectedStripe(false);
      setTimeout(() => {
        setOpenStoreModal(true);
        window.history.replaceState({}, "Model", "/model");
      }, 1000);
    }
  }, [window.location]);

  const listWithoutAssembly = [
    <FileUpload
      title={productionPage ? "Production Calculator" : "Starting Your Order"}
      verify={() => verifyUpload(itemType, file)}
      isDesignLibraryModalOpen={isDesignLibraryModalOpen}
      openDesignLibraryModal={openDesignLibraryModal}
      progressPercentage={setProgress}
      location={location}
      setHeight={setHeight}
      setLength={setLength}
      setWidth={setWidth}
      productionPage={productionPage}
      estimatePricing={estimatePricing}
      setProductPricing={setProductPricing}
      setMaterialPrice={setMaterialPrice}
      setPolishPrice={setPolishPrice}
      setPlatingPrice={setPlatingPrice}
      setAssemblyPrice={setAssemblyPrice}
      setLaserEngravingPrice={setLaserEngravingPrice}
      materialPrice={materialPrice}
      platingPrice={platingPrice}
      polishPrice={polishPrice}
      assemblyPrice={assemblyPrice}
      itemConfigs={itemConfigs}
    />,
    <Material
      title="Material and Polish"
      paymentDetail={paymentDetail}
      onClick={onClick}
      productionPage={productionPage}
      estimatePricing={estimatePricing}
      productChosen={productChosen}
      setMaterialPrice={setMaterialPrice}
      setPlatingPrice={setPlatingPrice}
      setPolishPrice={setPolishPrice}
    />,
    <OrderReview
      title={productionPage ? "Production Calculator" : "Order Review"}
      onSubmit={addToCart}
      paymentDetail={paymentDetail}
      productionPage={productionPage}
      setSellingPrice={setSellingPrice}
      sellingPrice={sellingPrice}
      setQty={setQty}
      qty={qty}
      itemType={itemType}
      material={material}
      plating={plating}
      polish={polish}
      assemblyOption={assemblyOption}
      laserEngravingOption={laserEngravingOption}
      estimatePricing={estimatePricing}
      showHowMuch={showHowMuch}
      productChosen={productChosen}
      selectedProduct={selectedProduct}
      totalProductPrice={totalProductPrice}
    />,
  ];

  const listWithAssembly = [
    <FileUpload
      title={productionPage ? "Production Calculator" : "Starting Your Order"}
      verify={() => verifyUpload(itemType, file)}
      isDesignLibraryModalOpen={isDesignLibraryModalOpen}
      openDesignLibraryModal={openDesignLibraryModal}
      progressPercentage={setProgress}
      location={location}
      productionPage={productionPage}
      estimatePricing={estimatePricing}
      setProductPricing={setProductPricing}
      setMaterialPrice={setMaterialPrice}
      setPolishPrice={setPolishPrice}
      setPlatingPrice={setPlatingPrice}
      setAssemblyPrice={setAssemblyPrice}
      setLaserEngravingPrice={setLaserEngravingPrice}
      materialPrice={materialPrice}
      platingPrice={platingPrice}
      polishPrice={polishPrice}
      assemblyPrice={assemblyPrice}
      itemConfigs={itemConfigs}
    />,
    <Material
      title="Material and Polish"
      paymentDetail={paymentDetail}
      estimatePricing={estimatePricing}
      productionPage={productionPage}
      productChosen={productChosen}
      setMaterialPrice={setMaterialPrice}
      setPlatingPrice={setPlatingPrice}
      setPolishPrice={setPolishPrice}
    />,
    <Assembly
      title="Assembly"
      isSkippable={true}
      onSkip={() => {
        clearSelections();
        getPrice();
      }}
      clearSelection={clearSelections}
      paymentDetail={paymentDetail}
      estimatePricing={estimatePricing}
      productionPage={productionPage}
      productChosen={productChosen}
      setAssemblyPrice={setAssemblyPrice}
      totalProductPrice={totalProductPrice}
    />,
    <LaserEngraving
      title="Laser Engraving"
      isSkippable={true}
      onSkip={() => {
        clearSelections();
        getPrice();
      }}
      clearSelection={clearSelections}
      paymentDetail={paymentDetail}
      estimatePricing={estimatePricing}
      productionPage={productionPage}
      productChosen={productChosen}
      setLaserEngravingPrice={setLaserEngravingPrice}
      totalProductPrice={totalProductPrice}
    />,
    <OrderReview
      title={productionPage ? "Production Calculator" : "Order Review"}
      onSubmit={addToCart}
      paymentDetail={paymentDetail}
      productionPage={productionPage}
      setSellingPrice={setSellingPrice}
      sellingPrice={sellingPrice}
      setQty={setQty}
      qty={qty}
      itemType={itemType}
      material={material}
      plating={plating}
      polish={polish}
      assemblyOption={assemblyOption}
      laserEngravingOption={laserEngravingOption}
      estimatePricing={estimatePricing}
      showHowMuch={showHowMuch}
      productChosen={productChosen}
      selectedProduct={selectedProduct}
      totalProductPrice={totalProductPrice}
    />,
  ];

  let steps = listWithoutAssembly;

  useEffect(() => {
    if (!!itemType) {
      const assemblyOptionsLength =
        assemblyOptions &&
        assemblyOptions.filter((assembly) => assembly.item.id === itemType.id)
          .length;

      const lasersLength =
        laserEngravingOptions &&
        laserEngravingOptions.filter((lsr) => lsr.item.id === itemType.id)
          .length;
      if (assemblyOptionsLength >= 1 && lasersLength >= 1) {
        setSteps(listWithAssembly.length);
      } else if (assemblyOptionsLength >= 1 && lasersLength < 1) {
        setSteps(listWithAssembly.length - 1);
      } else if (lasersLength >= 1 && assemblyOptionsLength < 1) {
        setSteps(listWithAssembly.length - 1);
      } else {
        setSteps(listWithoutAssembly.length);
      }
    }
  }, [itemType]);

  if (itemType) {
    const assemblyOptionsLength =
      assemblyOptions &&
      assemblyOptions.filter((assembly) => assembly.item.id === itemType.id)
        .length;
    const lasersLength =
      laserEngravingOptions &&
      laserEngravingOptions.filter((lsr) => lsr.item.id === itemType.id).length;
    if (assemblyOptionsLength >= 1 && lasersLength >= 1) {
      steps = listWithAssembly;
    } else if (assemblyOptionsLength >= 1 && lasersLength < 1) {
      steps = listWithAssembly.filter(
        (ls) => !ls.props.title.includes("Laser Engraving")
      );
    } else if (lasersLength >= 1 && assemblyOptionsLength < 1) {
      steps = listWithAssembly.filter(
        (ls) => !ls.props.title.includes("Assembly")
      );
    }
    if (assemblyOptionsLength < 1 && lasersLength < 1) {
      steps = listWithoutAssembly;
    }
  }

  const children = React.Children.map(steps, (child, index) => {
    const props = {
      title: child.props.title,
      stepNumber: index + 1,
      isActive: index === currentStep,
      displayPrevious: displayBack,
      displayNext: shouldDisplayNext,
      displaySubmit,
      takeScreenShot,
      screenshot,
      productionPage,
      weight,
      estimatePricing,
      priceLoading,
      estimatedPrice,
      modelHeight,
      modelWidth,
      modelLength,
      showHowMuch,
      homMuchToMake,
      productChosen,
      selectedProduct,
      isProductChosen,
      setSelectedProduct,
      totalProductPrice,
      setOpenStoreModal,
      openStoreModal,
      currentStep,
      setRedirectedStripe,
      stripeComplete,
      stripeAcc,
      getStripeConnectAccount,
      setSelectedMarket3dModel,
      ...child.props,
    };

    return (
      <Step key={index} {...props}>
        {child}
      </Step>
    );
  });

  const buttons = React.Children.map(steps, (_child, index) => {
    let props = {
      onClick: () => {
        if (!productChosen) {
          if (verifyUpload(itemType, file)) {
            goToStep(index);
          }
        }
      },
    };

    if (index !== currentStep) {
      props.variant = "outline";
    }

    return (
      <div>
        <Button
          key={index}
          {...props}
          style={
            index === currentStep
              ? conditionCrt
                ? dynamicStyle(
                    profile?.primaryButtonColor,
                    profile?.buttonTextColor
                  )
                : { backgroundColor: "#866d6d", borderColor: "#866d6d" }
              : {}
          }
        >
          {index + 1}
        </Button>
      </div>
    );
  });

  const resetPage = () => {
    resetCreate();
    resetPrice();
    setModal(false);
    isModelChosen(false);
    setNewOrder(true);
    if (selected3dModel) {
      setSelectedModel(null);
    }
    if (selectedMarketplace3dModel) {
      setSelectedMarket3dModel(null);
    }
    if (productChosen) {
      isProductChosen(null);
      setSelectedProduct(null);
    }
  };
  const showCart = () => {
    showShoppingCart();
    setNewOrder(true);
  };

  return (
    <>
      <Col
        className="step holder px-0"
        style={
          profile?.addDescription && profile?.addUpload && currentStep === 0
            ? { width: "20%" }
            : {}
        }
      >
        <Row>
          <Col className="cards px-0">{children}</Col>
          <Col className="steps-number">{buttons}</Col>
        </Row>
      </Col>
      <Modal
        style={{ zIndex: 100000 }}
        title="Item added to cart!"
        handleClose={resetPage}
        show={addToCartLoading === false && modal}
        headerStyle={
          conditionCrt
            ? dynamicStyle(
                profile?.primaryButtonColor,
                profile?.buttonTextColor
              )
            : { backgroundColor: "#7c6261", color: "white" }
        }
        primaryButtons={[
          {
            title: "Add another Item",
            handle: resetPage,
            className: !conditionCrt && "addItem-btn",
            style: conditionCrt
              ? {
                  ...dynamicStyle(
                    profile?.primaryButtonColor,
                    profile?.buttonTextColor
                  ),
                }
              : {},
          },
          {
            title: "Check Out",
            handle: () => {
              resetPage();
              showCart();
            },
            className: !conditionCrt && "checkOut-btn",
            style: conditionCrt
              ? {
                  ...dynamicStyle(
                    profile?.primaryButtonColor,
                    profile?.buttonTextColor
                  ),
                  marginLeft: "5px",
                }
              : {},
          },
        ]}
        library={true}
        libraryHandle={() => {
          resetPage();
          setTimeout(() => {
            openDesignLibraryModal(true);
            openModalLibrary(true);
          }, 1200);
        }}
      >
        <p>
          Your {itemType && itemType.name} cast in {material && material.name}{" "}
          has been added to your cart!
        </p>
        <p>Would you like to checkout out or add another item to your cart?</p>
        <p>
          <img
            src={
              productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : screenshot
            }
            alt="Screenshot of your jewelry piece"
            width={200}
          />
        </p>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  create: state.create,
  itemType: state.create?.jewelry.itemType,
  material: state.create?.jewelry.material,
  plating: state.create?.jewelry.plating,
  polish: state.create?.jewelry.polish,
  assemblyOption: state.create?.jewelry?.assemblyOption,
  // assemblyOptions: state.itemConfigs.assemblyOptions,
  assemblyOptions:
    state.itemConfigs.configs &&
    state.itemConfigs.configs.length !== 0 &&
    state.itemConfigs.configs.filter(
      (itemConfig) => itemConfig.subItemId === 4
    ),
  laserEngravingOption: state.create.jewelry.laserEngravingOption,
  // laserEngravingOptions: state.itemConfigs.laserEngravings,
  laserEngravingOptions:
    state.itemConfigs.configs &&
    state.itemConfigs.configs.length !== 0 &&
    state.itemConfigs.configs.filter(
      (itemConfig) => itemConfig.subItemId === 5
    ),
  price: state.create.price.price,
  volume: state.create.jewelry.volume,
  surfaceArea: state.create.jewelry.surfaceArea,
  weight: state.create.jewelry.weight,
  quantity: state.create.jewelry.quantity,
  description: state.create.jewelry.description,
  screenshot: state.create.jewelry.screenshot,
  meshLocation: state.create.jewelry.meshLocation,
  file: state.create.jewelry.file,
  file_name: state.create.jewelry.file_name,
  cart: state.cart,
  currentStep: state.steps.currentStep,
  totalSteps: state.steps.totalSteps,
  shouldDisplayNext: state.steps.currentStep < state.steps.totalSteps - 1,
  displayBack: state.steps.currentStep > 0,
  displaySubmit: state.steps.currentStep === state.steps.totalSteps - 1,
  addToCartLoading: state.cart.addToCartLoading,
  shipping: state.checkout.shipping,
  salesTax: state.cart.salesTax || 0,
  modelChosen: state.create.jewelry && state.create.jewelry.modelChosen,
  selected3dModel: state.create.jewelry && state.create.jewelry.selected3dModel,
  selectedMarketplace3dModel:
    state.create.jewelry && state.create.jewelry.selectedMarketplace3dModel,
  modelHeight: state.create.jewelry.model_height,
  modelWidth: state.create.jewelry.model_width,
  modelLength: state.create.jewelry.model_length,
  authToken: state.login.auth && state.login.auth.auth.accessToken,
  erpUser: state.login.auth && state.login.auth.profile.erp_id,
  profile: state.login.auth && state.login.auth.profile,
  laserCustomText: state.create.jewelry.laserCustomText,
  laserFont: state.create.jewelry.laserFont,
  laserImage: state.create.jewelry.laserImage,
  redirectedStripe: state.login && state.login.redirectedStripe,
});

const mapDispatch = (dispatch) => ({
  goToStep: (stepNumber) => dispatch(goToStep(stepNumber)),
  setSteps: (total) => dispatch(setSteps(total)),
  clearSelections: () => dispatch(clearSelections()),
  addToCartAPI: (cart, data) => {
    dispatch(addToCart(cart, data));
  },
  getPrice: () => dispatch(getPrice()),
  showShoppingCart: () => dispatch(shouldShowCart()),
  resetCreate: () => dispatch(resetCreate()),
  resetPrice: () => dispatch(resetPrice()),
  isModelChosen: (data) => dispatch(isModelChosen(data)),
  openModalLibrary: (data) => dispatch(openModalLibrary(data)),
  setSelectedModel: (data) => dispatch(setSelected3dModel(data)),
  setSelectedMarket3dModel: (data) => dispatch(setSelectedMarket3dModel(data)),
  setNewOrder: (data) => dispatch(setNewOrder(data)),
  isProductChosen: (data) => dispatch(isProductChosen(data)),
  setSelectedProduct: (data) => dispatch(setSelectedProduct(data)),
  setRedirectedStripe: (data) => dispatch(setRedirectedFromStripe(data)),
});

export default connect(mapStateToProps, mapDispatch)(StepList);
