import React from "react";
import { connect } from "react-redux";
import { Dropdown, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DropdownItem from "react-bootstrap/DropdownItem";
// import { GetAuth0 } from "../../utils/auth0";
import { logout } from "../../store/auth/action";
import warningIcon from "../../assets/images/red-warning.png";

const MARKET_URL = process.env.REACT_APP_MARKETPLACE_URL;

const DropdownLink = ({ to, children }, ...props) => {
  const navigate = useNavigate();
  return (
    <Dropdown.Item {...props} onClick={() => navigate(to)}>
      {children}
    </Dropdown.Item>
  );
};

const ProfileHeader = ({
  isAuthenticated,
  profile,
  profilePicture,
  logout,
}) => {
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = async () => {
    //   const auth = await GetAuth0();
    //   auth.logout({
    //     returnTo: `${window.location.protocol}//${window.location.host}`,
    //   });

    setTimeout(() => {
      logout();
      navigate("/login");
    }, 400);
  };

  const conditionCrt = profile?.creatorId;

  return (
    <li className="nav-item profile">
      <Dropdown>
        <Dropdown.Toggle variant="link" className="nav-link">
          {!profilePicture ? (
            <div className="d-flex flex-row">
              <div className="avatar-wrapper">
                <div className="avatar-initials">
                  {`${profile?.first_name
                    ?.charAt(0)
                    .toUpperCase()}${profile?.last_name
                    ?.charAt(0)
                    .toUpperCase()}`}
                </div>
              </div>
              <span className="user-name font-weight-bold mt-2">
                {profile?.subUser ? profile.subUserName : profile.nickname}
              </span>
            </div>
          ) : (
            <p>
              <Image
                src={profilePicture || ""}
                roundedCircle
                style={{ width: 30, height: 30 }}
              />
              <span className="user-name font-weight-bold">
                {profile?.subUser ? profile.subUserName : profile.nickname}
              </span>
            </p>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DropdownLink
            to={
              conditionCrt
                ? profile?.pageUrl
                  ? `${profile?.pageUrl}`
                  : "/model"
                : "/model"
            }
          >
            Start A New Order
          </DropdownLink>
          {/* <DropdownItem>
            <div
              style={{
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (window) {
                  window.open(`${MARKET_URL}/projects-dashboard`, "_blank");
                }
              }}
            >
              Design Projects
            </div>
          </DropdownItem> */}
          {/* <DropdownLink to="/dashboard">
            <div
              style={{
                justifyContent: "space-between",
              }}
            >
              Dashboard
            </div>
          </DropdownLink> */}
          {/* <DropdownLink to="/profile/edit">
            <div
              style={{
                justifyContent: "space-between",
              }}
            >
              Profile
              {profile && !profile.answers ? (
                <img
                  src={warningIcon}
                  alt="warningIcon"
                  style={{
                    width: "19px",
                    height: "19px",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                />
              ) : null}
            </div>
          </DropdownLink> */}
          {/* <DropdownLink to="/orders">
            <div
              style={{
                justifyContent: "space-between",
              }}
            >
              Orders
            </div>
          </DropdownLink> */}
          <Dropdown.Divider />
          <Dropdown.Item variant="danger" onClick={() => handleLogout()}>
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login && state.login?.isAuthenticated,
  profile: state.login && state.login?.auth?.profile,
  profilePicture: state.login && state.login?.auth?.auth?.user?.picture,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
