export function findMilestonesQuestions(milestone, usersChecklist) {
  let nrOfQuestions = 0;
  milestone.question.map((item) => {
    usersChecklist.map((act) => {
      if (item.id === act.id && act.checked) {
        nrOfQuestions++;
      }
    });
  });
  if (nrOfQuestions === milestone.question.length) {
    return true;
  } else {
    return false;
  }
}

export function getClassName(activeTab) {
  if (activeTab === "edit-profile" || activeTab === "transactions") {
    return "tab-active";
  } else {
    return "tab-content";
  }
}

export function localTimeUS(itemDate) {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    timezone: "UTC",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  let date = new Date(itemDate);
  let dateString = date.toLocaleString("en-US", options);
  return dateString;
}

export const getUserAnswers = (profile) => {
  let answers = profile.answers
    ? profile.answers.split("===").filter((item) => item)
    : [];

  let fullanswers = [];
  answers.map((answer) => {
    let element = answer.split(";");
    fullanswers.push({
      question: element[0].substring("Question: ".length),
      answer: element[1].substring("Answer: ".length),
      answerId: Number(element[2].substring("Ans-id: ".length)),
      ranking: element[3].substring("Ranking: ".length),
    });
  });
  return fullanswers;
};

const findItem = (id, testToggle) => {
  return testToggle.find((item) => item.id === id);
};

export const setOpenToggle = (obj, testToggle, setTestToggle) => {
  if (testToggle.length !== 0) {
    if (findItem(obj.id, testToggle)) {
      const newArr = testToggle.map((item) => {
        if (item.id === obj.id) {
          return {
            ...item,
            toggle: !item.toggle,
          };
        } else {
          return item;
        }
      });
      setTestToggle(newArr);
    } else {
      setTestToggle([...testToggle, obj]);
    }
  } else {
    setTestToggle([...testToggle, obj]);
  }
};

export const isToggleOpen = (index, testToggle) => {
  if (testToggle.length !== 0) {
    const tt = testToggle.some(
      (toggleItem) => toggleItem.id === index && toggleItem.toggle
    );
    return tt;
  } else {
    return false;
  }
};

export const findIfFinished = (userQuestions, usersChecklist) => {
  let nrOfChecked = 0;
  userQuestions.map((item) => {
    if (item.checked) {
      nrOfChecked++;
    }
  });
  if (usersChecklist.length !== 0) {
    if (nrOfChecked === usersChecklist.length) {
      return true;
    }
  }
  return false;
};

export const getMeshId = (url) => {
  const splitted = url.includes("//") ? url.split("/") : [];
  return splitted[splitted.length - 2];
};

export function replaceMarketplaceState(location) {
  if (location.includes("market")) {
    if (window) {
      window.history.replaceState(
        {},
        document && document.title,
        "/model" + ""
      );
    }
  }
}

export function getPermission(permissions, action) {
  const enable = permissions
    ? permissions.some((prm) => {
        if (prm.toLowerCase().includes(action)) {
          return true;
        } else {
          return false;
        }
      })
    : false;

  return enable;
}

export const dynamicStyle = (prop, prop2) =>
  prop && prop2
    ? {
        backgroundColor: prop,
        borderColor: prop,
        color: prop2,
      }
    : prop2
    ? {
        color: prop2,
      }
    : prop
    ? {
        backgroundColor: prop,
        borderColor: prop,
      }
    : {};

export function pigLatin(whatWeTitle) {
  var splitArray = whatWeTitle.split(/[-'\s]/);
  var finalString = "";
  for (let i = 0; i < splitArray.length; i++) {
    finalString += splitArray[i] === "&" ? "__" : splitArray[i] + "_";
  }
  return finalString;
}

export function filterTags(models) {
  let tags = [];
  if (models.length !== 0) {
    models.map((item) => {
      const parsedTags =
        item.tags && item.tags !== "undefined" ? JSON.parse(item.tags) : [];
      parsedTags.map((tag) => {
        tags.push(tag.tag);
      });
    });
  }

  const uniqueTags =
    tags.length !== 0
      ? tags.filter((val, id, array) => {
          return array.indexOf(val) == id;
        })
      : [];
  return uniqueTags;
}

export function checkItemTag(parsedTags, tagsSelected) {
  return parsedTags.some((tag) => tagsSelected.includes(tag.tag));
}

export function dynamoShopifyItem(
  id,
  profile,
  create,
  laserFont,
  laserImagesUploaded,
  laserCustomText,
  libraryProduct,
  productChosen,
  selectedProduct,
  totalProductPrice,
  shopUrl,
  screenShotLocation,
  selectedMarketplace3dModel
) {
  const dataToDynamo = {
    cartId: id,
    user: `${profile?.firstName} ${profile?.lastName}`,
    item: create.jewelry.itemType.name,
    material: create.jewelry.material ? create.jewelry.material.name : null,
    polish: create.jewelry.polish ? create.jewelry.polish.name : null,
    plating: create.jewelry.plating ? create.jewelry.plating.name : null,
    assemblyOption: create.jewelry.assemblyOption
      ? create.jewelry.assemblyOption.name
      : null,
    laserEngravingOption: create.jewelry.laserEngravingOption
      ? create.jewelry.laserEngravingOption.name
      : null,
    laserEngravingAttributes: {
      laserFont,
      laserImage: laserImagesUploaded ? laserImagesUploaded.data : [],
      laserCustomText,
      libraryProduct: libraryProduct ? libraryProduct.laserEngFile : null,
    },
    surfaceArea: productChosen ? null : create.jewelry.surfaceArea,
    volume: selectedProduct ? selectedProduct.weight : create.jewelry.volume,
    image: productChosen
      ? selectedProduct.prodImages[0]
      : screenShotLocation
      ? screenShotLocation.screenshot_url
      : null,
    price: productChosen
      ? { totalPrice: Number(totalProductPrice).toFixed(2) }
      : {
          castPrice: `${create.price.price.castPrice.labor_per_unit}-${create.price.price.castPrice.machine_labor_cost_per_unit}-${create.price.price.castPrice.market_pricing_per_gram}-${create.price.price.castPrice.percentage_of_casting_increase_for_sprues_and_trees}-${create.price.price.castPrice.percentage_of_markup_for_failure}-${create.price.price.castPrice.price_for_sprues_and_trees}-${create.price.price.castPrice.price_of_material_loss}-${create.price.price.castPrice.price_per_casting}-${create.price.price.castPrice.total_casting_price_per_unit}-${create.price.price.castPrice.total_material_and_machine_cost}-${create.price.price.castPrice.total_material_price}`,
          fixedCostPrice: `${create.price.price.fixedCostPrice.previous_month_fixed_costs}-${create.price.price.fixedCostPrice.previous_month_fixed_costs}-${create.price.price.fixedCostPrice.sum_of_previous_month_fixed_cost_per_unit}-${create.price.price.fixedCostPrice.sum_of_previous_month_fixed_cost_per_unit}-${create.price.price.fixedCostPrice.total_fixed_cost_to_be_added_to_3d_model}-${create.price.price.fixedCostPrice.added_fixed_cost_to_every_unit}-${create.price.price.fixedCostPrice.actual_fix_cost}-${create.price.price.fixedCostPrice.total_dynamic_overhead_price_per_unit}-${create.price.price.fixedCostPrice.total_overhead_price_per_unit}`,
          polishLaborPrice: `${create.price.price.polishLaborPrice.increase_to_add_if_below_min}-${create.price.price.polishLaborPrice.machine_labor_cost_per_unit}-${create.price.price.polishLaborPrice.machine_load_unload_labor}-${create.price.price.polishLaborPrice.min_surface_area}-${create.price.price.polishLaborPrice.percentage_of_markup_for_failure}-${create.price.price.polishLaborPrice.price_per_polished_unit}-${create.price.price.polishLaborPrice.total_polishing_labor_and_machine_price}-${create.price.price.polishLaborPrice.total_polishing_price}-${create.price.price.polishLaborPrice.total_polishing_price_per_unit}`,
          threeDPrintingPrice: `${create.price.price.threeDPrintingPrice.labor_per_unit}-${create.price.price.threeDPrintingPrice.machine_labor_cost_per_unit}-${create.price.price.threeDPrintingPrice.material_cost_per_ml}-${create.price.price.threeDPrintingPrice.material_price_per_unit}-${create.price.price.threeDPrintingPrice.model_volume_percentage_increase_for_3d_support}-${create.price.price.threeDPrintingPrice.percentage_markup_for_failiure}-${create.price.price.threeDPrintingPrice.price_per_3d_model_part}-${create.price.price.threeDPrintingPrice.total_3d_printing_price_per_unit}-${create.price.price.threeDPrintingPrice.total_material_and_machine_price}-${create.price.price.threeDPrintingPrice.total_material_for_3d_supports}`,
          platingPrice: create.price.price.platingPrice
            ? `${create.price.price.platingPrice.material_cost_per_mm2}-${create.price.price.platingPrice.material_price_per_plated_unit}-${create.price.price.platingPrice.plating_labor_price_per_mm2}-${create.price.price.platingPrice.total_plating_labor}-${create.price.price.platingPrice.total_plating_price}`
            : 0,
          assemblyOptionPrice: create.price.price.assemblyOptionPrice
            ? `${create.price.price.assemblyOptionPrice.assembly_type_cost}-${create.price.price.assemblyOptionPrice.labor_for_assembly_type}-${create.price.price.assemblyOptionPrice.total_assembly_price_and_labor}-${create.price.price.assemblyOptionPrice.markup_for_failure} - ${create.price.price.assemblyOptionPrice.total_assembly_price_per_unit}`
            : 0,
          laserEngravingOptionPrice: create.price.price
            .laserEngravingOptionPrice
            ? `${create.price.price.laserEngravingOptionPrice.laser_type_cost}-${create.price.price.laserEngravingOptionPrice.labor_for_laser_type}-${create.price.price.laserEngravingOptionPrice.markup_for_failure}-${create.price.price.laserEngravingOptionPrice.total_laser_price_and_labor}-${create.price.price.laserEngravingOptionPrice.total_laser_price_per_unit}`
            : 0,
          totalPrice: Number(create.price.price.totalPrice).toFixed(2),
        },
    file_name: productChosen ? null : create.jewelry.file_name,
    shopUrl: shopUrl ? shopUrl : null,
    variant: false,
    variant_id: null,
    product: productChosen ? true : false,
    productId: selectedProduct ? selectedProduct.name : null,
    marketFile: selectedMarketplace3dModel ? true : false,
  };
  return dataToDynamo;
}

export function findQuestionAndAns(stateAnswers, qtId, ansId) {
  return (
    stateAnswers &&
    stateAnswers.length > 0 &&
    stateAnswers.some((st) => st.question === qtId && st.answer === ansId)
  );
}
