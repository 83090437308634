import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import gildformLogoBlack from "../assets/images/gildformlogo-black.png";
import gildformLogo from "../assets/images/logo.png";
import { isToggleOpen, setOpenToggle } from "../utils/helpers";
import WindoWidth from "../components/WindowWidth";
import { Container, Row } from "react-bootstrap";

const ActualLink = ({
  internal_link,
  link_text,
  external_link,
  link_column,
  idz,
}) => {
  if (internal_link) {
    const linkParts = internal_link.split("/");
    const pageSlug = linkParts[linkParts.length - 1];
    const pageCategory = linkParts[linkParts.length - 2];
    if (pageCategory === "designer") {
      return (
        <Link to={`/designer/${pageSlug}`} key={idz}>
          {link_text}
        </Link>
      );
    }

    return (
      <Link to={pageSlug} key={idz}>
        {link_text}
      </Link>
    );
  }

  return (
    <a href={external_link} target="__blank" key={idz}>
      {link_column.title === "Social Media" && (
        <i
          className={
            link_text.toLowerCase() === "instagram"
              ? "fab fa-instagram"
              : link_text.toLowerCase() === "facebook"
              ? "fab fa-facebook"
              : link_text.toLowerCase() === "twitter"
              ? "fab fa-twitter"
              : link_text.toLowerCase() === "linkedin"
              ? "fab fa-linkedin"
              : ""
          }
          key={idz}
        />
      )}
      {link_text}
    </a>
  );
};

const Footer = ({ forceBottom }) => {
  const [toggle, setToggle] = useState({ id: "", toggle: false });
  const [testToggle, setTestToggle] = useState([]);

  const [footerWp, setFooterWp] = useState(null);

  const footerWpQuery = async () => {
    const res = await axios.get(
      `https://content.gildform.com/wp-json/acf/v3/footer/663`
    );
    if (res.status === 200) {
      setFooterWp(res.data);
    }
  };

  const windowWidth = WindoWidth();

  useEffect(() => {
    footerWpQuery();
  }, []);

  return (
    <Row as="footer" className={forceBottom ? "bottom" : ""}>
      <Container>
        <div className="logo-description">
          <div className="logo">
            <img className="gildform-logo" src={gildformLogoBlack} />
            <img className="gildform-logo-text" src={gildformLogo} />
          </div>
          <div className="gildform-description">
            {footerWp && (
              <span
                dangerouslySetInnerHTML={{
                  __html: footerWp.acf.footer_description,
                }}
              />
            )}
          </div>
        </div>
        <div className="links">
          {footerWp &&
            footerWp.acf.link_columns_rebrand.map((item, index) => {
              const finl = item.links.map((it) => {
                return {
                  ...it,
                  idd: Math.random().toString(16).slice(2),
                };
              });
              return (
                <div className="single-link" key={index}>
                  <div
                    className="link-click"
                    data-toggle="collapse"
                    data-target={
                      windowWidth > 992 ? "" : `#${item.title.split(" ")[0]}`
                    }
                    role="button"
                    aria-expanded="false"
                    aria-controls="link1"
                    onClick={() =>
                      setOpenToggle(
                        { id: index, toggle: !toggle.toggle },
                        testToggle,
                        setTestToggle
                      )
                    }
                  >
                    <span className="link-title">{item.title}</span>
                    {isToggleOpen(index, testToggle) ? (
                      <i className="fas fa-chevron-up" />
                    ) : (
                      <i className="fas fa-chevron-down" />
                    )}
                  </div>
                  <div className="collapse" id={`${item.title.split(" ")[0]}`}>
                    <div className="link-content">
                      {finl.map((link) => {
                        return (
                          <ActualLink
                            {...link}
                            link_column={item}
                            idz={link.idd}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Container>
      <hr style={{ width: "90%", margin: "auto" }} />
      <div className="date">
        Copyright © {new Date().getFullYear()} <a href="#">Gildform</a>
      </div>
    </Row>
  );
};

export default Footer;
