export const materialOptions = {
  SSA1: {
    name: ".925 Sterling Silver",
    sku: "SSA1",
    canPlate: true,
    texture_url: "/textures/925-sterling-silver.png",
    item_id: 24,
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 188,
      g: 193,
      b: 205,
    },
  },
  "12KY": {
    name: "12K Yellow Gold",
    sku: "12KY",
    canPlate: false,
    texture_url: "/textures/10k-yellow-gold.png",
    item_id: 17,
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 255,
      g: 220,
      b: 115,
    },
  },
  "14KY": {
    name: "14K Yellow Gold",
    sku: "14KY",
    canPlate: false,
    texture_url: "/textures/14k-yellow-gold.png",
    item_id: 6,
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 255,
      g: 207,
      b: 64,
    },
  },
  "18KY": {
    name: "18K Yellow Gold",
    sku: "18KY",
    canPlate: false,
    texture_url: "/textures/18k-yellow-gold.png",
    item_id: 14,
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 255,
      g: 191,
      b: 0,
    },
  },
  MA_WBRNZ: {
    name: "White Bronze",
    sku: "MA_WBRNZ",
    texture_url: "/textures/white-bronze.png",
    canPlate: true,
    item_id: 72,
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 190,
      g: 170,
      b: 138,
    },
  },
  MET_BRASS: {
    name: "Yellow Brass",
    sku: "MET_BRASS",
    texture_url: "/textures/alloy-yellow.png",
    item_id: 73,
    variant: 0,
    canPlate: true,
    sub_item_id: 0,
    color: {
      r: 181,
      g: 166,
      b: 66,
    },
  },
  BRASS: {
    name: "Yellow Brass",
    sku: "BRASS",
    texture_url: "/textures/alloy-yellow.png",
    item_id: 73,
    variant: 0,
    canPlate: true,
    sub_item_id: 0,
    color: {
      r: 181,
      g: 166,
      b: 66,
    },
  },
  "14KP": {
    name: "Rose Gold",
    texture_url: "/textures/gold-rose-18k.png",
    item_id: "202",
    variant: 0,
    canPlate: false,
    sub_item_id: 0,
    color: {
      r: 216,
      g: 161,
      b: 117,
    },
  },
};

export const platingOptions = {
  none: {
    name: "None",
    texture_url: "",
    item_id: "123",
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 212,
      g: 175,
      b: 55,
    },
  },
  "PLATING-14YG": {
    name: "14K YG",
    texture_url: "/textures/14k-yellow-gold.png",
    item_id: "200",
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 255,
      g: 207,
      b: 64,
    },
  },
  "PLATING-18KR": {
    name: "18K YG",
    texture_url: "/textures/18k-yellow-gold.png",
    item_id: "201",
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 255,
      g: 191,
      b: 0,
    },
  },
  "14PG": {
    name: "Rose Gold",
    texture_url: "/textures/gold-rose-18k.png",
    item_id: "202",
    variant: 0,
    sub_item_id: 0,
    color: {
      r: 216,
      g: 161,
      b: 117,
    },
  },
};

export const polishOptions = {
  LAB_PAL: {
    name: "Natural",
    sku: "LAB_PAL",
    item_id: 90,
    variant: 0,
    sub_item_id: 0,
    diffuse: 0.75,
    sort: 0,
  },
  "LABOR-POLISHING": {
    name: "Satin",
    sku: "LABOR-POLISHING",
    item_id: 88,
    variant: 0,
    sub_item_id: 0,
    diffuse: 0.85,
    sort: 1,
  },
  LAB_PLA: {
    sku: "LAB_PLA",
    name: "High Polish",
    item_id: 89,
    variant: 0,
    sub_item_id: 0,
    diffuse: 0.95,
    sort: 2,
  },
};
