import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

// import DeliveryDate from "../DeliveryDate";
import { setQuantity } from "../../store/create/jewelry/thunks";

const PriceWidget = ({
  price,
  quantity,
  setQuantity,
  priceIsLoading,
  libraryOpen,
  designWithUsModalOpen,
  creditCardOpen,
  new_order,
  productionPage,
  sellingPrice,
  qty,
  estimatedPrice,
  priceLoading,
  productChosen,
  productPricing,
  materialPrice,
  polishPrice,
  platingPrice,
  assemblyPrice,
  laserEngravingPrice,
  disPrice,
  assemblyDiscount,
}) => {
  const [monthly, setMonthly] = useState(false);

  if (libraryOpen) {
    return null;
  }
  if (designWithUsModalOpen || creditCardOpen) {
    return null;
  }

  const profitOutput =
    productionPage &&
    estimatedPrice &&
    Number(sellingPrice) * Number(qty) -
      Number(estimatedPrice && estimatedPrice.totalPrice) * Number(qty);

  var locales = [
    undefined, // Your own browser
    "en-US", // United States
    "de-DE", // Germany
    "ru-RU", // Russia
    "hi-IN", // India
    "de-CH", // Switzerland
  ];
  var n = 100000;
  var opts = { minimumFractionDigits: 2 };

  const revenue = monthly
    ? Number((Number(sellingPrice) * Number(qty)) / 12).toFixed(2)
    : Number(Number(sellingPrice) * Number(qty)).toFixed(2);

  const profit = monthly
    ? Number(profitOutput / 12).toFixed(2)
    : Number(profitOutput).toFixed(2);

  const proPrice =
    (assemblyDiscount > 0 ? disPrice : productPricing) +
    materialPrice +
    platingPrice +
    polishPrice +
    assemblyPrice +
    laserEngravingPrice;

  return (
    <Row
      className="price-holder"
      style={productionPage ? { marginTop: "7rem" } : { marginTop: "0rem" }}
    >
      {productionPage ? (
        <Col>
          <Row
            className="price-widget py-4"
            style={
              productionPage ? { minWidth: "fit-content", padding: "1rem" } : {}
            }
          >
            <div className="main-calc">
              <div
                className="profit"
                style={{
                  marginBottom: "1.5rem",
                  color: "#7c6263",
                }}
              >
                <span>&#8226;</span>Estimated Cost:{" "}
                {priceLoading ? (
                  <div>
                    <i className="fas fa-circle-notch fa-spin" />
                  </div>
                ) : (
                  `$${
                    estimatedPrice ? estimatedPrice.totalPrice.toFixed(2) : 0
                  }`
                )}
              </div>
              <div className="revenue">
                <span>&#8226;</span>Revenue Output (
                {monthly ? "monthly" : "yearly"}):{" "}
                {`$${Number(revenue).toLocaleString(locales[revenue], opts)}`}
              </div>
              <div className="profit">
                <span>&#8226;</span>Profit Output:{" "}
                {`$${Number(profit).toLocaleString(locales[profit], opts)}`}
              </div>
            </div>
            <div className="switches">
              <div className="action-box">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-bottom" style={{ zIndex: "10001" }}>
                      Click to calculate {monthly ? "yearly" : "monthly"}{" "}
                      values!
                    </Tooltip>
                  }
                  placement="top"
                >
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={monthly}
                      onChange={(e) => {
                        setMonthly(e.target.checked);
                      }}
                    />
                    <span className="switch" />
                  </label>
                </OverlayTrigger>
              </div>
            </div>
          </Row>
        </Col>
      ) : (
        <Col>
          <Row className="price-widget py-4">
            <Col>
              <div className="price-unit">Unit Price</div>
            </Col>
            {productChosen ? (
              <Col>{`$${proPrice && Number(proPrice).toFixed(2)}`}</Col>
            ) : (
              <Col>
                {!priceIsLoading ? (
                  <>${!!quantity ? price?.toFixed(2) : 0.0}</>
                ) : (
                  <i className="fas fa-circle-notch fa-spin" />
                )}
              </Col>
            )}
            <Col>x</Col>
            <Col>
              <Form.Group id="unit-tour">
                <Form.Control
                  style={{ textAlign: "right" }}
                  as="input"
                  value={quantity}
                  onChange={(e) => {
                    if (e.target.value < 1) return;
                    setQuantity(parseInt(e.target.value));
                  }}
                  type="number"
                  disabled={new_order}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-3">
            {/* <Col>{price !== 0 && <DeliveryDate />}</Col> */}
          </Row>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  price: state.create && state.create?.price?.price?.totalPrice,
  priceIsLoading:
    (state.create && state.create?.price?.isFetching) ||
    (state.create && state.create.jewelry?.isLoading?.modelIsLoading),
  quantity: state.create && state.create?.jewelry?.quantity,
  libraryOpen: state.create && state.create?.jewelry?.libraryOpen,
  creditCardOpen: state.create && state.create?.jewelry?.creditCardOpen,
  designWithUsModalOpen:
    state.create && state.create.jewelry?.designWithUsModalOpen,
  new_order: state.create && state.create.jewelry?.new_order,
});

const mapDispatchToProps = (dispatch) => ({
  setQuantity: (quantity) => dispatch(setQuantity(quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceWidget);
