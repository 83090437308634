import { useState, useEffect } from "react";

const WindoWidth = () => {
  const windowGlobal = typeof window !== "undefined";

  const [width, setWidth] = useState(windowGlobal ? window.innerWidth : null);
  useEffect(() => {
    if (windowGlobal) {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  return width;
};

export default WindoWidth;
