import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import darkIcon from "../assets/images/logo.svg";
import lightIcon from "../assets/images/logo_white.png";
import testLogo from "../assets/images/Discovery-Channel-Logo.png";
import CartDisplay from "../components/cart";
import Login from "../components/header/Login";
import Profile from "../components/header/Profile";
import Signup from "../components/header/SignUp";
// import WhatsNew from "./WhatsNew";

const getNavInfo = (data) => {
  if (data.length <= 0) {
    return [];
  }
  return data;
};

const renderNavLink = (component) => {
  if (component.type === "custom") {
    return (
      <a href={component.url} className="nav-link font-weight-bold">
        {component.title}
      </a>
    );
  }
  return (
    <Link className="nav-link font-weight-bold" to={component.post_name}>
      {component.title}
    </Link>
  );
};

const Header = ({
  light_header,
  backgroundImage,
  sit_on_top,
  onboarding,
  noAnswers,
  profile,
  cart,
}) => {
  const [menuItems, setMenuItems] = useState(null);
  const [openNewFeat, setOpenNewFeature] = useState(false);
  const [newFeats, setNewFeats] = useState(null);
  const [width, setWidth] = useState();

  const fetchMenus = async () => {
    const res = await axios.get(
      "https://content.gildform.com/wp-json/menus/v1/menus/nav-bar"
    );
    if (res.status === 200) {
      setMenuItems(getNavInfo(res.data.items));
    }
  };

  const headerData = [
    {
      object_slug: "learn",
      title: "Learn",
      type: "custom",
      url: "https://academy.gildform.com",
    },
    {
      object_slug: "our-story",
      title: "Our Story",
      type: "post_type",
      url: "https://content.gildform.com/archives/marketing_page/our-story",
    },
    {
      object_slug: "why-gildform",
      title: "Why Gildform",
      type: "custom",
      url: "#",
    },
    {
      object_slug: "blog",
      title: "Blog",
      type: "custom",
      url: "http://blog.gildform.com",
    },
    {
      object_slug: "help-center",
      title: "Help Center",
      type: "custom",
      url: "https://support.gildform.com/",
    },
  ];

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
    }
  }, []);

  // useEffect(() => {
  //   fetchMenus();
  // }, []);

  const isPhoneScreen = width < 768;
  const isTabletScreen = width > 768 && width < 992;
  const navbarType = light_header ? "navbar-dark" : "navbar-light";
  const hasBackgroundImg = backgroundImage ? "bg-nav" : "";
  const sitOnTop = sit_on_top ? "sit-on-top" : "";
  const navbarclass = `navbar navbar-expand-lg ${navbarType} ${hasBackgroundImg} ${sitOnTop}`;
  const logo = light_header ? lightIcon : darkIcon;
  const logoClass = light_header ? "navbar-brand light" : "navbar-brand";
  const backgroundImageUrl = `url(${backgroundImage})`;
  const styleHeader = () => {
    if (onboarding) {
      return { backgroundColor: "rgba(124, 98, 98, .97)", zIndex: 4 };
    } else if (noAnswers) {
      if (isPhoneScreen) {
        return { zIndex: 4, top: "60px" };
      } else if (isTabletScreen) {
        return { zIndex: 4, top: "38px" };
      } else {
        return { zIndex: 4, top: "30px" };
      }
    } else {
      return { zIndex: 4 };
    }
  };
  const conditionCrt = profile?.creatorId;

  return (
    <nav
      className={navbarclass}
      style={
        styleHeader()
        // onboarding
        //   ? { backgroundColor: 'rgba(124, 98, 98, .97)', zIndex: 4 }
        //   : noAnswers
        //   ? { zIndex: 4, top: '30px' }
        //   : { zIndex: 4 }
      }
    >
      {/* <WhatsNew
        setOpenNewFeature={setOpenNewFeature}
        openFeatureModal={openNewFeat}
        setNewFeats={setNewFeats}
      /> */}
      <Link className={logoClass} to="/">
        <img
          src={
            profile?.creatorId ? (profile?.image ? profile?.image : logo) : logo
          }
          alt="Gildform logo"
        />
        {profile?.creatorId && (
          <p style={{ fontFamily: "MyAppFont" }}>Powered by Gildform</p>
        )}
      </Link>
      <button
        className="navbar-toggler ml-auto order-lg-1 icon-controller"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="navbar-nav ml-auto order-lg-2 cart-icon">
        {/* <div
          onClick={() => {
            setTimeout(() => {
              setOpenNewFeature(true);
            }, 400);
          }}
          className="new-feat-icon"
        >
          {newFeats && (
            <span className="ann-nr">
              <b>{newFeats}</b>
            </span>
          )}
          <span style={{ fontSize: "23px", color: "rgba(0, 0, 0, 0.75)" }}>
            <img src={newFeature} width={"40"} height={"40"} />
          </span>
        </div> */}
        <CartDisplay />
      </div>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto order-lg-1">
          <Profile />
          <li className="nav-item" id="tour-item">
            <Link
              className="nav-link font-weight-bold"
              to={
                conditionCrt
                  ? profile?.pageUrl
                    ? `${profile?.pageUrl}`
                    : "/model"
                  : "/model"
              }
            >
              Get Started <span className="sr-only">(current)</span>
            </Link>
          </li>
          {/* {menuItems?.map((component) => (
            <li key={component.url} className="nav-link">
              {renderNavLink(component)}
            </li>
          ))} */}
          <li className="nav-item" />
          <Login />
          {/* <Signup /> */}
        </ul>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  profile: state.login?.auth?.profile,
});

export default connect(mapStateToProps, null)(Header);
