import React from "react";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import noImage from "../assets/images/noimage.jpg";
import { dynamicStyle } from "../utils/helpers";

const MARKET_URL = process.env.REACT_APP_MARKETPLACE_URL;

const LaserEngravinLibrary = (props) => {
  const buttonStyle = dynamicStyle(
    props.profile.primaryButtonColor,
    props.profile.buttonTextColor
  );
  const filteredItems =
    !props.filterProjectItems || props.filterProjectItems === "all"
      ? props.projectItems
      : props.projectItems.filter(
          (item) => Number(item.id) === Number(props.filterProjectItems)
        );
  return props.projectItems && props.projectItems.length === 0 ? (
    <div className="no-prj">
      You have no projects items that require laser engraving in the
      Marketplace!
      <Button
        style={{ ...buttonStyle, marginTop: "1rem" }}
        onClick={() => {
          if (window) {
            window.open(`${MARKET_URL}`, "_blank");
          }
        }}
      >
        Go to marketplace!
      </Button>
    </div>
  ) : (
    <div className="marketplace-lib-main">
      <div className="mdl-title">Choose one project item</div>
      <div className="filter-designs">
        <span className="filter-title">Filter by project item:</span>
        <span>
          <Form.Control
            as="select"
            name={"select"}
            onChange={(e) => {
              props.setFilterProjectItems(e.target.value);
            }}
            value={props.filterProjectItems}
          >
            <option value="0">Choose project</option>
            <option value="all">All Items</option>
            {props.projectItems.map((projectItem) => {
              return (
                <option key={projectItem.id} value={projectItem.id}>
                  {projectItem.title}
                </option>
              );
            })}
          </Form.Control>
        </span>
      </div>
      <div className="models-list">
        {filteredItems.map((item, index) => {
          const matched =
            props.selectedItem && props.selectedItem.id === item.id
              ? true
              : false;
          return (
            <div
              className={matched ? "model-card selected-card" : "model-card"}
              key={index}
              onClick={() => {
                props.setSelectedItem(item);
              }}
            >
              <div className="card_image">
                <img
                  src={item.images ? item.images : noImage}
                  className="img-fluid"
                />
              </div>
              <div className="model-title">{item.title}</div>
              <div className="card-body">{item.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.login && state.login?.auth?.profile,
});

export default connect(mapStateToProps)(LaserEngravinLibrary);
