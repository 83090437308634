import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import shopifyIcon from "../../assets/images/shopify-icon.png";
import gildformIcon from "../../assets/images/gildformlogo-black.png";
import {
  uploadItemImage,
  uploadFile,
  uploadLaserEngravingImages,
  openConnectAccount,
  getSettingsById,
} from "../../utils/api";
import { goNext, goBack } from "../../store/steps/action";
import {
  resetCreate,
  setFontLaser,
  setImageScreenShot,
  setLaserCustomText,
  setLaserImage,
  setMeshLocation,
} from "../../store/create/jewelry/action";
import { resetPrice } from "../../store/create/price/action";
import {
  dynamicStyle,
  dynamoShopifyItem,
  getMeshId,
  getPermission,
} from "../../utils/helpers";
import CustomTooltip from "../Tooltip";
import { setUserGildformItem } from "../../store/auth/action";
// import {
//   setProductDescription,
//   setProductImages,
//   setProductName,
//   setProductPrice,
// } from "../../state/create/gildformStore/action";
import { updateUser } from "../../store/auth/thunks";
// import GildformStore from "../GildformStore";

const API_URL = process.env.REACT_APP_API_URL;
const APP_URL = process.env.REACT_APP_URL;
const CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;

const Step = ({
  stepNumber,
  title,
  isActive,
  children,
  displayPrevious,
  displaySubmit,
  goToPreviousStep,
  goToNextStep,
  isSkippable,
  displayNext,
  onSkip,
  onSubmit,
  verify,
  priceIsFetching,
  addToCartLoading,
  takeScreenShot,
  create,
  token,
  profile,
  setImageScreenShot,
  setMeshLocation,
  resetCreate,
  resetPrice,
  modelChosen,
  selected3dModel,
  selectedMarketplace3dModel,
  itemType,
  fileDimensionError,
  productionPage,
  weight,
  priceLoading,
  estimatedPrice,
  modelHeight,
  modelWidth,
  modelLength,
  showHowMuch,
  homMuchToMake,
  productChosen,
  selectedProduct,
  setLaserFont,
  setLaserCustomText,
  setLaserImage,
  isProductChosen,
  setSelectedProduct,
  totalProductPrice,
  laserCustomText,
  laserFont,
  laserImage,
  libraryProduct,
  prodName,
  prodDescription,
  prodPrice,
  prodImages,
  setProductName,
  setProductDescription,
  setProductPrice,
  setProductImages,
  setGildformItem,
  setOpenStoreModal,
  openStoreModal,
  gildformItem,
  update,
  currentStep,
  setRedirectedStripe,
  stripeComplete,
  stripeAcc,
  getStripeConnectAccount,
  setSelectedMarket3dModel,
}) => {
  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);

  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const shopUrl = profile?.shopifyUrl;
  const shopToken = profile?.shopifyToken;
  const [accLink, setAccLink] = useState(null);
  const [gildformPercentage, setGildPercentage] = useState(null);

  const btnRef = useRef(null);
  const shopifybtnRef = useRef(null);
  const [proShow, setProfShow] = useState(false);
  const [showShopifyTool, setShowShopifyTool] = useState(false);

  const [imageUplod, setImgUpl] = useState(false);
  const [imageUse, setImgUse] = useState(false);

  const link = accLink
    ? accLink
    : `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${`${APP_URL}/model`}&client_id=${CLIENT_ID}&state=${`Gildform123@`}`;

  const openStripeAcc = async (id) => {
    const res = await openConnectAccount(id);
    if (res) {
      setAccLink(res.url);
      if (!stripeComplete) {
        update({ ...profile, stripeConnectId: res.account_id });
      }
    }
  };

  const getSettingPercentage = async () => {
    const res = await getSettingsById(57);
    setGildPercentage(res);
  };

  useEffect(() => {
    if (currentStep >= 2) {
      if (profile?.stripeConnectId && !stripeAcc) {
        getStripeConnectAccount(profile?.stripeConnectId);
      }
    }
    if (openStoreModal && profile) {
      if (!profile?.stripeConnectId || (!stripeComplete && !accLink)) {
        openStripeAcc(profile?.stripeConnectId);
      }
    }
  }, [openStoreModal, profile, currentStep]);

  useEffect(() => {
    if (openStoreModal) {
      getSettingPercentage();
    }
  }, [openStoreModal]);

  if (!onSkip) {
    onSkip = () => true;
  }
  if (!onSubmit) {
    onSubmit = () => true;
  }

  if (!isActive) {
    return null;
  }

  if (!verify) {
    verify = () => true;
  }

  const resetPage = () => {
    resetCreate();
    resetPrice();
    isProductChosen(null);
    setSelectedProduct(null);
    if (selectedMarketplace3dModel) {
      setSelectedMarket3dModel(null);
    }
    if (gildformItem) {
      setGildformItem(null);
    }
    if (openStoreModal) {
      setOpenStoreModal(false);
    }
    setProductDescription(null);
    setProductImages([]);
    setProductName(null);
    setProductPrice(null);
  };

  const setModelElementsShop = async () => {
    let cartId = `${uuid()}-${uuid()}`;

    const modelLibrary = selected3dModel
      ? selected3dModel.mesh_folder
      : selectedMarketplace3dModel
      ? getMeshId(selectedMarketplace3dModel.model3D)
      : null;

    let id = modelChosen === true ? modelLibrary : cartId;

    const pic = productChosen ? null : await takeScreenShot();
    if (!productChosen) {
      setImageScreenShot(pic);
    }

    const data = !productChosen
      ? {
          id: id,
          file: window.meshes[create.jewelry.file_name],
        }
      : null;

    if (modelChosen === true || productChosen) {
      return;
    } else {
      await uploadFile(data, token);
    }

    const screenShotLocation = productChosen
      ? null
      : await uploadItemImage(id, pic);

    setMeshLocation(id);
    let laserImagesUploaded;
    if (laserImage && laserImage.length > 0) {
      laserImagesUploaded = await uploadLaserEngravingImages(laserImage, null);
    }
    return { laserImagesUploaded, id, screenShotLocation };
  };

  const addToShopify = async () => {
    setLoading(true);

    const modelElements = await setModelElementsShop();

    const dataToDynamo = dynamoShopifyItem(
      modelElements.id,
      profile,
      create,
      laserFont,
      modelElements.laserImagesUploaded,
      laserCustomText,
      libraryProduct,
      productChosen,
      selectedProduct,
      totalProductPrice,
      shopUrl,
      modelElements.screenShotLocation,
      selectedMarketplace3dModel
    );

    const res = await fetch(`https://apish.gildform.com/gildform/api/add`, {
      body: JSON.stringify({
        product: {
          title: `${create.jewelry.material.name} ${create.jewelry.itemType.name}`,
          vendor: "Gildform",
          handle: `${
            productChosen
              ? Number(totalProductPrice).toFixed(1)
              : Number(create.price.price.totalPrice).toFixed(1)
          }dividedby${modelElements.id}dividedby${create.jewelry.itemType.id}-${
            create.jewelry.material.id
          }-${create.jewelry.plating ? create.jewelry.plating.id : null}-${
            create.jewelry.polish ? create.jewelry.polish.id : null
          }-${
            create.jewelry.assemblyOption
              ? create.jewelry.assemblyOption.id
              : null
          }-${
            create.jewelry.laserEngravingOption
              ? create.jewelry.laserEngravingOption.id
              : null
          }-${selectedMarketplace3dModel && `mkpl`}dividedby${
            create.jewelry.volume
          }-${create.jewelry.surfaceArea}dividedby${
            create.jewelry.file_name
          }dividedby${productChosen ? `product` : null}dividedby${
            selectedProduct ? selectedProduct.id : null
          }`,
          body_html: create.jewelry.description,
          images: [
            {
              src: productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : modelElements.screenShotLocation.screenshot_url,
            },
          ],
          variants: [
            {
              price: productChosen
                ? totalProductPrice
                : create.price.price.totalPrice,
              title: `${create.jewelry.material.name}-${create.jewelry.itemType.name}`,
              sku: `${create.price.price.totalPrice}dividedby${
                modelElements.id
              }dividedby${create.jewelry.itemType.id}-${
                create.jewelry.material.id
              }-${create.jewelry.plating ? create.jewelry.plating.id : null}-${
                create.jewelry.polish ? create.jewelry.polish.id : null
              }-${
                create.jewelry.assemblyOption
                  ? create.jewelry.assemblyOption.id
                  : null
              }-${
                create.jewelry.laserEngravingOption
                  ? create.jewelry.laserEngravingOption.id
                  : null
              }-${selectedMarketplace3dModel && `mkpl`}dividedby${
                productChosen ? selectedProduct.weight : create.jewelry.volume
              }-${create.jewelry.surfaceArea}dividedby${
                create.jewelry.file_name
              }dividedby${productChosen ? `product` : null}`,
            },
          ],
          added: true,
          published: false,
        },
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Shopify-URL": shopUrl,
        "Shopify-access-token": shopToken,
      },
    });
    setLoading(false);
    if (res.status === 200 || res.status === 201) {
      const resApi = await fetch(`${API_URL}/shopify/items`, {
        body: JSON.stringify(dataToDynamo),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Product added to Shopify successfully!", {
        className: "bg-primary text-white",
        onClose: () => {
          resetPage();
        },
      });
    } else {
      toast.error("Something went wrong. Please check your Shopify details!");
    }
  };

  const permissions = profile && profile?.subUser ? profile?.permissions : [];

  const conditionCrt = profile?.creatorId;

  const text = displaySubmit
    ? productionPage
      ? "Show Me How Much Money I Could Make"
      : "ADD TO CART"
    : "CONTINUE";
  const action = displaySubmit
    ? productionPage
      ? homMuchToMake
      : onSubmit
    : goToNextStep;
  const disableSubUser = displaySubmit
    ? profile?.subUser
      ? !getPermission(permissions, "order")
      : false
    : false;

  let btn = showHowMuch ? null : (
    <Button
      id="continue-tour"
      className="continue mr-2 mt-1"
      onClick={() => {
        if (productionPage || productChosen) {
          action();
        } else {
          if (verify()) {
            action();
          }
        }
      }}
      style={dynamicStyle(
        profile?.primaryButtonColor,
        profile?.buttonTextColor
      )}
      disabled={
        productionPage
          ? !modelHeight || !modelWidth || !modelLength || !weight || !itemType
          : productChosen
          ? null
          : !verify() ||
            disableAddToCart ||
            fileDimensionError ||
            disableSubUser
      }
      onMouseEnter={() => {
        if (displaySubmit && profile?.subUser) {
          if (!getPermission(permissions, "order")) {
            setProfShow(true);
          }
        }
      }}
      onMouseLeave={() => {
        if (profile?.subUser) {
          setProfShow(false);
        }
      }}
      ref={btnRef}
    >
      {priceIsFetching || addToCartLoading ? (
        <i className="fas fa-circle-notch fa-spin" />
      ) : null}
      {priceLoading ? <i className="fas fa-circle-notch fa-spin" /> : text}
    </Button>
  );

  const publishToGildformStore = async () => {
    setStoreLoading(true);
    const finalProduct = {
      ...gildformItem,
      gildformStore: true,
      sellingPrice: prodPrice,
      itemName: prodName,
      itemDesc: prodDescription,
      itemImages: prodImages,
    };
    const res = await fetch(`https://apish.gildform.com/gildform/api/add`, {
      body: JSON.stringify({
        product: {
          title: `${prodName ? `${prodName}: ` : ""}${finalProduct.material} ${
            finalProduct.item
          }`,
          vendor: "Gildform",
          handle: `${
            productChosen || (gildformItem && gildformItem.product)
              ? Number(
                  currentStep === 0
                    ? gildformItem.price.totalPrice
                    : totalProductPrice
                ).toFixed(2)
              : Number(gildformItem.create.price.price.totalPrice).toFixed(2)
          }dividedby${gildformItem.cartId}dividedby${
            gildformItem.create.jewelry.itemType.id
          }-${
            gildformItem.create.jewelry.material
              ? gildformItem.create.jewelry.material.id
              : null
          }-${
            gildformItem.create.jewelry.plating
              ? gildformItem.create.jewelry.plating.id
              : null
          }-${
            gildformItem.create.jewelry.polish
              ? gildformItem.create.jewelry.polish.id
              : null
          }-${
            gildformItem.create.jewelry.assemblyOption
              ? gildformItem.create.jewelry.assemblyOption.id
              : null
          }-${
            gildformItem.create.jewelry.laserEngravingOption
              ? gildformItem.create.jewelry.laserEngravingOption.id
              : null
          }-${selectedMarketplace3dModel && `mkpl`}dividedby${
            gildformItem.create.jewelry.volume
          }-${gildformItem.create.jewelry.surfaceArea}dividedby${
            gildformItem.create.jewelry.file_name
          }dividedby${productChosen ? `product` : null}dividedby${
            selectedProduct ? selectedProduct.id : null
          }dividedbygildstore-${prodPrice}`,
          body_html: prodPrice
            ? `${prodPrice}-${prodDescription}`
            : gildformItem.create.jewelry.description,
          images: [
            {
              src: productChosen
                ? selectedProduct && selectedProduct.prodImages[0]
                : imageUplod
                ? prodImages[0].image
                : imageUse
                ? gildformItem.image
                : null,
            },
          ],
          variants: [
            {
              price:
                productChosen || (gildformItem && gildformItem.product)
                  ? Number(
                      currentStep === 0
                        ? gildformItem.price.totalPrice
                        : totalProductPrice
                    ).toFixed(2)
                  : Number(gildformItem.create.price.price.totalPrice).toFixed(
                      2
                    ),
              title: `${prodName}:${finalProduct.material} ${finalProduct.item}`,
              sku: `${
                productChosen || (gildformItem && gildformItem.product)
                  ? Number(
                      currentStep === 0
                        ? gildformItem.price.totalPrice
                        : totalProductPrice
                    ).toFixed(2)
                  : Number(gildformItem.create.price.price.totalPrice).toFixed(
                      2
                    )
              }dividedby${gildformItem.cartId}dividedby${
                gildformItem.create.jewelry.itemType.id
              }-${
                gildformItem.create.jewelry.material
                  ? gildformItem.create.jewelry.material.id
                  : null
              }-${
                gildformItem.create.jewelry.plating
                  ? gildformItem.create.jewelry.plating.id
                  : null
              }-${
                gildformItem.create.jewelry.polish
                  ? gildformItem.create.jewelry.polish.id
                  : null
              }-${
                gildformItem.create.jewelry.assemblyOption
                  ? gildformItem.create.jewelry.assemblyOption.id
                  : null
              }-${
                gildformItem.create.jewelry.laserEngravingOption
                  ? gildformItem.create.jewelry.laserEngravingOption.id
                  : null
              }-${selectedMarketplace3dModel && `mkpl`}dividedby${
                productChosen
                  ? selectedProduct.weight
                  : gildformItem.create.jewelry.volume
              }-${gildformItem.create.jewelry.surfaceArea}dividedby${
                gildformItem.create.jewelry.file_name
              }dividedby${productChosen ? `product` : null}dividedby${
                selectedProduct ? selectedProduct.id : null
              }
              }dividedbygildstore-${prodPrice}`,
            },
          ],
          added: true,
          published: false,
        },
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // 'Shopify-URL': 'https://gild-test-store.myshopify.com/',
        // 'Shopify-access-token': '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',
        "Shopify-URL": "https://gildform-creator-shop.myshopify.com/",
        "Shopify-access-token": "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",
      },
    });
    setStoreLoading(false);
    if (res.status === 200 || res.status === 201) {
      const resApi = await fetch(`${API_URL}/shopify/gildform-store-item`, {
        body: JSON.stringify(finalProduct),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Product added to Gildform Store successfully!", {
        className: "bg-primary text-white",
        onClose: () => {
          resetPage();
        },
      });
    } else {
      toast.error("Something went wrong!");
    }
  };

  const setGildformItemToState = async () => {
    const modelElements = await setModelElementsShop();

    const dataToDynamo = dynamoShopifyItem(
      modelElements.id,
      profile,
      currentStep !== 0 ? create : gildformItem.create,
      laserFont,
      modelElements.laserImagesUploaded,
      laserCustomText,
      libraryProduct,
      productChosen,
      selectedProduct,
      currentStep !== 0 ? totalProductPrice : gildformItem.price.totalPrice,
      shopUrl,
      modelElements.screenShotLocation,
      selectedMarketplace3dModel
    );
    setGildformItem({ ...dataToDynamo, create });
    setStoreLoading(false);
    setOpenLoad(false);
  };

  const saveGildformStoreItem = async () => {
    setStoreLoading(true);
    setRedirectedStripe(true);
    setTimeout(() => {
      window.open(link, "_self");
    }, 500);
  };

  if (displayPrevious && !estimatedPrice) {
    btn = (
      <>
        <Button
          className="back-btn"
          onClick={goToPreviousStep}
          style={{ marginRight: "15px" }}
        >
          BACK
        </Button>
        {btn}
      </>
    );
  }

  if (!displayNext && !productionPage) {
    btn = (
      <>
        {btn}
        {(shopUrl && shopUrl !== "") ||
        !!shopUrl ||
        (shopToken && shopToken !== "") ||
        !!shopToken ? (
          loading ? (
            <Button variant="outline-dark">
              <i className="fas fa-circle-notch fa-spin" />
            </Button>
          ) : (
            <div>
              <>
                <CustomTooltip
                  show={showShopifyTool}
                  profile={profile}
                  permissions={permissions}
                  action={"shopify"}
                  target={shopifybtnRef}
                  place="bottom"
                />
                {profile?.membership?.name === "Standard" ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        Only for Standard Plus memberships and above
                      </Tooltip>
                    }
                  >
                    <div>
                      {/* <Button
                        onClick={() => {
                          addToShopify();
                          setDisableAddToCart(true);
                        }}
                        className="mt-1 connect-shopify-btn"
                        disabled={
                          (profile?.membership &&
                            profile?.membership?.name === "Standard") ||
                          profile?.subUser
                            ? !getPermission(permissions, "shopify")
                            : false
                        }
                        onMouseEnter={() => {
                          if (profile?.subUser) {
                            if (!getPermission(permissions, "shopify")) {
                              setShowShopifyTool(true);
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          setShowShopifyTool(false);
                        }}
                      >
                        Publish on Shopify
                      </Button>
                      <div>
                        <span className="bg-white text-primary">
                          Upgrade your membership to publish on shopify!
                        </span>
                      </div> */}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <>
                    {/* <Button
                      onClick={() => {
                        addToShopify();
                        setDisableAddToCart(true);
                      }}
                      className="mt-1 connect-shopify-btn"
                      disabled={
                        profile?.membership &&
                        profile?.membership?.name === "Standard"
                      }
                      ref={shopifybtnRef}
                    >
                      <img
                        style={{
                          width: "22px",
                          height: "22px",
                          marginBottom: "5px",
                        }}
                        src={shopifyIcon}
                        alt="shopify-icon"
                      />
                      Publish on Shopify{" "}
                    </Button> */}
                  </>
                )}
              </>
            </div>
          )
        ) : (
          <>
            {/* <Button
              className="mt-1 connect-shopify-btn"
              variant="link"
              disabled={
                profile?.membership && profile?.membership?.name === "Standard"
              }
            >
              <Link to={"/profile/edit"} className="text-white">
                <div>Connect to Shopify</div>
              </Link>
            </Button>
            <div>
              {profile?.membership &&
              profile?.membership?.name === "Standard" ? (
                <span className="bg-white text-primary">
                  Upgrade your membership to connect to shopify!
                </span>
              ) : (
                ""
              )}
            </div> */}
          </>
        )}
        {/* <Button
          className={"gildform-store-btn"}
          onClick={async () => {
            setOpenLoad(true);
            setTimeout(() => {
              setOpenStoreModal(true);
            }, 800);
            await setGildformItemToState();
          }}
          disabled={
            profile?.membership && profile?.membership?.name === "Standard"
          }
        >
          <img
            src={gildformIcon}
            alt="gildform"
            style={{ width: "20px", marginRight: "2px" }}
          />
          {openLoad ? (
            <span> ... Opening modal</span>
          ) : (
            "Sell on Gildform Store"
          )}
        </Button> */}
      </>
    );
  }

  return (
    <Container
      className="d-flex flex-column step step-container-all"
      style={{
        height: "100%",
        paddingRight: "0px",
        width: "fit-content",
        paddingLeft: "10px",
      }}
    >
      {/* <GildformStore
        openStoreModal={openStoreModal}
        setOpenStoreModal={setOpenStoreModal}
        stripeComplete={stripeComplete}
        prodName={prodName}
        prodImages={prodImages}
        prodPrice={prodPrice}
        saveGildformStoreItem={saveGildformStoreItem}
        publishToGildformStore={publishToGildformStore}
        gildformItem={gildformItem}
        setProductDescription={setProductDescription}
        setProductImages={setProductImages}
        setProductName={setProductName}
        setProductPrice={setProductPrice}
        gildformPercentage={gildformPercentage}
        storeLoading={storeLoading}
        setImgUpl={setImgUpl}
        setImgUse={setImgUse}
        imageUse={imageUse}
        imageUplod={imageUplod}
        openLoad={openLoad}
      /> */}
      <CustomTooltip
        show={proShow}
        profile={profile}
        permissions={permissions}
        action={"order"}
        target={btnRef}
        place="bottom"
      />
      <Row className="sect">
        <Col className="px-0">
          <div style={{ fontSize: "1.55em", fontWeight: "400" }}>
            <span
              style={
                conditionCrt
                  ? {
                      ...dynamicStyle(
                        profile?.primaryButtonColor,
                        profile?.buttonTextColor
                      ),
                      paddingRight: "7px",
                      paddingLeft: "7px",
                      marginRight: "4px",
                    }
                  : {
                      backgroundColor: "#7c6363",
                      paddingRight: "7px",
                      paddingLeft: "7px",
                      color: "white",
                      marginRight: "4px",
                    }
              }
            >
              {stepNumber}
            </span>
            {title}
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column sect">{children}</Row>
      <Row className="d-flex flex-row sect">{btn}</Row>
      {isSkippable && (
        <Button
          variant="link"
          id="skip-my-tour-earrings"
          className="link-skip"
          onClick={() => {
            onSkip();
            goToNextStep();
            if (Number(stepNumber) === 4) {
              setLaserImage(null);
              setLaserCustomText(null);
              setLaserFont(null);
            }
          }}
        >
          Skip This Step
        </Button>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  priceIsFetching: state.create?.price?.isFetching,
  material: state.create?.jewelry?.material,
  volume: state.create?.jewelry?.volume,
  addToCartLoading: state?.cart?.addToCartLoading,
  create: state.create,
  token: state.login?.auth?.auth.token,
  profile: state.login?.auth?.profile,
  itemType: state.create?.jewelry?.itemType,
  modelChosen: state.create?.jewelry && state.create?.jewelry?.modelChosen,
  selected3dModel:
    state.create?.jewelry && state.create?.jewelry?.selected3dModel,
  selectedMarketplace3dModel:
    state.create?.jewelry && state.create.jewelry?.selectedMarketplace3dModel,
  fileDimensionError:
    state.create?.jewelry && state.create?.jewelry?.fileDimensionError,
  laserCustomText: state.create.jewelry.laserCustomText,
  laserFont: state.create.jewelry.laserFont,
  laserImage: state.create.jewelry.laserImage,
  libraryProduct: state.create.jewelry && state.create.jewelry.libraryProduct,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
  prodName: state.create?.gildformStore && state.create?.gildformStore?.name,
  prodDescription:
    state.create?.gildformStore && state.create?.gildformStore?.description,
  prodPrice: state.create?.gildformStore && state.create?.gildformStore?.price,
  prodImages:
    state.create?.gildformStore && state.create?.gildformStore?.images,
  gildformItem: state.login?.gildformItem && state?.login?.gildformItem,
});

const mapDispatchToProps = (dispatch) => ({
  setImageScreenShot: (data) => dispatch(setImageScreenShot(data)),
  setMeshLocation: (data) => dispatch(setMeshLocation(data)),
  goToNextStep: () => dispatch(goNext()),
  goToPreviousStep: () => dispatch(goBack()),
  resetCreate: () => dispatch(resetCreate()),
  resetPrice: () => dispatch(resetPrice()),
  setLaserFont: (data) => dispatch(setFontLaser(data)),
  setLaserCustomText: (data) => dispatch(setLaserCustomText(data)),
  setLaserImage: (data) => dispatch(setLaserImage(data)),
  update: (user) => dispatch(updateUser(user)),
  //   setProductName: (data) => dispatch(setProductName(data)),
  //   setProductDescription: (data) => dispatch(setProductDescription(data)),
  //   setProductPrice: (data) => dispatch(setProductPrice(data)),
  //   setProductImages: (data) => dispatch(setProductImages(data)),
  setGildformItem: (data) => dispatch(setUserGildformItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step);
