export const FETCH_ITEM_CONFIGS_REQUEST = "FETCH_ITEM_CONFIGS_REQUEST";
export const FETCH_ITEM_CONFIGS_SUCCESS = "FETCH_ITEM_CONFIGS_SUCCESS";
export const FETCH_ITEM_CONFIGS_FAILURE = "FETCH_ITEM_CONFIGS_FAILURE";

export const SET_ASSEMBLY_OPTIONS = "SET_ASSEMBLY_OPTIONS";
export const SET_LASER_ENGRAVINGS = "SET_LASER_ENGRAVINGS";
export const SET_MATERIAL_OPTIONS = "SET_MATERIAL_OPTIONS";
export const SET_PLATING_OPTIONS = "SET_PLATING_OPTIONS";
export const SET_POLISH_OPTIONS = "SET_POLISH_OPTIONS";

// export const lastChecked = () => ({
//   type: CHECKED,
// });

export const fetchItemConfigsRequest = () => ({
  type: FETCH_ITEM_CONFIGS_REQUEST,
});

export const fetchItemConfigsSuccess = (payload) => ({
  type: FETCH_ITEM_CONFIGS_SUCCESS,
  payload,
});

export const fetchItemConfigsFailure = () => ({
  type: FETCH_ITEM_CONFIGS_FAILURE,
});

export const setMaterialOptions = (payload) => ({
  type: SET_MATERIAL_OPTIONS,
  payload,
});

export const setPlatingOptions = (payload) => ({
  type: SET_PLATING_OPTIONS,
  payload,
});

export const setPolishOptions = (payload) => ({
  type: SET_POLISH_OPTIONS,
  payload,
});

export const setAssemblyOptions = (payload) => ({
  type: SET_ASSEMBLY_OPTIONS,
  payload,
});

export const setLaserOptions = (payload) => ({
  type: SET_LASER_ENGRAVINGS,
  payload,
});
