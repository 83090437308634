import { FETCHING_PRICE, SET_PRICE, RESET_PRICE } from "../../actionTypes";

export const fetchingPrice = () => ({
  type: FETCHING_PRICE,
});

export const getPrice = (price) => ({
  type: SET_PRICE,
  price,
});

export const resetPrice = () => ({
  type: RESET_PRICE,
});
